import { styled } from '@infra/ui-design-system'
import { Grid } from '@mui/material'

export const StyledGridItem = styled(Grid)<{
  align?: 'left' | 'center' | 'right'
}>`
  display: flex;
  justify-content: ${({ align }) => align};
  flex-grow: 1;
  align-items: center;
`
