import { JWTToken, RefreshToken } from '../models/tokens';
import {auth} from '@infra/firebase';
type TokenType = 'access-token' | 'refresh-token';

export interface IAuthService {
  isAuthenticated(): boolean
  isTokenExpiredV2(): boolean
  getToken(tokenType: TokenType): JWTToken | RefreshToken
  getTokenExpireTime(tokenType: TokenType): number
  setToken(tokenType: TokenType, token: JWTToken | RefreshToken): void
  removeToken(tokenType: TokenType): void
  logout: () => Promise<void>
}

export class AuthService implements IAuthService {
  public static accessTokenName = 'authentication-access-token'
  public static refreshTokenName = 'authentication-refresh-token'

  public accessToken: JWTToken
  public refreshToken: RefreshToken

  constructor() {
    this.accessToken = this.getToken('access-token')
    this.refreshToken = this.getToken('refresh-token')
  }

  private getTokenName(tokenType: TokenType): string {
    return tokenType === 'access-token' ? AuthService.accessTokenName : AuthService.refreshTokenName
  }

  public getTokenExpireTime(tokenType: TokenType): number {
    const tokenName: string = this.getTokenName(tokenType)

    const tokenData = localStorage.getItem(tokenName)
    return tokenData ? +(JSON.parse(tokenData).expires_at ?? 0) : 0
  }

  public getToken(tokenType: TokenType): JWTToken | RefreshToken {
    const tokenName: string = this.getTokenName(tokenType)

    const token = localStorage.getItem(tokenName)
    return token ? JSON.parse(token).token : null
  }

  public setToken(tokenType: TokenType, token: JWTToken | RefreshToken, expires_in = 0): void {
    const tokenName: string = this.getTokenName(tokenType)

    localStorage.setItem(
      tokenName,
      JSON.stringify({
        token,
        ...(expires_in
          ? {
              expires_at: Math.floor(new Date().getTime() / 1000) + expires_in,
            }
          : {}),
      })
    )
  }

  public removeToken(tokenType: TokenType): void {
    const tokenName: string = this.getTokenName(tokenType)
    localStorage.removeItem(tokenName)
  }

  public async logout(): Promise<void>
  {
    this.removeToken('access-token');
    this.removeToken('refresh-token');
    await auth.signOut();
  }

  /*
   * There are two ways of detecting token expiration
   * first: Decoding the token to see the expire_time (all apps except console)
   * second: Checking the expire_time stored in localStorage (only console)
   */

  // first method of token validation
  public isTokenExpired(token: JWTToken): boolean {
    return (
      (token?.split('.')[1] &&
        Date.now() >= JSON.parse(window.atob(token.split('.')[1])).exp * 1000) ||
      false
    )
  }

  // second method of token validation
  public isTokenExpiredV2(): boolean {
    const expires_at = this.getTokenExpireTime('access-token')
    return new Date(expires_at * 1000) > new Date()
  }

  isAuthenticated(): boolean {
    const token = this.getToken('access-token')
    return token !== null && !this.isTokenExpired(token)
  }
}
