import i18n, { InitOptions } from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import LocalStorageBackend from 'i18next-localstorage-backend' // primary use cache
import HttpApi from 'i18next-http-backend'

const initializeI18n = (
  i18nextConfig: InitOptions,
  versioning: Record<string, string>,
  defaultVersion: string,
  path?: string,
  backendOptions?: Record<string, string>[]
) =>
  i18n
    .use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      ...i18nextConfig,
      backend: {
        backends: [LocalStorageBackend, HttpApi],
        backendOptions: backendOptions ?? [
          {
            prefix: 'i18next_res_',
            expirationTime: 7 * 24 * 60 * 60 * 1000,
            versions: { ...versioning },
            store: typeof window !== 'undefined' ? window.sessionStorage : null,
            defaultVersion,
          },
          { loadPath: path ?? '/translations/{{ns}}/{{lng}}.json' },
        ],
      },
    })

export { i18n, initializeI18n }
