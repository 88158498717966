import React from "react";
import {Button, Typography, AccordionSummary} from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {FC, useState} from "react";
import {styled} from "@infra/ui-design-system";
import {ExpandMore, DeleteOutline} from "@mui/icons-material";
import {IParameter, IPanel} from "../types";

const StyledExpandMoreIcon = styled(ExpandMore)`
  background-color: #fcfcfc;
  box-sizing: border-box;
  transform: rotate(-90deg);
  background: transparent;
  color: ${({theme}) => theme.palette.text?.primary || theme.palette.grey[400]};
  .Mui-expanded &{
    transform: rotate(-180deg);
  }
`
const StyledAccordionSummary = styled(AccordionSummary)`
  justify-content: flex-start;
  .MuiAccordionSummary-content {
    flex-grow: 0;
  }
`
const StyledTypographyBullet = styled(Typography)`display: list-item;`
const StyledDeleteIcon = styled(DeleteOutline)`
  width: 1rem;
`
const StyledDeleteButton = styled(Button)`
  position: absolute;
  right: -1rem;
  top: 50%;
  transform: translateY(-50%);
`

export interface IPanelAccordion {
  panel: IPanel;
  expanded?: boolean;
  handleDeletePanel?: (panelId: string) => boolean;
}

export const PanelAccordion: FC<IPanelAccordion> = ({
  panel,
  expanded,
  handleDeletePanel,
}: IPanelAccordion) => {

  const [expand, setExpand] = useState<boolean>(expanded||false);
  const toggleExand = () => {setExpand(!expand)};

  return <Accordion
    expanded={expand}
    onClick={toggleExand}
    sx={{boxShadow: 'none !important', background: 'transparent'}}
  >
    <StyledAccordionSummary
      expandIcon={<StyledExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{padding: '0', minHeight: '0 !important'}}
    >
      <Typography sx={{paddingLeft: '1px', fontWeight: 'bold'}} variant="body2">{panel?.label || panel.name}</Typography>
      {!!handleDeletePanel && <StyledDeleteButton onClick={() => {handleDeletePanel && handleDeletePanel(panel.id, panel.parameters)}}><StyledDeleteIcon /></StyledDeleteButton>}
    </StyledAccordionSummary>
    <AccordionDetails sx={{
      padding: 0,
      marginLeft: 2.5,
      paddingBottom: '.5rem'
    }}>
      {panel?.parameters?.sort((a: any, b: any) => a?.name < b?.name ? -1 : 1).map(item => <StyledTypographyBullet key={item.id} sx={{marginTop: 1}} variant="body2">{item.label || item.name}</StyledTypographyBullet>)}
    </AccordionDetails>
  </Accordion>
}
