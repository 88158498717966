import {
  CreateOrganizationRequestDTO,
  GetOrganizationResponseDTO,
  OrganizationsApi,
  UpdateOrganizationRequestDTO,
} from '@infra/daslab-sdk';
import {BaseAPI} from '@infra/observable-core'

export interface IOrganizationsService {
  createOrganization: (payload: CreateOrganizationRequestDTO) => Promise<GetOrganizationResponseDTO>;
  getOrganization: (id: string) => Promise<GetOrganizationResponseDTO>;
  listOrganizations: () => Promise<GetOrganizationResponseDTO[]>;
  updateOrganization: (id: string, payload: UpdateOrganizationRequestDTO) => Promise<GetOrganizationResponseDTO>;
}

export class OrganizationsService extends BaseAPI<OrganizationsApi> implements IOrganizationsService
{

  constructor()
  {
    super(OrganizationsApi);
  }

  listOrganizations = async (): Promise<GetOrganizationResponseDTO[]> =>
  {
    const {data} = (await this.api.listOrganizations()) as unknown as {data: GetOrganizationResponseDTO[]};
    return data;
  }

  createOrganization = async (payload: CreateOrganizationRequestDTO) =>
  {
    const {data} = await this.api.createOrganization(payload);
    return data;
  }

  getOrganization = async (id: string) =>
  {
    const {data} = await this.api.getOrganization(id);
    return data;
  }

  updateOrganization = async (id: string, payload: UpdateOrganizationRequestDTO) =>
  {
    const {data} = await this.api.updateOrganization(id, payload);
    return data;
  }

}

export const organizationsService: OrganizationsService = new OrganizationsService();
