import {AxiosInstance} from 'axios';
import {AxiosInstanceAPI} from './AxiosInstanceAPI';
import {apiConfig} from '../config';
import {Configuration, CreateAccountRequestDTOLocaleEnum} from "@infra/daslab-sdk";
import {i18n} from "i18next";

export abstract class BaseAPI<T = null>
{
  protected api: T;
  protected baseUrl: string
  protected axiosProtectedInstance: AxiosInstance;

  protected constructor(CurrentApi: any = null)
  {
    this.baseUrl = apiConfig.baseUrl.replace(/\/v1\//g, '');
    this.axiosProtectedInstance = new AxiosInstanceAPI({}).instance;
    this.api = CurrentApi ? this.createApiInstance(CurrentApi) : null;
  }

  getLocaleFromI18n = (i18nLanguage: i18n['language']): CreateAccountRequestDTOLocaleEnum =>
  {
    if(i18nLanguage === CreateAccountRequestDTOLocaleEnum.EnGb) return CreateAccountRequestDTOLocaleEnum.EnGb;
    if(i18nLanguage === CreateAccountRequestDTOLocaleEnum.EsEs) return CreateAccountRequestDTOLocaleEnum.EsEs;
    return CreateAccountRequestDTOLocaleEnum.DeDe;
  }

  createApiInstance = (CurrentApi: any) =>
  {
    const axiosInstance: AxiosInstanceAPI = new AxiosInstanceAPI({baseUrl: this.baseUrl});
    return new CurrentApi(new Configuration(), this.baseUrl, axiosInstance.instance);
  }
}
