import { TableCell, TableHead as MuiTableHead, Typography, TableSortLabel } from '@mui/material'
import { styled } from '@infra/ui-design-system'
import { TableHeadProps } from './table.interface'
import { StyledSortIcon, StyledRow } from './table.styled'

const StyledStickyTableHead = styled(MuiTableHead)`
  background-color: white;
`

export const TableHead = ({ columns, sort = '', onSortChange, hover = false }: TableHeadProps) => {
  const sortDirection = sort?.startsWith('-') ? 'desc' : 'asc'
  const sortBy = sortDirection === 'desc' ? sort.substring(1) : sort

  return (
    <StyledStickyTableHead>
      <StyledRow hover={hover}>
        {columns.map(({ id: columnId, label, sortable = false }) => (
          <TableCell key={label} data-test="column-label">
            {sortable ? (
              <TableSortLabel
                active
                direction={sortDirection}
                data-test="sort-button"
                onClick={() =>
                  onSortChange?.({
                    sort: sortDirection === 'asc' ? `-${columnId}` : columnId,
                    direction: sortDirection,
                  })
                }
                IconComponent={({ className }) => (
                  <StyledSortIcon
                    name={sortBy === columnId ? 'sortOrder' : 'passiveSort'}
                    className={className}
                  />
                )}
              >
                <Typography
                  variant="subtitle2"
                  component="h3"
                  color="#8E8E8E"
                  fontFamily="IBM Plex Mono"
                >
                  {label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography
                variant="subtitle2"
                component="h3"
                color=" #8E8E8E"
                fontFamily="IBM Plex Mono"
              >
                {label}
              </Typography>
            )}
          </TableCell>
        ))}
      </StyledRow>
    </StyledStickyTableHead>
  )
}
