/**
 * Checks if current browsers is supported.
 * @param {{ name: any; version: any }} currentBrowser
 * @return {Boolean}
 */
export function isBrowserSupported(currentBrowser: { name: any; version: any }): boolean {
  // Internet Explorer 11 unsupported
  if (
    (currentBrowser.name === 'ie' || currentBrowser.name === 'internet explorer') &&
    currentBrowser.version < '11'
  ) {
    return false
  }

  return true
}

/**
 * Get browser language setting
 * @return {String}
 */
export function getBrowserLanguage(): string {
  return (navigator.languages && navigator.languages[0]) || navigator.language
}

/**
 * Splits string at a given character and symbol
 * @param {String}  string
 * @param {Number}  minCharacterLength
 * @param {Array}   symbolArray default = ['_', '-']
 * @return {String}
 */
export function splitString(
  string: string,
  minCharacterLength: number,
  symbolArray: any[] = ['_', '-']
): string {
  let language = string

  if (language.length > minCharacterLength) {
    // Language would be something like es-ES or es_ES
    for (let i = 0; i < symbolArray.length; i += 1) {
      language = language.split(symbolArray[i])[0]
    }
  }

  return language
}

/**
 * Returns the formatted language code from browser language, and fallback to default language if it isn't supported.
 * @return {String}
 */
export function getLanguageCodeFromBrowser(): string {
  const language: string = getBrowserLanguage().split('-')[0]
  if(language === 'es') return 'es_ES';
  if(language === 'de') return 'de_DE';
  return 'en_GB';
}

export function getSupportWebsiteUrl(): string {
  const language: string = getBrowserLanguage().split('-')[0];
  if(language === 'es') return 'https://support.daslab.health/hc/es';
  if(language === 'de') return 'https://support.daslab.health/hc/de';
  return 'https://support.daslab.health/hc/en-gb';
}

export const getCookie = (name: string): string|null => {
  if(typeof document === 'undefined') return null;
  const cookies = Object.fromEntries(
    document.cookie.split('; ').map(cookie => {
      const [name, value] = cookie.split('=');
      return [name, decodeURIComponent(value)];
    })
  );
  return cookies[name] || null;
}

export const getMapFromSessionStorage = <T>(key: string): Map<string, T> =>
{
  const stringMap = sessionStorage.getItem(key);
  return stringMap ? new Map(JSON.parse(stringMap)) : new Map();
}

export const saveMapToSessionStorage = <T>(key: string, map: Map<string, T>) =>
{
  const stringMap = JSON.stringify(Array.from(map));
  sessionStorage.setItem(key, stringMap);
}
