// @ts-nocheck Theme object causing issue need to investigate why it's not inheriting the Theme
import { Icon, styled } from '@infra/ui-design-system'
import {
  Table,
  tableBodyClasses,
  tableCellClasses,
  tableClasses,
  TableContainer,
  TableRow,
  TableHead,
  tableRowClasses,
} from '@mui/material'

export const StyledSortIcon = styled(Icon)`
  color: transparent !important;

  margin-left: ${({ theme }) => theme.spacing(1.5)};
  height: ${({ theme }) => theme.spacing(2)};
`

export const StyledTableContainer = styled(TableContainer)`
  width: 100%;
`

export const StyledRow = styled(TableRow) <{ hover?: boolean }>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : undefined)};

  &.${tableRowClasses.root} {

    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${({ theme }) => theme.typography?.borders?.colors?.light || theme.palette.grey[200]};
    }
    &:hover,
    &.${tableRowClasses.selected} {
      background-color: ${({ theme }) => theme.palette.primary.light};
      &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background: white;
      }

      > td > span {
        color: ${({ theme }) => theme.palette?.text?.primary || theme.palette?.primary?.main};
        opacity: 1;
      }
    }
  }
`

export const StyledTable = styled(Table) <{ fontFamily?: string }>`
  .${tableBodyClasses.root} {

    &:before, &:after {
      content: "-";
      display: block;
      line-height: 1em;
      color: transparent;
    }

    // Set border-radius on the top-left and bottom-left of the first table data on the table row
    td:first-of-type,
    th:first-of-type {
      border-radius: 10px 0 0 10px;
    }

    // Set border-radius on the top-right and bottom-right of the last table data on the table row
    td:last-child,
    th:last-child {
      border-radius: 0 10px 10px 0;
    }
  }

  ${tableCellClasses.root} {
    border: none;
  }

  .${tableCellClasses.head} {
    padding: ${({ theme: { spacing } }) => `${spacing(1.5)} ${spacing(1)}`};

    .Mui-active {
      h3 {
        font-weight: 600;
        color: ${({ theme }) => theme.palette.common.black};
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }

  .${tableCellClasses.body} {
    color: ${({ theme }) => theme.palette.grey[500]};
    padding: ${({ theme: { spacing } }) => `${spacing(1.5)} ${spacing(1)}`};
    border: none;
    font-size: 14x;
    font-weight: 400;
    line-height: 1.6;
  }

  .${tableCellClasses.body}.no-padding {
    padding: ${({ theme: { spacing } }) => `0 ${spacing(1)}`};
  }
`

export const StyledTableCellContent = styled.span`
  white-space: nowrap;
  max-width: ${({ mw = 205 }: { mw?: number }) => `${mw}px`};
  width: ${({ wi = 200, isMobile }: { wi?: number, isMobile: boolean }) => !isMobile ? `${wi}px` : 'auto'};
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  opacity: .7;
  color: ${({ theme }) => theme.palette?.text?.primary || 'black'};
`

export const StyledNoTableData = styled.td`
  height: 150px;
  text-align: center;
`
