import {BaseAPI, orderEntity} from "@infra/observable-core";
import {ConnectOrderRequestDTO, GetOrderResponseDTO, OrdersApi, RecreateOrderRequestDTO} from "@infra/daslab-sdk";
import {AxiosResponse} from "axios";
import {Order} from "./entities/order/Order.entity";

export type ServiceGetOrderResponseDTO = GetOrderResponseDTO & {
  combinedStatuses: orderEntity['combinedStatuses'];
  fullName: orderEntity['fullName'];
  fullAddress: orderEntity['fullAddress'];
  isFinalized: orderEntity['isFinalized'];
};
type GetOrdersListResponseDTO = { //todo - when the return type of the sdk evolves, define the type
  data: GetOrderResponseDTO[];
  meta: {total: number}
}

type page = {offset: number, limit: number};
type filter = {[key: string]: string};

interface IOrdersApi {
  list: (sort: string[], page?: page, filter?:filter) => Promise<GetOrdersListResponseDTO>
  getById: (orderId: string) => Promise<ServiceGetOrderResponseDTO>
  cancelById: (orderId: string) => Promise<GetOrderResponseDTO>
  connect: (connectOrderRequestDTO: ConnectOrderRequestDTO) => Promise<GetOrderResponseDTO>
  recreate: (placerId: RecreateOrderRequestDTO['placerId']) => Promise<void>
}

class OrdersService extends BaseAPI<OrdersApi> implements IOrdersApi
{

  constructor()
  {
    super(OrdersApi);
  }

  connect = async (connectOrderRequestDTO: ConnectOrderRequestDTO) =>
  {
    const {data} = await this.api.connect(connectOrderRequestDTO);
    return data;
  }

  list = async (sort: string[] = ['-createdAt', 'id'], page?: page, filter?: filter) =>
  {
    const {data: response} = await this.api.list(sort, {params: {page, filter}}) as unknown as AxiosResponse<GetOrdersListResponseDTO>;
    const data = response.data.map(order => {
      const orderInstance = new Order(order);
      return {
        ...orderInstance.order,
        fullName: orderInstance.fullName,
        status: orderInstance.status,
      };
    });
    return {data, meta: response.meta};
  }

  getById = async (orderId: string) =>
  {
    const {data} = await this.api.getById(orderId);
    const order = new Order(data);
    return {
      ...data,
      fullName: order.fullName,
      fullAddress: order.fullAddress,
      combinedStatuses: order.combinedStatuses,
      isFinalized: order.isFinalized,
    };
  }

  cancelById = async (orderId: string) =>
  {
    const {data} = await this.api.cancelById(orderId, {data: {}});
    return data;
  }

  recreate = async (placerId: RecreateOrderRequestDTO['placerId']) =>
  {
    await this.api.recreate({placerId});
  }

}

export const ordersService: OrdersService = new OrdersService();
