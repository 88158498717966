import {Statuses} from "./order.types";
import {OrderStatusDTO} from "@infra/daslab-sdk";

const followUpCases = [
  { from: Statuses.CREATED, to: Statuses.DISPATCHED, interval: 72 },
  { from: Statuses.DISPATCHED, to: Statuses.ACTIVATED, interval: 120 },
  { from: Statuses.ACTIVATED, to: Statuses.SPECIMEN_RECEIVED_BY_LABORATORY, interval: 96 },
  {
    from: Statuses.SPECIMEN_RECEIVED_BY_LABORATORY,
    to: Statuses.DIAGNOSTIC_RESULTS_CREATED,
    interval: 72,
  },
];

const getTime = ({ createdAt }: OrderStatusDTO) => new Date(createdAt).getTime();
export const needsFollowUp = (list: OrderStatusDTO[],): Statuses | false =>
{
  const cases = [...followUpCases]

  const statusMap: { [key: string]: OrderStatusDTO | undefined } = {
    created: list.find(({ value }) => value === Statuses.CREATED),
    dispatched: list.find(({ value }) => value === Statuses.DISPATCHED),
    activated: list.find(({ value }) => value === Statuses.ACTIVATED),
    specimen_received_by_laboratory: list.find(({ value }) => value === Statuses.SPECIMEN_RECEIVED_BY_LABORATORY),
    diagnostic_results_created: list.find(({ value }) => value === Statuses.DIAGNOSTIC_RESULTS_CREATED),
  }

  for (let i = 0; i < cases.length; i++)
  {
    const { from, to, interval } = cases[i];
    if (!statusMap[from]) continue;
    const beginning = getTime(statusMap[from] as OrderStatusDTO);
    const ending = statusMap[to] ? getTime(statusMap[to] as OrderStatusDTO) : new Date().getTime();
    if (ending - beginning >= interval * 60 * 60 * 1000) return to;
  }

  return false;
}
