import {BaseAPI} from '../apis';
import {
  AccountsApi,
  AccountMeResponseDTO,
  UpdateAccountPasswordRequestDTO,
  AnonymizePatientRequestDTO,
  AnonymizePatientResponseDTO,
} from '@infra/daslab-sdk';

export interface IAccountsApiService
{
  readonly getCurrent: () => Promise<AccountMeResponseDTO>;
  readonly updatePassword: (updateAccountPasswordRequestDTO: UpdateAccountPasswordRequestDTO) => Promise<void>;
  readonly anonymize: (updateAccountPasswordRequestDTO: AnonymizePatientRequestDTO) => Promise<AnonymizePatientResponseDTO>;
}

export class AccountsService extends BaseAPI<AccountsApi> implements IAccountsApiService
{

  constructor()
  {
    super(AccountsApi);
  }

  getCurrent = async (): Promise<AccountMeResponseDTO> =>
  {
    const {data} = await this.api.getCurrent();
    return data;
  }

  updatePassword = async (updateAccountPasswordRequestDTO: UpdateAccountPasswordRequestDTO): Promise<void> =>
  {
    await this.api.updatePassword(updateAccountPasswordRequestDTO);
  }

  anonymize = async (updateAccountPasswordRequestDTO: AnonymizePatientRequestDTO): Promise<AnonymizePatientResponseDTO> =>
  {
    const {data} = await this.api.anonymize(updateAccountPasswordRequestDTO);
    return data;
  }
}

export const accountsService = new AccountsService();
