import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {auth, signOut} from "@infra/firebase";
import {apiConfig} from '@infra/observable-core'

export interface IConstructorConfig {baseUrl?: string, withAccessToken?: boolean, withRedirectionOnUnauthorized?: boolean}
export interface IAxiosInstanceAPI {instance: AxiosInstance}

export class AxiosInstanceAPI implements IAxiosInstanceAPI
{
  public instance: AxiosInstance;

  constructor({
    baseUrl = apiConfig.baseUrl,
    withAccessToken = true,
    withRedirectionOnUnauthorized = true,
  }: IConstructorConfig)
  {
    this.instance = axios.create({baseURL: baseUrl});
    withAccessToken && this.bindRequestInterceptors();
    withRedirectionOnUnauthorized && this.bindResponseInterceptor();
  }

  bindRequestInterceptors = (): void =>
  {
    this.instance.interceptors.request.use(
      async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        const accessToken: string|Promise<string> = await auth.currentUser?.getIdToken();
        if(!accessToken) throw {response: {status: 401, message: 'missing accessToken'}};
        config.headers['Authorization'] = `Bearer ${accessToken}`;
        return config;
      },
      error => {console.error(error)}
    );
  }

  bindResponseInterceptor = () =>
  {
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error): Promise<void> =>
      {
        if(error.response.status == 401 || error.response.status == 403) return await signOut(auth);
        throw error;
      }
    );
  }
}

