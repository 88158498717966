/* tslint:disable */
/* eslint-disable */
/**
 * DasLab API
 * This is the DasLab health API reference, please select a resource to get started.
 *
 * The version of the OpenAPI document: 2.0.4
 * Contact: developers@daslab.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountConsentDTO
 */
export interface AccountConsentDTO {
    /**
     * 
     * @type {string}
     * @memberof AccountConsentDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountConsentDTO
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof AccountConsentDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountConsentDTO
     */
    'deletedAt': string | null;
}
/**
 * 
 * @export
 * @interface AccountDTO
 */
export interface AccountDTO {
    /**
     * 
     * @type {string}
     * @memberof AccountDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDTO
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface AccountMeResponseDTO
 */
export interface AccountMeResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof AccountMeResponseDTO
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeResponseDTO
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountMeResponseDTO
     */
    'organizationId': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountMeResponseDTO
     */
    'role': AccountMeResponseDTORoleEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AccountMeResponseDTO
     */
    'lastSeenAt': string | null;
}

export const AccountMeResponseDTORoleEnum = {
    DaslabAdministrator: 'daslab-administrator',
    Patient: 'patient',
    Practitioner: 'practitioner',
    DaslabSupportUser: 'daslab-support-user',
    ApiClient: 'api-client',
    OrganizationOwner: 'organization-owner'
} as const;

export type AccountMeResponseDTORoleEnum = typeof AccountMeResponseDTORoleEnum[keyof typeof AccountMeResponseDTORoleEnum];

/**
 * 
 * @export
 * @interface AccountResponseDTO
 */
export interface AccountResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'organizationId': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'providerId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'locale': AccountResponseDTOLocaleEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'patientId': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'termsAcceptedAt': string | null;
    /**
     * 
     * @type {Array<AccountConsentDTO>}
     * @memberof AccountResponseDTO
     */
    'consents': Array<AccountConsentDTO>;
    /**
     * 
     * @type {AccountSessionDTO}
     * @memberof AccountResponseDTO
     */
    'session': AccountSessionDTO | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'lastSeenAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDTO
     */
    'updatedAt': string;
}

export const AccountResponseDTOLocaleEnum = {
    DeDe: 'de_DE',
    EnGb: 'en_GB',
    EsEs: 'es_ES'
} as const;

export type AccountResponseDTOLocaleEnum = typeof AccountResponseDTOLocaleEnum[keyof typeof AccountResponseDTOLocaleEnum];

/**
 * 
 * @export
 * @interface AccountSessionDTO
 */
export interface AccountSessionDTO {
    /**
     * 
     * @type {string}
     * @memberof AccountSessionDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountSessionDTO
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountSessionDTO
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountSessionDTO
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
    /**
     * Name of acity or town.
     * @type {string}
     * @memberof AddressDTO
     */
    'city': string;
    /**
     * Country (must be a ISO 3166-1 alpha-2 letter code).
     * @type {string}
     * @memberof AddressDTO
     */
    'country': string;
    /**
     * Street name, number, direction, etc.
     * @type {Array<string>}
     * @memberof AddressDTO
     */
    'line': Array<string>;
    /**
     * Postal code for area.
     * @type {string}
     * @memberof AddressDTO
     */
    'postalCode': string;
    /**
     * List of possible values defined [here](http://www.hl7.org/fhir/codesystem-address-use.html#4.3.14.160.2).
     * @type {string}
     * @memberof AddressDTO
     */
    'use': string;
    /**
     * Contact phone number.
     * @type {string}
     * @memberof AddressDTO
     */
    'phone'?: string | null;
}
/**
 * 
 * @export
 * @interface AddressResponseDTO
 */
export interface AddressResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof AddressResponseDTO
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressResponseDTO
     */
    'country': string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressResponseDTO
     */
    'line': string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressResponseDTO
     */
    'postalCode': string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressResponseDTO
     */
    'phone': string | null;
}
/**
 * 
 * @export
 * @interface Anonymize400Response
 */
export interface Anonymize400Response {
    /**
     * 
     * @type {string}
     * @memberof Anonymize400Response
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Anonymize400Response
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Anonymize400Response
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @interface Anonymize401Response
 */
export interface Anonymize401Response {
    /**
     * 
     * @type {string}
     * @memberof Anonymize401Response
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Anonymize401Response
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Anonymize401Response
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @interface AnonymizePatientRequestDTO
 */
export interface AnonymizePatientRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof AnonymizePatientRequestDTO
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface AnonymizePatientResponseDTO
 */
export interface AnonymizePatientResponseDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof AnonymizePatientResponseDTO
     */
    'result': Array<string>;
}
/**
 * 
 * @export
 * @interface AttachmentDTO
 */
export interface AttachmentDTO {
    /**
     * 
     * @type {string}
     * @memberof AttachmentDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDTO
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDTO
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDTO
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CancelById404Response
 */
export interface CancelById404Response {
    /**
     * 
     * @type {string}
     * @memberof CancelById404Response
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CancelById404Response
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CancelById404Response
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @interface CodingResponseDTO
 */
export interface CodingResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CodingResponseDTO
     */
    'system': string;
    /**
     * 
     * @type {string}
     * @memberof CodingResponseDTO
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CodingResponseDTO
     */
    'display': string;
}
/**
 * 
 * @export
 * @interface CollectDiagnosticPrescriptionRequestDTO
 */
export interface CollectDiagnosticPrescriptionRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CollectDiagnosticPrescriptionRequestDTO
     */
    'patientIdentifier': string;
}
/**
 * 
 * @export
 * @interface CollectionDTO
 */
export interface CollectionDTO {
    /**
     * 
     * @type {string}
     * @memberof CollectionDTO
     */
    'collectedDateTime': string;
}
/**
 * 
 * @export
 * @interface CollectionResponseDTO
 */
export interface CollectionResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CollectionResponseDTO
     */
    'collectedDateTime': string;
}
/**
 * 
 * @export
 * @interface ConditionResponseDTO
 */
export interface ConditionResponseDTO {
    /**
     * 
     * @type {Array<CodingResponseDTO>}
     * @memberof ConditionResponseDTO
     */
    'coding': Array<CodingResponseDTO>;
}
/**
 * 
 * @export
 * @interface Connect409Response
 */
export interface Connect409Response {
    /**
     * 
     * @type {string}
     * @memberof Connect409Response
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Connect409Response
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Connect409Response
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @interface ConnectOrderRequestDTO
 */
export interface ConnectOrderRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof ConnectOrderRequestDTO
     */
    'reference': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectOrderRequestDTO
     */
    'dateOfBirth': string;
}
/**
 * 
 * @export
 * @interface ContainerResponseDTO
 */
export interface ContainerResponseDTO {
    /**
     * 
     * @type {Array<IdentifierResponseDTO>}
     * @memberof ContainerResponseDTO
     */
    'identifier': Array<IdentifierResponseDTO>;
}
/**
 * 
 * @export
 * @interface CreateAccountRequestDTO
 */
export interface CreateAccountRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestDTO
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestDTO
     */
    'tenantId': CreateAccountRequestDTOTenantIdEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestDTO
     */
    'locale': CreateAccountRequestDTOLocaleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestDTO
     */
    'roleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestDTO
     */
    'organizationId'?: string;
}

export const CreateAccountRequestDTOTenantIdEnum = {
    ApiClientsIuoe6: 'api-clients-iuoe6',
    ConsoleAppI4exj: 'console-app-i4exj',
    PatientsApp6b12p: 'patients-app-6b12p',
    PractitionersAppZswbi: 'practitioners-app-zswbi'
} as const;

export type CreateAccountRequestDTOTenantIdEnum = typeof CreateAccountRequestDTOTenantIdEnum[keyof typeof CreateAccountRequestDTOTenantIdEnum];
export const CreateAccountRequestDTOLocaleEnum = {
    DeDe: 'de_DE',
    EnGb: 'en_GB',
    EsEs: 'es_ES'
} as const;

export type CreateAccountRequestDTOLocaleEnum = typeof CreateAccountRequestDTOLocaleEnum[keyof typeof CreateAccountRequestDTOLocaleEnum];

/**
 * 
 * @export
 * @interface CreateAccountResponseDTO
 */
export interface CreateAccountResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountResponseDTO
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CreateDiagnosticPrescriptionRequestDTO
 */
export interface CreateDiagnosticPrescriptionRequestDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDiagnosticPrescriptionRequestDTO
     */
    'parameterIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDiagnosticPrescriptionRequestDTO
     */
    'panelIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateDiagnosticPrescriptionRequestDTO
     */
    'patientIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiagnosticPrescriptionRequestDTO
     */
    'patientFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiagnosticPrescriptionRequestDTO
     */
    'patientLastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiagnosticPrescriptionRequestDTO
     */
    'notesForLab'?: string;
}
/**
 * 
 * @export
 * @interface CreateDiagnosticReportDTO
 */
export interface CreateDiagnosticReportDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateDiagnosticReportDTO
     */
    'resourceType': string;
    /**
     * 
     * @type {Array<FHIRIdentifierDTO>}
     * @memberof CreateDiagnosticReportDTO
     */
    'identifier': Array<FHIRIdentifierDTO>;
    /**
     * 
     * @type {Array<FHIRReferenceDTO>}
     * @memberof CreateDiagnosticReportDTO
     */
    'basedOn': Array<FHIRReferenceDTO>;
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof CreateDiagnosticReportDTO
     */
    'dataAbsentReason'?: FHIRCodingDTO;
    /**
     * 
     * @type {Array<FHIRPresentedFormDTO>}
     * @memberof CreateDiagnosticReportDTO
     */
    'presentedForm'?: Array<FHIRPresentedFormDTO>;
}
/**
 * 
 * @export
 * @interface CreateDiagnosticResultRequestDTO
 */
export interface CreateDiagnosticResultRequestDTO {
    /**
     * 
     * @type {CreateDiagnosticReportDTO}
     * @memberof CreateDiagnosticResultRequestDTO
     */
    'diagnosticReport': CreateDiagnosticReportDTO;
    /**
     * 
     * @type {Array<CreateObservationDTO>}
     * @memberof CreateDiagnosticResultRequestDTO
     */
    'observations': Array<CreateObservationDTO>;
}
/**
 * 
 * @export
 * @interface CreateLaboratoryInvoicesRequestDTO
 */
export interface CreateLaboratoryInvoicesRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateLaboratoryInvoicesRequestDTO
     */
    'diagnosticRequestId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLaboratoryInvoicesRequestDTO
     */
    'placerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLaboratoryInvoicesRequestDTO
     */
    'contentType': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLaboratoryInvoicesRequestDTO
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLaboratoryInvoicesRequestDTO
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface CreateObservationDTO
 */
export interface CreateObservationDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateObservationDTO
     */
    'resourceType': string;
    /**
     * 
     * @type {Array<FHIRIdentifierDTO>}
     * @memberof CreateObservationDTO
     */
    'identifier'?: Array<FHIRIdentifierDTO>;
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof CreateObservationDTO
     */
    'code': FHIRCodingDTO;
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof CreateObservationDTO
     */
    'valueCodeableConcept'?: FHIRCodingDTO;
    /**
     * 
     * @type {FHIRQuantityDTO}
     * @memberof CreateObservationDTO
     */
    'valueQuantity'?: FHIRQuantityDTO;
    /**
     * 
     * @type {Array<FHIRReferenceRangeDTO>}
     * @memberof CreateObservationDTO
     */
    'referenceRange'?: Array<FHIRReferenceRangeDTO>;
    /**
     * 
     * @type {Array<FHIRNoteDTO>}
     * @memberof CreateObservationDTO
     */
    'note'?: Array<FHIRNoteDTO>;
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof CreateObservationDTO
     */
    'dataAbsentReason'?: FHIRCodingDTO;
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof CreateObservationDTO
     */
    'method': FHIRCodingDTO;
}
/**
 * 
 * @export
 * @interface CreateOrderRequestDTO
 */
export interface CreateOrderRequestDTO {
    /**
     * Shipping, billing address for the order.
     * @type {Array<AddressDTO>}
     * @memberof CreateOrderRequestDTO
     */
    'address': Array<AddressDTO>;
    /**
     * 
     * @type {CustomerDTO}
     * @memberof CreateOrderRequestDTO
     */
    'customer': CustomerDTO;
    /**
     * The order id within the placer\'s database.
     * @type {string}
     * @memberof CreateOrderRequestDTO
     */
    'placerId': string;
    /**
     * List of products to be shipped for this order.
     * @type {Array<ProductDTO>}
     * @memberof CreateOrderRequestDTO
     */
    'products': Array<ProductDTO>;
    /**
     * Order consents
     * @type {Array<string>}
     * @memberof CreateOrderRequestDTO
     */
    'consents'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateOrganizationRequestDTO
 */
export interface CreateOrganizationRequestDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrganizationRequestDTO
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequestDTO
     */
    'type'?: CreateOrganizationRequestDTOTypeEnum;
    /**
     * 
     * @type {OrganizationSettingsDTO}
     * @memberof CreateOrganizationRequestDTO
     */
    'settings': OrganizationSettingsDTO;
}

export const CreateOrganizationRequestDTOTypeEnum = {
    Laboratory: 'Laboratory',
    FulfillmentProvider: 'FulfillmentProvider',
    Organization: 'Organization',
    HealthcareProvider: 'HealthcareProvider',
    Administration: 'Administration',
    InsuranceCompany: 'InsuranceCompany'
} as const;

export type CreateOrganizationRequestDTOTypeEnum = typeof CreateOrganizationRequestDTOTypeEnum[keyof typeof CreateOrganizationRequestDTOTypeEnum];

/**
 * 
 * @export
 * @interface CreatePatientAccountRequestDTO
 */
export interface CreatePatientAccountRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatePatientAccountRequestDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientAccountRequestDTO
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientAccountRequestDTO
     */
    'termsAcceptedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientAccountRequestDTO
     */
    'locale': CreatePatientAccountRequestDTOLocaleEnum;
    /**
     * Account consents
     * @type {Array<string>}
     * @memberof CreatePatientAccountRequestDTO
     */
    'consents'?: Array<string>;
}

export const CreatePatientAccountRequestDTOLocaleEnum = {
    DeDe: 'de_DE',
    EnGb: 'en_GB',
    EsEs: 'es_ES'
} as const;

export type CreatePatientAccountRequestDTOLocaleEnum = typeof CreatePatientAccountRequestDTOLocaleEnum[keyof typeof CreatePatientAccountRequestDTOLocaleEnum];

/**
 * 
 * @export
 * @interface CreatePractitionerAccountRequestDTO
 */
export interface CreatePractitionerAccountRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerAccountRequestDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerAccountRequestDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerAccountRequestDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerAccountRequestDTO
     */
    'registeredNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerAccountRequestDTO
     */
    'speciality': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerAccountRequestDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerAccountRequestDTO
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerAccountRequestDTO
     */
    'locale': CreatePractitionerAccountRequestDTOLocaleEnum;
}

export const CreatePractitionerAccountRequestDTOLocaleEnum = {
    DeDe: 'de_DE',
    EnGb: 'en_GB',
    EsEs: 'es_ES'
} as const;

export type CreatePractitionerAccountRequestDTOLocaleEnum = typeof CreatePractitionerAccountRequestDTOLocaleEnum[keyof typeof CreatePractitionerAccountRequestDTOLocaleEnum];

/**
 * 
 * @export
 * @interface CreateProductOrganizationRoleRequestDTO
 */
export interface CreateProductOrganizationRoleRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateProductOrganizationRoleRequestDTO
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductOrganizationRoleRequestDTO
     */
    'name': CreateProductOrganizationRoleRequestDTONameEnum;
    /**
     * 
     * @type {CreateProductOrganizationRoleSettingsRequestDTO}
     * @memberof CreateProductOrganizationRoleRequestDTO
     */
    'settings'?: CreateProductOrganizationRoleSettingsRequestDTO;
}

export const CreateProductOrganizationRoleRequestDTONameEnum = {
    ConsultationProvider: 'ConsultationProvider',
    DiagnosticResultsInterpreter: 'DiagnosticResultsInterpreter',
    DiagnosticResultsPerformer: 'DiagnosticResultsPerformer',
    FulfillmentProvider: 'FulfillmentProvider',
    Payer: 'Payer'
} as const;

export type CreateProductOrganizationRoleRequestDTONameEnum = typeof CreateProductOrganizationRoleRequestDTONameEnum[keyof typeof CreateProductOrganizationRoleRequestDTONameEnum];

/**
 * 
 * @export
 * @interface CreateProductOrganizationRoleSettingsRequestDTO
 */
export interface CreateProductOrganizationRoleSettingsRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateProductOrganizationRoleSettingsRequestDTO
     */
    'consultationUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductOrganizationRoleSettingsRequestDTO
     */
    'consultationPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductOrganizationRoleSettingsRequestDTO
     */
    'examinationUrl'?: string;
}
/**
 * 
 * @export
 * @interface CreateProductRequestDTO
 */
export interface CreateProductRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    'organizationId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductRequestDTO
     */
    'loincs': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    'procedurePackId': string;
    /**
     * 
     * @type {CreateProductSettingsRequestDTO}
     * @memberof CreateProductRequestDTO
     */
    'settings'?: CreateProductSettingsRequestDTO;
    /**
     * 
     * @type {Array<CreateProductOrganizationRoleRequestDTO>}
     * @memberof CreateProductRequestDTO
     */
    'organizationRoles': Array<CreateProductOrganizationRoleRequestDTO>;
}
/**
 * 
 * @export
 * @interface CreateProductSettingsRequestDTO
 */
export interface CreateProductSettingsRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateProductSettingsRequestDTO
     */
    'ageMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductSettingsRequestDTO
     */
    'ageMax'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProductSettingsRequestDTO
     */
    'reorderUrl'?: string;
}
/**
 * 
 * @export
 * @interface CustomerDTO
 */
export interface CustomerDTO {
    /**
     * Customer email address.
     * @type {string}
     * @memberof CustomerDTO
     */
    'email': string;
    /**
     * Customer first name.
     * @type {string}
     * @memberof CustomerDTO
     */
    'firstName': string;
    /**
     * Customer last name.
     * @type {string}
     * @memberof CustomerDTO
     */
    'lastName': string;
    /**
     * Date of birth of the patient.
     * @type {string}
     * @memberof CustomerDTO
     */
    'dateOfBirth'?: string;
    /**
     * Gender of the patient.
     * @type {string}
     * @memberof CustomerDTO
     */
    'gender'?: string;
    /**
     * Flag to indicate if the customer is a patient.
     * @type {boolean}
     * @memberof CustomerDTO
     */
    'isPatient'?: boolean;
    /**
     * Insurance number of the patient.
     * @type {string}
     * @memberof CustomerDTO
     */
    'insuranceNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerResponseDTO
 */
export interface CustomerResponseDTO {
    /**
     * Customer email address.
     * @type {string}
     * @memberof CustomerResponseDTO
     */
    'email': string;
    /**
     * Customer first name.
     * @type {string}
     * @memberof CustomerResponseDTO
     */
    'firstName': string;
    /**
     * Customer last name.
     * @type {string}
     * @memberof CustomerResponseDTO
     */
    'lastName': string;
    /**
     * Date of birth of the patient.
     * @type {string}
     * @memberof CustomerResponseDTO
     */
    'dateOfBirth'?: string;
    /**
     * Gender of the patient.
     * @type {string}
     * @memberof CustomerResponseDTO
     */
    'gender'?: string;
    /**
     * Flag to indicate if the customer is a patient.
     * @type {boolean}
     * @memberof CustomerResponseDTO
     */
    'isPatient'?: boolean;
    /**
     * Insurance number of the patient.
     * @type {string}
     * @memberof CustomerResponseDTO
     */
    'insuranceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface DTAInvoiceResponseDTO
 */
export interface DTAInvoiceResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof DTAInvoiceResponseDTO
     */
    'url': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DTAInvoiceResponseDTO
     */
    'errors': Array<string>;
}
/**
 * 
 * @export
 * @interface DiagnosticCategoryViewDTO
 */
export interface DiagnosticCategoryViewDTO {
    /**
     * 
     * @type {string}
     * @memberof DiagnosticCategoryViewDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<ParameterDTO>}
     * @memberof DiagnosticCategoryViewDTO
     */
    'parameters': Array<ParameterDTO>;
    /**
     * 
     * @type {Array<PanelWithParametersDTO>}
     * @memberof DiagnosticCategoryViewDTO
     */
    'panels': Array<PanelWithParametersDTO>;
}
/**
 * 
 * @export
 * @interface DiagnosticPrescriptionDTO
 */
export interface DiagnosticPrescriptionDTO {
    /**
     * 
     * @type {string}
     * @memberof DiagnosticPrescriptionDTO
     */
    'id': string;
    /**
     * 
     * @type {Array<ParameterDTO>}
     * @memberof DiagnosticPrescriptionDTO
     */
    'parameters': Array<ParameterDTO>;
    /**
     * 
     * @type {Array<PanelWithParametersDTO>}
     * @memberof DiagnosticPrescriptionDTO
     */
    'panels': Array<PanelWithParametersDTO>;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticPrescriptionDTO
     */
    'patientFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticPrescriptionDTO
     */
    'patientLastName': string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticPrescriptionDTO
     */
    'patientIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticPrescriptionDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticPrescriptionDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticPrescriptionDTO
     */
    'notesForLab': string | null;
    /**
     * 
     * @type {PractitionerDTO}
     * @memberof DiagnosticPrescriptionDTO
     */
    'practitioner': PractitionerDTO;
    /**
     * 
     * @type {object}
     * @memberof DiagnosticPrescriptionDTO
     */
    'patient'?: object;
    /**
     * 
     * @type {AttachmentDTO}
     * @memberof DiagnosticPrescriptionDTO
     */
    'attachment': AttachmentDTO | null;
    /**
     * 
     * @type {object}
     * @memberof DiagnosticPrescriptionDTO
     */
    'diagnosticRequest'?: object;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticPrescriptionDTO
     */
    'collectedAt': string | null;
    /**
     * 
     * @type {AccountDTO}
     * @memberof DiagnosticPrescriptionDTO
     */
    'prescribedToAccount': AccountDTO | null;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticPrescriptionDTO
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface FHIRCodeableConceptDTO
 */
export interface FHIRCodeableConceptDTO {
    /**
     * 
     * @type {string}
     * @memberof FHIRCodeableConceptDTO
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FHIRCodeableConceptDTO
     */
    'display': string;
    /**
     * 
     * @type {string}
     * @memberof FHIRCodeableConceptDTO
     */
    'system': string;
}
/**
 * 
 * @export
 * @interface FHIRCodingDTO
 */
export interface FHIRCodingDTO {
    /**
     * 
     * @type {Array<FHIRCodeableConceptDTO>}
     * @memberof FHIRCodingDTO
     */
    'coding': Array<FHIRCodeableConceptDTO>;
    /**
     * 
     * @type {string}
     * @memberof FHIRCodingDTO
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface FHIRIdentifierDTO
 */
export interface FHIRIdentifierDTO {
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof FHIRIdentifierDTO
     */
    'type': FHIRCodingDTO;
    /**
     * 
     * @type {string}
     * @memberof FHIRIdentifierDTO
     */
    'system'?: string;
    /**
     * 
     * @type {string}
     * @memberof FHIRIdentifierDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface FHIRNameDTO
 */
export interface FHIRNameDTO {
    /**
     * 
     * @type {string}
     * @memberof FHIRNameDTO
     */
    'family': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FHIRNameDTO
     */
    'given': Array<string>;
}
/**
 * 
 * @export
 * @interface FHIRNoteDTO
 */
export interface FHIRNoteDTO {
    /**
     * 
     * @type {FHIRReferenceDTO}
     * @memberof FHIRNoteDTO
     */
    'authorReference': FHIRReferenceDTO;
    /**
     * 
     * @type {string}
     * @memberof FHIRNoteDTO
     */
    'authorString': string;
    /**
     * 
     * @type {string}
     * @memberof FHIRNoteDTO
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof FHIRNoteDTO
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface FHIRPresentedFormDTO
 */
export interface FHIRPresentedFormDTO {
    /**
     * 
     * @type {string}
     * @memberof FHIRPresentedFormDTO
     */
    'contentType': string;
    /**
     * 
     * @type {string}
     * @memberof FHIRPresentedFormDTO
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof FHIRPresentedFormDTO
     */
    'data': string;
}
/**
 * 
 * @export
 * @interface FHIRQuantityDTO
 */
export interface FHIRQuantityDTO {
    /**
     * 
     * @type {string}
     * @memberof FHIRQuantityDTO
     */
    'comparator'?: string;
    /**
     * 
     * @type {number}
     * @memberof FHIRQuantityDTO
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof FHIRQuantityDTO
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof FHIRQuantityDTO
     */
    'system': string;
    /**
     * 
     * @type {string}
     * @memberof FHIRQuantityDTO
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface FHIRRangeDTO
 */
export interface FHIRRangeDTO {
    /**
     * 
     * @type {FHIRQuantityDTO}
     * @memberof FHIRRangeDTO
     */
    'low': FHIRQuantityDTO;
    /**
     * 
     * @type {FHIRQuantityDTO}
     * @memberof FHIRRangeDTO
     */
    'high': FHIRQuantityDTO;
}
/**
 * 
 * @export
 * @interface FHIRReferenceDTO
 */
export interface FHIRReferenceDTO {
    /**
     * 
     * @type {string}
     * @memberof FHIRReferenceDTO
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FHIRReferenceDTO
     */
    'reference': string;
}
/**
 * 
 * @export
 * @interface FHIRReferenceRangeDTO
 */
export interface FHIRReferenceRangeDTO {
    /**
     * 
     * @type {FHIRQuantityDTO}
     * @memberof FHIRReferenceRangeDTO
     */
    'low'?: FHIRQuantityDTO;
    /**
     * 
     * @type {FHIRQuantityDTO}
     * @memberof FHIRReferenceRangeDTO
     */
    'high'?: FHIRQuantityDTO;
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof FHIRReferenceRangeDTO
     */
    'type': FHIRCodingDTO;
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof FHIRReferenceRangeDTO
     */
    'normalValue'?: FHIRCodingDTO;
    /**
     * 
     * @type {Array<FHIRCodingDTO>}
     * @memberof FHIRReferenceRangeDTO
     */
    'appliesTo'?: Array<FHIRCodingDTO>;
    /**
     * 
     * @type {FHIRRangeDTO}
     * @memberof FHIRReferenceRangeDTO
     */
    'age'?: FHIRRangeDTO;
    /**
     * 
     * @type {string}
     * @memberof FHIRReferenceRangeDTO
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface FHIRSpecimenContainerDTO
 */
export interface FHIRSpecimenContainerDTO {
    /**
     * 
     * @type {Array<FHIRIdentifierDTO>}
     * @memberof FHIRSpecimenContainerDTO
     */
    'identifier': Array<FHIRIdentifierDTO>;
}
/**
 * 
 * @export
 * @interface FHIRTelecomDTO
 */
export interface FHIRTelecomDTO {
    /**
     * 
     * @type {string}
     * @memberof FHIRTelecomDTO
     */
    'system': string;
    /**
     * 
     * @type {string}
     * @memberof FHIRTelecomDTO
     */
    'use': string;
    /**
     * 
     * @type {string}
     * @memberof FHIRTelecomDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface GetAttachmentResponseDTO
 */
export interface GetAttachmentResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentResponseDTO
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface GetAttachmentUrlByDiagnosticPrescriptionIdResponseDTO
 */
export interface GetAttachmentUrlByDiagnosticPrescriptionIdResponseDTO {
    /**
     * Attachment presigned url
     * @type {string}
     * @memberof GetAttachmentUrlByDiagnosticPrescriptionIdResponseDTO
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface GetDiagnosticResultViewResponseDTO
 */
export interface GetDiagnosticResultViewResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTO
     */
    'attachmentId': string | null;
    /**
     * 
     * @type {GetDiagnosticResultViewResponseDTOFollowUp}
     * @memberof GetDiagnosticResultViewResponseDTO
     */
    'followUp'?: GetDiagnosticResultViewResponseDTOFollowUp;
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {Array<GetDiagnosticResultViewResponseDTOObservationsOutcomeInner>}
     * @memberof GetDiagnosticResultViewResponseDTO
     */
    'observationsOutcome': Array<GetDiagnosticResultViewResponseDTOObservationsOutcomeInner>;
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTO
     */
    'orderReference': string | null;
    /**
     * 
     * @type {GetDiagnosticResultViewResponseDTOPatient}
     * @memberof GetDiagnosticResultViewResponseDTO
     */
    'patient': GetDiagnosticResultViewResponseDTOPatient;
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTO
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface GetDiagnosticResultViewResponseDTOFollowUp
 */
export interface GetDiagnosticResultViewResponseDTOFollowUp {
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTOFollowUp
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTOFollowUp
     */
    'consultationUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTOFollowUp
     */
    'examinationUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTOFollowUp
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDiagnosticResultViewResponseDTOObservationsOutcomeInner
 */
export interface GetDiagnosticResultViewResponseDTOObservationsOutcomeInner {
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTOObservationsOutcomeInner
     */
    'result'?: string;
}
/**
 * 
 * @export
 * @interface GetDiagnosticResultViewResponseDTOPatient
 */
export interface GetDiagnosticResultViewResponseDTOPatient {
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTOPatient
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDiagnosticResultViewResponseDTOPatient
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface GetLaboratoryInvoices202ResponseDTO
 */
export interface GetLaboratoryInvoices202ResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof GetLaboratoryInvoices202ResponseDTO
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GetLaboratoryInvoicesResponseDTO
 */
export interface GetLaboratoryInvoicesResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof GetLaboratoryInvoicesResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetLaboratoryInvoicesResponseDTO
     */
    'diagnosticRequestId': string;
    /**
     * 
     * @type {string}
     * @memberof GetLaboratoryInvoicesResponseDTO
     */
    'placerId': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLaboratoryInvoicesResponseDTO
     */
    'url': string | null;
    /**
     * 
     * @type {AddressResponseDTO}
     * @memberof GetLaboratoryInvoicesResponseDTO
     */
    'address': AddressResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof GetLaboratoryInvoicesResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetLaboratoryInvoicesResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface GetOrderResponseDTO
 */
export interface GetOrderResponseDTO {
    /**
     * 
     * @type {Array<AddressDTO>}
     * @memberof GetOrderResponseDTO
     */
    'address': Array<AddressDTO>;
    /**
     * 
     * @type {string}
     * @memberof GetOrderResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {CustomerResponseDTO}
     * @memberof GetOrderResponseDTO
     */
    'customer': CustomerResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof GetOrderResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOrderResponseDTO
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof GetOrderResponseDTO
     */
    'placerId': string;
    /**
     * 
     * @type {Array<OrderProductResponseDTO>}
     * @memberof GetOrderResponseDTO
     */
    'products': Array<OrderProductResponseDTO>;
    /**
     * 
     * @type {string}
     * @memberof GetOrderResponseDTO
     */
    'reference': string | null;
    /**
     * 
     * @type {Array<OrderStatusDTO>}
     * @memberof GetOrderResponseDTO
     */
    'statuses': Array<OrderStatusDTO>;
    /**
     * 
     * @type {string}
     * @memberof GetOrderResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface GetOrganizationResponseDTO
 */
export interface GetOrganizationResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrganizationResponseDTO
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationResponseDTO
     */
    'type': GetOrganizationResponseDTOTypeEnum;
    /**
     * 
     * @type {OrganizationSettingsDTO}
     * @memberof GetOrganizationResponseDTO
     */
    'settings': OrganizationSettingsDTO;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationResponseDTO
     */
    'updatedAt': string;
}

export const GetOrganizationResponseDTOTypeEnum = {
    Laboratory: 'Laboratory',
    FulfillmentProvider: 'FulfillmentProvider',
    Organization: 'Organization',
    HealthcareProvider: 'HealthcareProvider',
    Administration: 'Administration',
    InsuranceCompany: 'InsuranceCompany'
} as const;

export type GetOrganizationResponseDTOTypeEnum = typeof GetOrganizationResponseDTOTypeEnum[keyof typeof GetOrganizationResponseDTOTypeEnum];

/**
 * 
 * @export
 * @interface GetProductOrganizationRoleResponseDTO
 */
export interface GetProductOrganizationRoleResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof GetProductOrganizationRoleResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductOrganizationRoleResponseDTO
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductOrganizationRoleResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {GetProductOrganizationRoleResponseSettingsDTO}
     * @memberof GetProductOrganizationRoleResponseDTO
     */
    'settings': GetProductOrganizationRoleResponseSettingsDTO | null;
    /**
     * 
     * @type {string}
     * @memberof GetProductOrganizationRoleResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductOrganizationRoleResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface GetProductOrganizationRoleResponseSettingsDTO
 */
export interface GetProductOrganizationRoleResponseSettingsDTO {
    /**
     * 
     * @type {string}
     * @memberof GetProductOrganizationRoleResponseSettingsDTO
     */
    'consultationUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProductOrganizationRoleResponseSettingsDTO
     */
    'examinationUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProductOrganizationRoleResponseSettingsDTO
     */
    'phoneNumber': string | null;
}
/**
 * 
 * @export
 * @interface GetProductResponseDTO
 */
export interface GetProductResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof GetProductResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponseDTO
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponseDTO
     */
    'organizationId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProductResponseDTO
     */
    'loincs': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponseDTO
     */
    'procedurePackId': string;
    /**
     * 
     * @type {GetProductSettingsResponseDTO}
     * @memberof GetProductResponseDTO
     */
    'settings'?: GetProductSettingsResponseDTO | null;
    /**
     * 
     * @type {Array<GetProductOrganizationRoleResponseDTO>}
     * @memberof GetProductResponseDTO
     */
    'organizationRoles': Array<GetProductOrganizationRoleResponseDTO>;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetProductResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface GetProductSettingsResponseDTO
 */
export interface GetProductSettingsResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof GetProductSettingsResponseDTO
     */
    'ageMin': number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProductSettingsResponseDTO
     */
    'ageMax': number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProductSettingsResponseDTO
     */
    'reorderUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface GetSpecimenResponseDTO
 */
export interface GetSpecimenResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof GetSpecimenResponseDTO
     */
    'resourceType': string;
    /**
     * 
     * @type {string}
     * @memberof GetSpecimenResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {MetaResponseDTO}
     * @memberof GetSpecimenResponseDTO
     */
    'meta': MetaResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof GetSpecimenResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {TypeResponseDTO}
     * @memberof GetSpecimenResponseDTO
     */
    'type': TypeResponseDTO;
    /**
     * 
     * @type {ReferenceResponseDTO}
     * @memberof GetSpecimenResponseDTO
     */
    'subject': ReferenceResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof GetSpecimenResponseDTO
     */
    'receivedTime'?: string;
    /**
     * 
     * @type {CollectionResponseDTO}
     * @memberof GetSpecimenResponseDTO
     */
    'collection'?: CollectionResponseDTO;
    /**
     * 
     * @type {Array<ContainerResponseDTO>}
     * @memberof GetSpecimenResponseDTO
     */
    'container': Array<ContainerResponseDTO>;
    /**
     * 
     * @type {Array<ConditionResponseDTO>}
     * @memberof GetSpecimenResponseDTO
     */
    'condition'?: Array<ConditionResponseDTO>;
}
/**
 * 
 * @export
 * @interface GetToken200Response
 */
export interface GetToken200Response {
    /**
     * 
     * @type {string}
     * @memberof GetToken200Response
     */
    'access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetToken200Response
     */
    'expires_in'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetToken200Response
     */
    'token_type'?: string;
}
/**
 * 
 * @export
 * @interface GetToken400Response
 */
export interface GetToken400Response {
    /**
     * 
     * @type {string}
     * @memberof GetToken400Response
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetToken400Response
     */
    'message'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetToken400Response
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @interface IdentifierResponseDTO
 */
export interface IdentifierResponseDTO {
    /**
     * 
     * @type {TypeResponseDTO}
     * @memberof IdentifierResponseDTO
     */
    'type': TypeResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof IdentifierResponseDTO
     */
    'system'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentifierResponseDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface InvitePatientToCollectDiagnosticPrescriptionDTO
 */
export interface InvitePatientToCollectDiagnosticPrescriptionDTO {
    /**
     * 
     * @type {string}
     * @memberof InvitePatientToCollectDiagnosticPrescriptionDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InvitePatientToCollectDiagnosticPrescriptionDTO
     */
    'diagnosticPrescriptionId': string;
    /**
     * 
     * @type {string}
     * @memberof InvitePatientToCollectDiagnosticPrescriptionDTO
     */
    'locale': InvitePatientToCollectDiagnosticPrescriptionDTOLocaleEnum;
}

export const InvitePatientToCollectDiagnosticPrescriptionDTOLocaleEnum = {
    DeDe: 'de_DE',
    EnGb: 'en_GB',
    EsEs: 'es_ES'
} as const;

export type InvitePatientToCollectDiagnosticPrescriptionDTOLocaleEnum = typeof InvitePatientToCollectDiagnosticPrescriptionDTOLocaleEnum[keyof typeof InvitePatientToCollectDiagnosticPrescriptionDTOLocaleEnum];

/**
 * 
 * @export
 * @interface ListAccounts403Response
 */
export interface ListAccounts403Response {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts403Response
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAccounts403Response
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ListAccounts403Response
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @interface ListProductResponseDTO
 */
export interface ListProductResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ListProductResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ListProductResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ListProductResponseDTO
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof ListProductResponseDTO
     */
    'organizationId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListProductResponseDTO
     */
    'loincs': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListProductResponseDTO
     */
    'procedurePackId': string;
    /**
     * 
     * @type {GetProductSettingsResponseDTO}
     * @memberof ListProductResponseDTO
     */
    'settings'?: GetProductSettingsResponseDTO | null;
    /**
     * 
     * @type {string}
     * @memberof ListProductResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ListProductResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface MetaResponseDTO
 */
export interface MetaResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof MetaResponseDTO
     */
    'lastUpdated': string;
    /**
     * 
     * @type {string}
     * @memberof MetaResponseDTO
     */
    'versionId': string;
}
/**
 * 
 * @export
 * @interface OrderProductResponseDTO
 */
export interface OrderProductResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderProductResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductResponseDTO
     */
    'orderProductId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductResponseDTO
     */
    'sku': string;
    /**
     * 
     * @type {Array<OrderProductSpecimenDTO>}
     * @memberof OrderProductResponseDTO
     */
    'specimens': Array<OrderProductSpecimenDTO>;
    /**
     * 
     * @type {Array<OrderProductStatusDTO>}
     * @memberof OrderProductResponseDTO
     */
    'statuses': Array<OrderProductStatusDTO>;
    /**
     * 
     * @type {string}
     * @memberof OrderProductResponseDTO
     */
    'trackingCompany': string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductResponseDTO
     */
    'trackingId': string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductResponseDTO
     */
    'trackingUrl': string | null;
}
/**
 * 
 * @export
 * @interface OrderProductSpecimenDTO
 */
export interface OrderProductSpecimenDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderProductSpecimenDTO
     */
    'containerId': string;
}
/**
 * 
 * @export
 * @interface OrderProductStatusDTO
 */
export interface OrderProductStatusDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderProductStatusDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductStatusDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface OrderStatusDTO
 */
export interface OrderStatusDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderStatusDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface OrganizationPublicSettingsDTO
 */
export interface OrganizationPublicSettingsDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationPublicSettingsDTO
     */
    'locale': OrganizationPublicSettingsDTOLocaleEnum;
    /**
     * 
     * @type {OrganizationPublicSettingsNameDTO}
     * @memberof OrganizationPublicSettingsDTO
     */
    'name': OrganizationPublicSettingsNameDTO;
    /**
     * 
     * @type {OrganizationPublicSettingsThemeDTO}
     * @memberof OrganizationPublicSettingsDTO
     */
    'theme': OrganizationPublicSettingsThemeDTO | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPublicSettingsDTO
     */
    'website': string | null;
}

export const OrganizationPublicSettingsDTOLocaleEnum = {
    DeDe: 'de_DE',
    EnGb: 'en_GB',
    EsEs: 'es_ES'
} as const;

export type OrganizationPublicSettingsDTOLocaleEnum = typeof OrganizationPublicSettingsDTOLocaleEnum[keyof typeof OrganizationPublicSettingsDTOLocaleEnum];

/**
 * 
 * @export
 * @interface OrganizationPublicSettingsNameDTO
 */
export interface OrganizationPublicSettingsNameDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationPublicSettingsNameDTO
     */
    'display': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPublicSettingsNameDTO
     */
    'legal': string;
}
/**
 * 
 * @export
 * @interface OrganizationPublicSettingsResponseDTO
 */
export interface OrganizationPublicSettingsResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationPublicSettingsResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {OrganizationPublicSettingsDTO}
     * @memberof OrganizationPublicSettingsResponseDTO
     */
    'settings': OrganizationPublicSettingsDTO;
}
/**
 * 
 * @export
 * @interface OrganizationPublicSettingsThemeDTO
 */
export interface OrganizationPublicSettingsThemeDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationPublicSettingsThemeDTO
     */
    'logoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPublicSettingsThemeDTO
     */
    'primaryColor': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPublicSettingsThemeDTO
     */
    'secondaryColor': string;
}
/**
 * 
 * @export
 * @interface OrganizationSettingsDTO
 */
export interface OrganizationSettingsDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsDTO
     */
    'codingSystem': OrganizationSettingsDTOCodingSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsDTO
     */
    'locale': OrganizationSettingsDTOLocaleEnum;
    /**
     * 
     * @type {OrganizationSettingsNameDTO}
     * @memberof OrganizationSettingsDTO
     */
    'name': OrganizationSettingsNameDTO;
    /**
     * 
     * @type {OrganizationSettingsNotificationDTO}
     * @memberof OrganizationSettingsDTO
     */
    'notifications': OrganizationSettingsNotificationDTO | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsDTO
     */
    'supportEmail': string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsDTO
     */
    'termsUrl': string | null;
    /**
     * 
     * @type {OrganizationSettingsThemeDTO}
     * @memberof OrganizationSettingsDTO
     */
    'theme': OrganizationSettingsThemeDTO | null;
    /**
     * 
     * @type {OrganizationSettingsWebhooksDTO}
     * @memberof OrganizationSettingsDTO
     */
    'webhooks': OrganizationSettingsWebhooksDTO | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsDTO
     */
    'website': string | null;
}

export const OrganizationSettingsDTOCodingSystemEnum = {
    Blst: 'BLST',
    Loinc: 'LOINC'
} as const;

export type OrganizationSettingsDTOCodingSystemEnum = typeof OrganizationSettingsDTOCodingSystemEnum[keyof typeof OrganizationSettingsDTOCodingSystemEnum];
export const OrganizationSettingsDTOLocaleEnum = {
    DeDe: 'de_DE',
    EnGb: 'en_GB',
    EsEs: 'es_ES'
} as const;

export type OrganizationSettingsDTOLocaleEnum = typeof OrganizationSettingsDTOLocaleEnum[keyof typeof OrganizationSettingsDTOLocaleEnum];

/**
 * 
 * @export
 * @interface OrganizationSettingsNameDTO
 */
export interface OrganizationSettingsNameDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsNameDTO
     */
    'display': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsNameDTO
     */
    'legal': string;
}
/**
 * 
 * @export
 * @interface OrganizationSettingsNotificationDTO
 */
export interface OrganizationSettingsNotificationDTO {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSettingsNotificationDTO
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsNotificationDTO
     */
    'type': OrganizationSettingsNotificationDTOTypeEnum;
}

export const OrganizationSettingsNotificationDTOTypeEnum = {
    Email: 'email'
} as const;

export type OrganizationSettingsNotificationDTOTypeEnum = typeof OrganizationSettingsNotificationDTOTypeEnum[keyof typeof OrganizationSettingsNotificationDTOTypeEnum];

/**
 * 
 * @export
 * @interface OrganizationSettingsThemeDTO
 */
export interface OrganizationSettingsThemeDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsThemeDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsThemeDTO
     */
    'logoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsThemeDTO
     */
    'primaryColor': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsThemeDTO
     */
    'secondaryColor': string;
}
/**
 * 
 * @export
 * @interface OrganizationSettingsWebhooksDTO
 */
export interface OrganizationSettingsWebhooksDTO {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSettingsWebhooksDTO
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsWebhooksDTO
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettingsWebhooksDTO
     */
    'secret': string;
}
/**
 * 
 * @export
 * @interface PanelWithParametersDTO
 */
export interface PanelWithParametersDTO {
    /**
     * 
     * @type {string}
     * @memberof PanelWithParametersDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PanelWithParametersDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PanelWithParametersDTO
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PanelWithParametersDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PanelWithParametersDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<ParameterDTO>}
     * @memberof PanelWithParametersDTO
     */
    'parameters': Array<ParameterDTO>;
}
/**
 * 
 * @export
 * @interface ParameterDTO
 */
export interface ParameterDTO {
    /**
     * 
     * @type {string}
     * @memberof ParameterDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ParameterDTO
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ParameterDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ParameterDTO
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParameterDTO
     */
    'system': string;
}
/**
 * 
 * @export
 * @interface PatientRequestDTO
 */
export interface PatientRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDTO
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDTO
     */
    'gender': string;
    /**
     * 
     * @type {Array<FHIRIdentifierDTO>}
     * @memberof PatientRequestDTO
     */
    'identifier': Array<FHIRIdentifierDTO>;
    /**
     * 
     * @type {Array<FHIRNameDTO>}
     * @memberof PatientRequestDTO
     */
    'name': Array<FHIRNameDTO>;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestDTO
     */
    'resourceType': string;
    /**
     * 
     * @type {Array<FHIRTelecomDTO>}
     * @memberof PatientRequestDTO
     */
    'telecom': Array<FHIRTelecomDTO>;
}
/**
 * 
 * @export
 * @interface PractitionerDTO
 */
export interface PractitionerDTO {
    /**
     * 
     * @type {string}
     * @memberof PractitionerDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerDTO
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerDTO
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerDTO
     */
    'registeredNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerDTO
     */
    'speciality': string;
    /**
     * 
     * @type {AddressDTO}
     * @memberof PractitionerDTO
     */
    'address'?: AddressDTO;
    /**
     * 
     * @type {string}
     * @memberof PractitionerDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ProductAgeSettingsDTO
 */
export interface ProductAgeSettingsDTO {
    /**
     * 
     * @type {number}
     * @memberof ProductAgeSettingsDTO
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductAgeSettingsDTO
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface ProductDTO
 */
export interface ProductDTO {
    /**
     * Id of the product.
     * @type {string}
     * @memberof ProductDTO
     */
    'id'?: string;
    /**
     * SKU of the product.
     * @type {string}
     * @memberof ProductDTO
     */
    'sku'?: string;
}
/**
 * 
 * @export
 * @interface ProductPublicSettingsDTOResponseSettings
 */
export interface ProductPublicSettingsDTOResponseSettings {
    /**
     * 
     * @type {ProductAgeSettingsDTO}
     * @memberof ProductPublicSettingsDTOResponseSettings
     */
    'age'?: ProductAgeSettingsDTO;
}
/**
 * 
 * @export
 * @interface ProductPublicSettingsResponseDTO
 */
export interface ProductPublicSettingsResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ProductPublicSettingsResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductPublicSettingsResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductPublicSettingsResponseDTO
     */
    'sku': string;
    /**
     * 
     * @type {ProductPublicSettingsDTOResponseSettings}
     * @memberof ProductPublicSettingsResponseDTO
     */
    'settings': ProductPublicSettingsDTOResponseSettings | null;
}
/**
 * 
 * @export
 * @interface RecreateOrderRequestDTO
 */
export interface RecreateOrderRequestDTO {
    /**
     * Placer ID of the order needs to be recreated
     * @type {string}
     * @memberof RecreateOrderRequestDTO
     */
    'placerId': string;
}
/**
 * 
 * @export
 * @interface ReferenceResponseDTO
 */
export interface ReferenceResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ReferenceResponseDTO
     */
    'reference': string;
}
/**
 * 
 * @export
 * @interface RequestAccountPasswordResetRequestDTO
 */
export interface RequestAccountPasswordResetRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof RequestAccountPasswordResetRequestDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RequestAccountPasswordResetRequestDTO
     */
    'tenantId': RequestAccountPasswordResetRequestDTOTenantIdEnum;
}

export const RequestAccountPasswordResetRequestDTOTenantIdEnum = {
    ApiClientsIuoe6: 'api-clients-iuoe6',
    DaslabInfraStaging: 'daslab-infra-staging',
    ConsoleAppI4exj: 'console-app-i4exj',
    PatientsApp6b12p: 'patients-app-6b12p',
    PractitionersAppZswbi: 'practitioners-app-zswbi'
} as const;

export type RequestAccountPasswordResetRequestDTOTenantIdEnum = typeof RequestAccountPasswordResetRequestDTOTenantIdEnum[keyof typeof RequestAccountPasswordResetRequestDTOTenantIdEnum];

/**
 * 
 * @export
 * @interface SignInPatientAccountRequestDTO
 */
export interface SignInPatientAccountRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof SignInPatientAccountRequestDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignInPatientAccountRequestDTO
     */
    'redirectUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignInPatientAccountRequestDTO
     */
    'emailTemplate'?: string;
}
/**
 * 
 * @export
 * @interface SpecimenConditionCodeableConceptDTO
 */
export interface SpecimenConditionCodeableConceptDTO {
    /**
     * Symbol in syntax defined by the system
     * @type {string}
     * @memberof SpecimenConditionCodeableConceptDTO
     */
    'code': SpecimenConditionCodeableConceptDTOCodeEnum;
    /**
     * Representation defined by the system
     * @type {string}
     * @memberof SpecimenConditionCodeableConceptDTO
     */
    'display': SpecimenConditionCodeableConceptDTODisplayEnum;
    /**
     * Identity of the terminology system
     * @type {string}
     * @memberof SpecimenConditionCodeableConceptDTO
     */
    'system': string;
}

export const SpecimenConditionCodeableConceptDTOCodeEnum = {
    _281268007: '281268007',
    _123840003: '123840003',
    _733480003: '733480003',
    _281263003: '281263003',
    _135839007: '135839007',
    _125159002: '125159002'
} as const;

export type SpecimenConditionCodeableConceptDTOCodeEnum = typeof SpecimenConditionCodeableConceptDTOCodeEnum[keyof typeof SpecimenConditionCodeableConceptDTOCodeEnum];
export const SpecimenConditionCodeableConceptDTODisplayEnum = {
    InsufficientSample: 'Insufficient sample',
    SampleContaminated: 'Sample contaminated',
    SampleDecomposed: 'Sample decomposed',
    SampleLeaked: 'Sample leaked',
    SampleRejected: 'Sample rejected',
    SampleUnlabeled: 'Sample unlabeled'
} as const;

export type SpecimenConditionCodeableConceptDTODisplayEnum = typeof SpecimenConditionCodeableConceptDTODisplayEnum[keyof typeof SpecimenConditionCodeableConceptDTODisplayEnum];

/**
 * 
 * @export
 * @interface SpecimenConditionCodingDTO
 */
export interface SpecimenConditionCodingDTO {
    /**
     * A list of codes defined by a terminology system
     * @type {Array<SpecimenConditionCodeableConceptDTO>}
     * @memberof SpecimenConditionCodingDTO
     */
    'coding': Array<SpecimenConditionCodeableConceptDTO>;
    /**
     * Plain text representation of the concept
     * @type {string}
     * @memberof SpecimenConditionCodingDTO
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface SpecimenDTO
 */
export interface SpecimenDTO {
    /**
     * 
     * @type {string}
     * @memberof SpecimenDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecimenDTO
     */
    'resourceType': string;
    /**
     * 
     * @type {CollectionDTO}
     * @memberof SpecimenDTO
     */
    'collection': CollectionDTO;
    /**
     * 
     * @type {Array<FHIRSpecimenContainerDTO>}
     * @memberof SpecimenDTO
     */
    'container': Array<FHIRSpecimenContainerDTO>;
}
/**
 * 
 * @export
 * @interface SpecimenShortDTO
 */
export interface SpecimenShortDTO {
    /**
     * 
     * @type {string}
     * @memberof SpecimenShortDTO
     */
    'containerId': string;
}
/**
 * 
 * @export
 * @interface TypeResponseDTO
 */
export interface TypeResponseDTO {
    /**
     * 
     * @type {Array<CodingResponseDTO>}
     * @memberof TypeResponseDTO
     */
    'coding': Array<CodingResponseDTO>;
}
/**
 * 
 * @export
 * @interface UpdateAccountPasswordRequestDTO
 */
export interface UpdateAccountPasswordRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountPasswordRequestDTO
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UpdateDiagnosticPrescriptionRequestDTO
 */
export interface UpdateDiagnosticPrescriptionRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateDiagnosticPrescriptionRequestDTO
     */
    'patientFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiagnosticPrescriptionRequestDTO
     */
    'patientLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiagnosticPrescriptionRequestDTO
     */
    'patientIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiagnosticPrescriptionRequestDTO
     */
    'notesForLab'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDiagnosticPrescriptionRequestDTO
     */
    'parameterIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDiagnosticPrescriptionRequestDTO
     */
    'panelIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateDiagnosticReportDTO
 */
export interface UpdateDiagnosticReportDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateDiagnosticReportDTO
     */
    'resourceType': string;
    /**
     * 
     * @type {Array<FHIRIdentifierDTO>}
     * @memberof UpdateDiagnosticReportDTO
     */
    'identifier': Array<FHIRIdentifierDTO>;
    /**
     * 
     * @type {Array<FHIRReferenceDTO>}
     * @memberof UpdateDiagnosticReportDTO
     */
    'basedOn': Array<FHIRReferenceDTO>;
    /**
     * 
     * @type {Array<FHIRPresentedFormDTO>}
     * @memberof UpdateDiagnosticReportDTO
     */
    'presentedForm'?: Array<FHIRPresentedFormDTO>;
}
/**
 * 
 * @export
 * @interface UpdateDiagnosticRequestRequestDTO
 */
export interface UpdateDiagnosticRequestRequestDTO {
    /**
     * 
     * @type {PatientRequestDTO}
     * @memberof UpdateDiagnosticRequestRequestDTO
     */
    'patient': PatientRequestDTO;
    /**
     * 
     * @type {SpecimenDTO}
     * @memberof UpdateDiagnosticRequestRequestDTO
     */
    'specimen': SpecimenDTO;
    /**
     * 
     * @type {UpdateDiagnosticRequestServiceRequestDTO}
     * @memberof UpdateDiagnosticRequestRequestDTO
     */
    'serviceRequest': UpdateDiagnosticRequestServiceRequestDTO;
}
/**
 * 
 * @export
 * @interface UpdateDiagnosticRequestServiceRequestDTO
 */
export interface UpdateDiagnosticRequestServiceRequestDTO {
    /**
     * 
     * @type {Array<FHIRIdentifierDTO>}
     * @memberof UpdateDiagnosticRequestServiceRequestDTO
     */
    'identifier': Array<FHIRIdentifierDTO>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiagnosticRequestServiceRequestDTO
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDiagnosticResultRequestDTO
 */
export interface UpdateDiagnosticResultRequestDTO {
    /**
     * 
     * @type {UpdateDiagnosticReportDTO}
     * @memberof UpdateDiagnosticResultRequestDTO
     */
    'diagnosticReport': UpdateDiagnosticReportDTO;
    /**
     * 
     * @type {Array<UpdateObservationDTO>}
     * @memberof UpdateDiagnosticResultRequestDTO
     */
    'observations'?: Array<UpdateObservationDTO>;
}
/**
 * 
 * @export
 * @interface UpdateLaboratoryInvoicesRequestDTO
 */
export interface UpdateLaboratoryInvoicesRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateLaboratoryInvoicesRequestDTO
     */
    'diagnosticRequestId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLaboratoryInvoicesRequestDTO
     */
    'placerId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLaboratoryInvoicesRequestDTO
     */
    'contentType': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLaboratoryInvoicesRequestDTO
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLaboratoryInvoicesRequestDTO
     */
    'data': string;
}
/**
 * 
 * @export
 * @interface UpdateObservationDTO
 */
export interface UpdateObservationDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateObservationDTO
     */
    'resourceType': string;
    /**
     * 
     * @type {Array<FHIRIdentifierDTO>}
     * @memberof UpdateObservationDTO
     */
    'identifier'?: Array<FHIRIdentifierDTO>;
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof UpdateObservationDTO
     */
    'code': FHIRCodingDTO;
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof UpdateObservationDTO
     */
    'valueCodeableConcept'?: FHIRCodingDTO;
    /**
     * 
     * @type {FHIRQuantityDTO}
     * @memberof UpdateObservationDTO
     */
    'valueQuantity'?: FHIRQuantityDTO;
    /**
     * 
     * @type {Array<FHIRReferenceRangeDTO>}
     * @memberof UpdateObservationDTO
     */
    'referenceRange'?: Array<FHIRReferenceRangeDTO>;
    /**
     * 
     * @type {Array<FHIRNoteDTO>}
     * @memberof UpdateObservationDTO
     */
    'note'?: Array<FHIRNoteDTO>;
    /**
     * 
     * @type {FHIRCodingDTO}
     * @memberof UpdateObservationDTO
     */
    'method': FHIRCodingDTO;
}
/**
 * 
 * @export
 * @interface UpdateOrderProductItemDTO
 */
export interface UpdateOrderProductItemDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderProductItemDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderProductItemDTO
     */
    'orderProductId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderProductItemDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderProductItemDTO
     */
    'trackingId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderProductItemDTO
     */
    'trackingCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderProductItemDTO
     */
    'trackingUrl'?: string;
    /**
     * 
     * @type {Array<SpecimenShortDTO>}
     * @memberof UpdateOrderProductItemDTO
     */
    'specimens': Array<SpecimenShortDTO>;
}
/**
 * 
 * @export
 * @interface UpdateOrderRequestDTO
 */
export interface UpdateOrderRequestDTO {
    /**
     * 
     * @type {Array<UpdateOrderProductItemDTO>}
     * @memberof UpdateOrderRequestDTO
     */
    'products': Array<UpdateOrderProductItemDTO>;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationRequestDTO
 */
export interface UpdateOrganizationRequestDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationRequestDTO
     */
    'active'?: boolean;
    /**
     * 
     * @type {UpdateOrganizationSettingsRequestDTO}
     * @memberof UpdateOrganizationRequestDTO
     */
    'settings'?: UpdateOrganizationSettingsRequestDTO;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationSettingsBarcodeDTO
 */
export interface UpdateOrganizationSettingsBarcodeDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsBarcodeDTO
     */
    'format'?: UpdateOrganizationSettingsBarcodeDTOFormatEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrganizationSettingsBarcodeDTO
     */
    'maxCharacters'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrganizationSettingsBarcodeDTO
     */
    'minCharacters'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsBarcodeDTO
     */
    'prefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsBarcodeDTO
     */
    'suffix'?: string;
}

export const UpdateOrganizationSettingsBarcodeDTOFormatEnum = {
    Code128: 'Code 128',
    Interleaved2Of5: 'Interleaved 2 of 5'
} as const;

export type UpdateOrganizationSettingsBarcodeDTOFormatEnum = typeof UpdateOrganizationSettingsBarcodeDTOFormatEnum[keyof typeof UpdateOrganizationSettingsBarcodeDTOFormatEnum];

/**
 * 
 * @export
 * @interface UpdateOrganizationSettingsNameDTO
 */
export interface UpdateOrganizationSettingsNameDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsNameDTO
     */
    'display'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsNameDTO
     */
    'legal'?: string;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationSettingsNotificationDTO
 */
export interface UpdateOrganizationSettingsNotificationDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationSettingsNotificationDTO
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsNotificationDTO
     */
    'type'?: UpdateOrganizationSettingsNotificationDTOTypeEnum;
}

export const UpdateOrganizationSettingsNotificationDTOTypeEnum = {
    Email: 'email'
} as const;

export type UpdateOrganizationSettingsNotificationDTOTypeEnum = typeof UpdateOrganizationSettingsNotificationDTOTypeEnum[keyof typeof UpdateOrganizationSettingsNotificationDTOTypeEnum];

/**
 * 
 * @export
 * @interface UpdateOrganizationSettingsRequestDTO
 */
export interface UpdateOrganizationSettingsRequestDTO {
    /**
     * 
     * @type {UpdateOrganizationSettingsBarcodeDTO}
     * @memberof UpdateOrganizationSettingsRequestDTO
     */
    'barcode'?: UpdateOrganizationSettingsBarcodeDTO;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsRequestDTO
     */
    'codingSystem'?: UpdateOrganizationSettingsRequestDTOCodingSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsRequestDTO
     */
    'locale'?: UpdateOrganizationSettingsRequestDTOLocaleEnum;
    /**
     * 
     * @type {UpdateOrganizationSettingsNameDTO}
     * @memberof UpdateOrganizationSettingsRequestDTO
     */
    'name'?: UpdateOrganizationSettingsNameDTO;
    /**
     * 
     * @type {UpdateOrganizationSettingsNotificationDTO}
     * @memberof UpdateOrganizationSettingsRequestDTO
     */
    'notifications'?: UpdateOrganizationSettingsNotificationDTO | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsRequestDTO
     */
    'supportEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsRequestDTO
     */
    'termsUrl'?: string | null;
    /**
     * 
     * @type {UpdateOrganizationSettingsThemeDTO}
     * @memberof UpdateOrganizationSettingsRequestDTO
     */
    'theme'?: UpdateOrganizationSettingsThemeDTO | null;
    /**
     * 
     * @type {UpdateOrganizationSettingsWebhooksDTO}
     * @memberof UpdateOrganizationSettingsRequestDTO
     */
    'webhooks'?: UpdateOrganizationSettingsWebhooksDTO | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsRequestDTO
     */
    'website'?: string | null;
}

export const UpdateOrganizationSettingsRequestDTOCodingSystemEnum = {
    Blst: 'BLST',
    Loinc: 'LOINC'
} as const;

export type UpdateOrganizationSettingsRequestDTOCodingSystemEnum = typeof UpdateOrganizationSettingsRequestDTOCodingSystemEnum[keyof typeof UpdateOrganizationSettingsRequestDTOCodingSystemEnum];
export const UpdateOrganizationSettingsRequestDTOLocaleEnum = {
    DeDe: 'de_DE',
    EnGb: 'en_GB',
    EsEs: 'es_ES'
} as const;

export type UpdateOrganizationSettingsRequestDTOLocaleEnum = typeof UpdateOrganizationSettingsRequestDTOLocaleEnum[keyof typeof UpdateOrganizationSettingsRequestDTOLocaleEnum];

/**
 * 
 * @export
 * @interface UpdateOrganizationSettingsThemeDTO
 */
export interface UpdateOrganizationSettingsThemeDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsThemeDTO
     */
    'name'?: UpdateOrganizationSettingsThemeDTONameEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsThemeDTO
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsThemeDTO
     */
    'primaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsThemeDTO
     */
    'secondaryColor'?: string;
}

export const UpdateOrganizationSettingsThemeDTONameEnum = {
    Daslab: 'daslab',
    Mediquo: 'mediquo'
} as const;

export type UpdateOrganizationSettingsThemeDTONameEnum = typeof UpdateOrganizationSettingsThemeDTONameEnum[keyof typeof UpdateOrganizationSettingsThemeDTONameEnum];

/**
 * 
 * @export
 * @interface UpdateOrganizationSettingsWebhooksDTO
 */
export interface UpdateOrganizationSettingsWebhooksDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationSettingsWebhooksDTO
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsWebhooksDTO
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationSettingsWebhooksDTO
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProductRequestDTO
 */
export interface UpdateProductRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequestDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequestDTO
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequestDTO
     */
    'organizationId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductRequestDTO
     */
    'loincs'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequestDTO
     */
    'procedurePackId'?: string;
    /**
     * 
     * @type {UpdateProductSettingsRequestDTO}
     * @memberof UpdateProductRequestDTO
     */
    'settings'?: UpdateProductSettingsRequestDTO;
    /**
     * 
     * @type {Array<CreateProductOrganizationRoleRequestDTO>}
     * @memberof UpdateProductRequestDTO
     */
    'organizationRoles'?: Array<CreateProductOrganizationRoleRequestDTO>;
}
/**
 * 
 * @export
 * @interface UpdateProductSettingsRequestDTO
 */
export interface UpdateProductSettingsRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof UpdateProductSettingsRequestDTO
     */
    'ageMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductSettingsRequestDTO
     */
    'ageMax'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductSettingsRequestDTO
     */
    'reorderUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateSpecimenRequestDTO
 */
export interface UpdateSpecimenRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecimenRequestDTO
     */
    'receivedTime'?: string;
    /**
     * 
     * @type {SpecimenConditionCodingDTO}
     * @memberof UpdateSpecimenRequestDTO
     */
    'condition'?: SpecimenConditionCodingDTO;
}
/**
 * 
 * @export
 * @interface UploadAttachmentRequestDTO
 */
export interface UploadAttachmentRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof UploadAttachmentRequestDTO
     */
    'diagnosticPrescriptionId': string;
    /**
     * 
     * @type {string}
     * @memberof UploadAttachmentRequestDTO
     */
    'data': string;
}
/**
 * 
 * @export
 * @interface VerifyEmailRequestDTO
 */
export interface VerifyEmailRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailRequestDTO
     */
    'email': string;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Anonymized data by patient email
         * @summary Anonymize Patient
         * @param {AnonymizePatientRequestDTO} anonymizePatientRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        anonymize: async (anonymizePatientRequestDTO: AnonymizePatientRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'anonymizePatientRequestDTO' is not null or undefined
            assertParamExists('anonymize', 'anonymizePatientRequestDTO', anonymizePatientRequestDTO)
            const localVarPath = `/v1/patients/anonymize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(anonymizePatientRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates an account.
         * @summary Create Account
         * @param {CreateAccountRequestDTO} createAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createAccountRequestDTO: CreateAccountRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountRequestDTO' is not null or undefined
            assertParamExists('create', 'createAccountRequestDTO', createAccountRequestDTO)
            const localVarPath = `/v1/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a practitioner account.
         * @summary Create Practitioner Account
         * @param {CreatePractitionerAccountRequestDTO} createPractitionerAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPractitionerV1: async (createPractitionerAccountRequestDTO: CreatePractitionerAccountRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPractitionerAccountRequestDTO' is not null or undefined
            assertParamExists('createPractitionerV1', 'createPractitionerAccountRequestDTO', createPractitionerAccountRequestDTO)
            const localVarPath = `/v1/accounts/practitioners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPractitionerAccountRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a patient account.
         * @summary Create Patient Account
         * @param {CreatePatientAccountRequestDTO} createPatientAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create_1: async (createPatientAccountRequestDTO: CreatePatientAccountRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPatientAccountRequestDTO' is not null or undefined
            assertParamExists('create_1', 'createPatientAccountRequestDTO', createPatientAccountRequestDTO)
            const localVarPath = `/v1/accounts/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPatientAccountRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an account.
         * @summary Delete Account
         * @param {string} id The ID of the Account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAccount', 'id', id)
            const localVarPath = `/v1/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves information about the account that is associated     with the JWT sent in the request. Meant to be used by the Frontend apps.
         * @summary Get Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrent: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/accounts/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the list of accounts from a specific Organization.
         * @summary List Accounts
         * @param {string} organizationId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts: async (organizationId: string, roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listAccounts', 'organizationId', organizationId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('listAccounts', 'roleId', roleId)
            const localVarPath = `/v1/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests a verification email for an account.     Meant to be used by the Frontend apps.
         * @summary Request verify email
         * @param {VerifyEmailRequestDTO} verifyEmailRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerifyEmail: async (verifyEmailRequestDTO: VerifyEmailRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmailRequestDTO' is not null or undefined
            assertParamExists('requestVerifyEmail', 'verifyEmailRequestDTO', verifyEmailRequestDTO)
            const localVarPath = `/v1/accounts/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests a password reset email for an account.     Meant to be used by the Frontend apps.
         * @summary Request password reset email
         * @param {RequestAccountPasswordResetRequestDTO} requestAccountPasswordResetRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (requestAccountPasswordResetRequestDTO: RequestAccountPasswordResetRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestAccountPasswordResetRequestDTO' is not null or undefined
            assertParamExists('resetPassword', 'requestAccountPasswordResetRequestDTO', requestAccountPasswordResetRequestDTO)
            const localVarPath = `/v1/accounts/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestAccountPasswordResetRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests a sign in email for an account.     Meant to be used by the Frontend apps.
         * @summary Request sign in email
         * @param {SignInPatientAccountRequestDTO} signInPatientAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn: async (signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInPatientAccountRequestDTO' is not null or undefined
            assertParamExists('signIn', 'signInPatientAccountRequestDTO', signInPatientAccountRequestDTO)
            const localVarPath = `/v1/accounts/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInPatientAccountRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests a sign in email for a patient account.     Meant to be used by the Frontend apps.
         * @summary Request sign in email for patient accounts
         * @param {SignInPatientAccountRequestDTO} signInPatientAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signPatientInV1: async (signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInPatientAccountRequestDTO' is not null or undefined
            assertParamExists('signPatientInV1', 'signInPatientAccountRequestDTO', signInPatientAccountRequestDTO)
            const localVarPath = `/v1/accounts/patients/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInPatientAccountRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the password for an account.     Meant to be used by the Frontend apps.
         * @summary Update Account password
         * @param {UpdateAccountPasswordRequestDTO} updateAccountPasswordRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (updateAccountPasswordRequestDTO: UpdateAccountPasswordRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountPasswordRequestDTO' is not null or undefined
            assertParamExists('updatePassword', 'updateAccountPasswordRequestDTO', updateAccountPasswordRequestDTO)
            const localVarPath = `/v1/accounts/update-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountPasswordRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * Anonymized data by patient email
         * @summary Anonymize Patient
         * @param {AnonymizePatientRequestDTO} anonymizePatientRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async anonymize(anonymizePatientRequestDTO: AnonymizePatientRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnonymizePatientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.anonymize(anonymizePatientRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.anonymize']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Creates an account.
         * @summary Create Account
         * @param {CreateAccountRequestDTO} createAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createAccountRequestDTO: CreateAccountRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAccountResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createAccountRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Creates a practitioner account.
         * @summary Create Practitioner Account
         * @param {CreatePractitionerAccountRequestDTO} createPractitionerAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPractitionerV1(createPractitionerAccountRequestDTO: CreatePractitionerAccountRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPractitionerV1(createPractitionerAccountRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.createPractitionerV1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Creates a patient account.
         * @summary Create Patient Account
         * @param {CreatePatientAccountRequestDTO} createPatientAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create_1(createPatientAccountRequestDTO: CreatePatientAccountRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAccountResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create_1(createPatientAccountRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.create_1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Deletes an account.
         * @summary Delete Account
         * @param {string} id The ID of the Account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccount(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.deleteAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves information about the account that is associated     with the JWT sent in the request. Meant to be used by the Frontend apps.
         * @summary Get Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrent(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountMeResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrent(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.getCurrent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves the list of accounts from a specific Organization.
         * @summary List Accounts
         * @param {string} organizationId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccounts(organizationId: string, roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccounts(organizationId, roleId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.listAccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Requests a verification email for an account.     Meant to be used by the Frontend apps.
         * @summary Request verify email
         * @param {VerifyEmailRequestDTO} verifyEmailRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestVerifyEmail(verifyEmailRequestDTO: VerifyEmailRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestVerifyEmail(verifyEmailRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.requestVerifyEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Requests a password reset email for an account.     Meant to be used by the Frontend apps.
         * @summary Request password reset email
         * @param {RequestAccountPasswordResetRequestDTO} requestAccountPasswordResetRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(requestAccountPasswordResetRequestDTO: RequestAccountPasswordResetRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(requestAccountPasswordResetRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.resetPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Requests a sign in email for an account.     Meant to be used by the Frontend apps.
         * @summary Request sign in email
         * @param {SignInPatientAccountRequestDTO} signInPatientAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signIn(signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signIn(signInPatientAccountRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.signIn']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Requests a sign in email for a patient account.     Meant to be used by the Frontend apps.
         * @summary Request sign in email for patient accounts
         * @param {SignInPatientAccountRequestDTO} signInPatientAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signPatientInV1(signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signPatientInV1(signInPatientAccountRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.signPatientInV1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates the password for an account.     Meant to be used by the Frontend apps.
         * @summary Update Account password
         * @param {UpdateAccountPasswordRequestDTO} updateAccountPasswordRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(updateAccountPasswordRequestDTO: UpdateAccountPasswordRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(updateAccountPasswordRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountsApi.updatePassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * Anonymized data by patient email
         * @summary Anonymize Patient
         * @param {AnonymizePatientRequestDTO} anonymizePatientRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        anonymize(anonymizePatientRequestDTO: AnonymizePatientRequestDTO, options?: any): AxiosPromise<AnonymizePatientResponseDTO> {
            return localVarFp.anonymize(anonymizePatientRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates an account.
         * @summary Create Account
         * @param {CreateAccountRequestDTO} createAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createAccountRequestDTO: CreateAccountRequestDTO, options?: any): AxiosPromise<CreateAccountResponseDTO> {
            return localVarFp.create(createAccountRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a practitioner account.
         * @summary Create Practitioner Account
         * @param {CreatePractitionerAccountRequestDTO} createPractitionerAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPractitionerV1(createPractitionerAccountRequestDTO: CreatePractitionerAccountRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createPractitionerV1(createPractitionerAccountRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a patient account.
         * @summary Create Patient Account
         * @param {CreatePatientAccountRequestDTO} createPatientAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create_1(createPatientAccountRequestDTO: CreatePatientAccountRequestDTO, options?: any): AxiosPromise<CreateAccountResponseDTO> {
            return localVarFp.create_1(createPatientAccountRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an account.
         * @summary Delete Account
         * @param {string} id The ID of the Account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves information about the account that is associated     with the JWT sent in the request. Meant to be used by the Frontend apps.
         * @summary Get Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrent(options?: any): AxiosPromise<AccountMeResponseDTO> {
            return localVarFp.getCurrent(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the list of accounts from a specific Organization.
         * @summary List Accounts
         * @param {string} organizationId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts(organizationId: string, roleId: string, options?: any): AxiosPromise<AccountResponseDTO> {
            return localVarFp.listAccounts(organizationId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests a verification email for an account.     Meant to be used by the Frontend apps.
         * @summary Request verify email
         * @param {VerifyEmailRequestDTO} verifyEmailRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerifyEmail(verifyEmailRequestDTO: VerifyEmailRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.requestVerifyEmail(verifyEmailRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests a password reset email for an account.     Meant to be used by the Frontend apps.
         * @summary Request password reset email
         * @param {RequestAccountPasswordResetRequestDTO} requestAccountPasswordResetRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(requestAccountPasswordResetRequestDTO: RequestAccountPasswordResetRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(requestAccountPasswordResetRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests a sign in email for an account.     Meant to be used by the Frontend apps.
         * @summary Request sign in email
         * @param {SignInPatientAccountRequestDTO} signInPatientAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn(signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.signIn(signInPatientAccountRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests a sign in email for a patient account.     Meant to be used by the Frontend apps.
         * @summary Request sign in email for patient accounts
         * @param {SignInPatientAccountRequestDTO} signInPatientAccountRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signPatientInV1(signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.signPatientInV1(signInPatientAccountRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the password for an account.     Meant to be used by the Frontend apps.
         * @summary Update Account password
         * @param {UpdateAccountPasswordRequestDTO} updateAccountPasswordRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(updateAccountPasswordRequestDTO: UpdateAccountPasswordRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updatePassword(updateAccountPasswordRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - interface
 * @export
 * @interface AccountsApi
 */
export interface AccountsApiInterface {
    /**
     * Anonymized data by patient email
     * @summary Anonymize Patient
     * @param {AnonymizePatientRequestDTO} anonymizePatientRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    anonymize(anonymizePatientRequestDTO: AnonymizePatientRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<AnonymizePatientResponseDTO>;

    /**
     * Creates an account.
     * @summary Create Account
     * @param {CreateAccountRequestDTO} createAccountRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    create(createAccountRequestDTO: CreateAccountRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<CreateAccountResponseDTO>;

    /**
     * Creates a practitioner account.
     * @summary Create Practitioner Account
     * @param {CreatePractitionerAccountRequestDTO} createPractitionerAccountRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    createPractitionerV1(createPractitionerAccountRequestDTO: CreatePractitionerAccountRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Creates a patient account.
     * @summary Create Patient Account
     * @param {CreatePatientAccountRequestDTO} createPatientAccountRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    create_1(createPatientAccountRequestDTO: CreatePatientAccountRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<CreateAccountResponseDTO>;

    /**
     * Deletes an account.
     * @summary Delete Account
     * @param {string} id The ID of the Account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    deleteAccount(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Retrieves information about the account that is associated     with the JWT sent in the request. Meant to be used by the Frontend apps.
     * @summary Get Account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    getCurrent(options?: RawAxiosRequestConfig): AxiosPromise<AccountMeResponseDTO>;

    /**
     * Retrieves the list of accounts from a specific Organization.
     * @summary List Accounts
     * @param {string} organizationId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    listAccounts(organizationId: string, roleId: string, options?: RawAxiosRequestConfig): AxiosPromise<AccountResponseDTO>;

    /**
     * Requests a verification email for an account.     Meant to be used by the Frontend apps.
     * @summary Request verify email
     * @param {VerifyEmailRequestDTO} verifyEmailRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    requestVerifyEmail(verifyEmailRequestDTO: VerifyEmailRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Requests a password reset email for an account.     Meant to be used by the Frontend apps.
     * @summary Request password reset email
     * @param {RequestAccountPasswordResetRequestDTO} requestAccountPasswordResetRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    resetPassword(requestAccountPasswordResetRequestDTO: RequestAccountPasswordResetRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Requests a sign in email for an account.     Meant to be used by the Frontend apps.
     * @summary Request sign in email
     * @param {SignInPatientAccountRequestDTO} signInPatientAccountRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    signIn(signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Requests a sign in email for a patient account.     Meant to be used by the Frontend apps.
     * @summary Request sign in email for patient accounts
     * @param {SignInPatientAccountRequestDTO} signInPatientAccountRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    signPatientInV1(signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates the password for an account.     Meant to be used by the Frontend apps.
     * @summary Update Account password
     * @param {UpdateAccountPasswordRequestDTO} updateAccountPasswordRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    updatePassword(updateAccountPasswordRequestDTO: UpdateAccountPasswordRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI implements AccountsApiInterface {
    /**
     * Anonymized data by patient email
     * @summary Anonymize Patient
     * @param {AnonymizePatientRequestDTO} anonymizePatientRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public anonymize(anonymizePatientRequestDTO: AnonymizePatientRequestDTO, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).anonymize(anonymizePatientRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates an account.
     * @summary Create Account
     * @param {CreateAccountRequestDTO} createAccountRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public create(createAccountRequestDTO: CreateAccountRequestDTO, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).create(createAccountRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a practitioner account.
     * @summary Create Practitioner Account
     * @param {CreatePractitionerAccountRequestDTO} createPractitionerAccountRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createPractitionerV1(createPractitionerAccountRequestDTO: CreatePractitionerAccountRequestDTO, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).createPractitionerV1(createPractitionerAccountRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a patient account.
     * @summary Create Patient Account
     * @param {CreatePatientAccountRequestDTO} createPatientAccountRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public create_1(createPatientAccountRequestDTO: CreatePatientAccountRequestDTO, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).create_1(createPatientAccountRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an account.
     * @summary Delete Account
     * @param {string} id The ID of the Account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteAccount(id: string, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).deleteAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves information about the account that is associated     with the JWT sent in the request. Meant to be used by the Frontend apps.
     * @summary Get Account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getCurrent(options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).getCurrent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the list of accounts from a specific Organization.
     * @summary List Accounts
     * @param {string} organizationId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listAccounts(organizationId: string, roleId: string, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).listAccounts(organizationId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests a verification email for an account.     Meant to be used by the Frontend apps.
     * @summary Request verify email
     * @param {VerifyEmailRequestDTO} verifyEmailRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public requestVerifyEmail(verifyEmailRequestDTO: VerifyEmailRequestDTO, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).requestVerifyEmail(verifyEmailRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests a password reset email for an account.     Meant to be used by the Frontend apps.
     * @summary Request password reset email
     * @param {RequestAccountPasswordResetRequestDTO} requestAccountPasswordResetRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public resetPassword(requestAccountPasswordResetRequestDTO: RequestAccountPasswordResetRequestDTO, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).resetPassword(requestAccountPasswordResetRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests a sign in email for an account.     Meant to be used by the Frontend apps.
     * @summary Request sign in email
     * @param {SignInPatientAccountRequestDTO} signInPatientAccountRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public signIn(signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).signIn(signInPatientAccountRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests a sign in email for a patient account.     Meant to be used by the Frontend apps.
     * @summary Request sign in email for patient accounts
     * @param {SignInPatientAccountRequestDTO} signInPatientAccountRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public signPatientInV1(signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).signPatientInV1(signInPatientAccountRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the password for an account.     Meant to be used by the Frontend apps.
     * @summary Update Account password
     * @param {UpdateAccountPasswordRequestDTO} updateAccountPasswordRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updatePassword(updateAccountPasswordRequestDTO: UpdateAccountPasswordRequestDTO, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updatePassword(updateAccountPasswordRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AttachmentsApi - axios parameter creator
 * @export
 */
export const AttachmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves an pre-signed URL linking to an attachment.
         * @summary Get Attachment
         * @param {string} id The ID of the Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/v1/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentsApi - functional programming interface
 * @export
 */
export const AttachmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves an pre-signed URL linking to an attachment.
         * @summary Get Attachment
         * @param {string} id The ID of the Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAttachmentResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AttachmentsApi.getById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AttachmentsApi - factory interface
 * @export
 */
export const AttachmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentsApiFp(configuration)
    return {
        /**
         * Retrieves an pre-signed URL linking to an attachment.
         * @summary Get Attachment
         * @param {string} id The ID of the Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any): AxiosPromise<GetAttachmentResponseDTO> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttachmentsApi - interface
 * @export
 * @interface AttachmentsApi
 */
export interface AttachmentsApiInterface {
    /**
     * Retrieves an pre-signed URL linking to an attachment.
     * @summary Get Attachment
     * @param {string} id The ID of the Attachment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApiInterface
     */
    getById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GetAttachmentResponseDTO>;

}

/**
 * AttachmentsApi - object-oriented interface
 * @export
 * @class AttachmentsApi
 * @extends {BaseAPI}
 */
export class AttachmentsApi extends BaseAPI implements AttachmentsApiInterface {
    /**
     * Retrieves an pre-signed URL linking to an attachment.
     * @summary Get Attachment
     * @param {string} id The ID of the Attachment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public getById(id: string, options?: RawAxiosRequestConfig) {
        return AttachmentsApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generates an OAuth token using client credentials grant
         * @summary Generate an OAuth token
         * @param {string} clientId The Client Id part of the credentials.
         * @param {string} clientSecret The Client Secret part of the credentials.
         * @param {string} audience The intended recipient of the token.
         * @param {string} grantType The type of OAuth grant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (clientId: string, clientSecret: string, audience: string, grantType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getToken', 'clientId', clientId)
            // verify required parameter 'clientSecret' is not null or undefined
            assertParamExists('getToken', 'clientSecret', clientSecret)
            // verify required parameter 'audience' is not null or undefined
            assertParamExists('getToken', 'audience', audience)
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('getToken', 'grantType', grantType)
            const localVarPath = `/v2/oauth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
            if (audience !== undefined) { 
                localVarFormParams.set('audience', audience as any);
            }
    
            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Generates an OAuth token using client credentials grant
         * @summary Generate an OAuth token
         * @param {string} clientId The Client Id part of the credentials.
         * @param {string} clientSecret The Client Secret part of the credentials.
         * @param {string} audience The intended recipient of the token.
         * @param {string} grantType The type of OAuth grant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(clientId: string, clientSecret: string, audience: string, grantType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetToken200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(clientId, clientSecret, audience, grantType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.getToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * Generates an OAuth token using client credentials grant
         * @summary Generate an OAuth token
         * @param {string} clientId The Client Id part of the credentials.
         * @param {string} clientSecret The Client Secret part of the credentials.
         * @param {string} audience The intended recipient of the token.
         * @param {string} grantType The type of OAuth grant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(clientId: string, clientSecret: string, audience: string, grantType: string, options?: any): AxiosPromise<GetToken200Response> {
            return localVarFp.getToken(clientId, clientSecret, audience, grantType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - interface
 * @export
 * @interface AuthenticationApi
 */
export interface AuthenticationApiInterface {
    /**
     * Generates an OAuth token using client credentials grant
     * @summary Generate an OAuth token
     * @param {string} clientId The Client Id part of the credentials.
     * @param {string} clientSecret The Client Secret part of the credentials.
     * @param {string} audience The intended recipient of the token.
     * @param {string} grantType The type of OAuth grant.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiInterface
     */
    getToken(clientId: string, clientSecret: string, audience: string, grantType: string, options?: RawAxiosRequestConfig): AxiosPromise<GetToken200Response>;

}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI implements AuthenticationApiInterface {
    /**
     * Generates an OAuth token using client credentials grant
     * @summary Generate an OAuth token
     * @param {string} clientId The Client Id part of the credentials.
     * @param {string} clientSecret The Client Secret part of the credentials.
     * @param {string} audience The intended recipient of the token.
     * @param {string} grantType The type of OAuth grant.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getToken(clientId: string, clientSecret: string, audience: string, grantType: string, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getToken(clientId, clientSecret, audience, grantType, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DiagnosticCategoriesApi - axios parameter creator
 * @export
 */
export const DiagnosticCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Used in doctors app, is this FHIR?
         * @summary Get the categories for diagnostics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/diagnostic-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiagnosticCategoriesApi - functional programming interface
 * @export
 */
export const DiagnosticCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiagnosticCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Used in doctors app, is this FHIR?
         * @summary Get the categories for diagnostics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiagnosticCategoryViewDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticCategoriesApi.list']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DiagnosticCategoriesApi - factory interface
 * @export
 */
export const DiagnosticCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiagnosticCategoriesApiFp(configuration)
    return {
        /**
         * Used in doctors app, is this FHIR?
         * @summary Get the categories for diagnostics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): AxiosPromise<Array<DiagnosticCategoryViewDTO>> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiagnosticCategoriesApi - interface
 * @export
 * @interface DiagnosticCategoriesApi
 */
export interface DiagnosticCategoriesApiInterface {
    /**
     * Used in doctors app, is this FHIR?
     * @summary Get the categories for diagnostics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticCategoriesApiInterface
     */
    list(options?: RawAxiosRequestConfig): AxiosPromise<Array<DiagnosticCategoryViewDTO>>;

}

/**
 * DiagnosticCategoriesApi - object-oriented interface
 * @export
 * @class DiagnosticCategoriesApi
 * @extends {BaseAPI}
 */
export class DiagnosticCategoriesApi extends BaseAPI implements DiagnosticCategoriesApiInterface {
    /**
     * Used in doctors app, is this FHIR?
     * @summary Get the categories for diagnostics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticCategoriesApi
     */
    public list(options?: RawAxiosRequestConfig) {
        return DiagnosticCategoriesApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DiagnosticPrescriptionsApi - axios parameter creator
 * @export
 */
export const DiagnosticPrescriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Invite a patient to collect his DP, used in doctors frontend app
         * @summary Collect Diagnostic Prescription
         * @param {string} id 
         * @param {CollectDiagnosticPrescriptionRequestDTO} collectDiagnosticPrescriptionRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectById: async (id: string, collectDiagnosticPrescriptionRequestDTO: CollectDiagnosticPrescriptionRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('collectById', 'id', id)
            // verify required parameter 'collectDiagnosticPrescriptionRequestDTO' is not null or undefined
            assertParamExists('collectById', 'collectDiagnosticPrescriptionRequestDTO', collectDiagnosticPrescriptionRequestDTO)
            const localVarPath = `/v1/diagnostic-prescriptions/{id}/collect`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(collectDiagnosticPrescriptionRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *    Create Diagnostic Prescription is created by a doctor in the doctor FE app.
         * @summary Create Diagnostic Prescription
         * @param {CreateDiagnosticPrescriptionRequestDTO} createDiagnosticPrescriptionRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createDiagnosticPrescriptionRequestDTO: CreateDiagnosticPrescriptionRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDiagnosticPrescriptionRequestDTO' is not null or undefined
            assertParamExists('create', 'createDiagnosticPrescriptionRequestDTO', createDiagnosticPrescriptionRequestDTO)
            const localVarPath = `/v1/diagnostic-prescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDiagnosticPrescriptionRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Diagnostic Prescription Result Attachment Pdf url, used in FE apps
         * @summary Get Diagnostic Prescription Result Attachment Pdf url
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAttachmentById', 'id', id)
            const localVarPath = `/v1/diagnostic-prescriptions/{id}/attachment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Diagnostic Prescription
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/v1/diagnostic-prescriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite Patient to collect Diagnostic Prescription
         * @param {InvitePatientToCollectDiagnosticPrescriptionDTO} invitePatientToCollectDiagnosticPrescriptionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invite: async (invitePatientToCollectDiagnosticPrescriptionDTO: InvitePatientToCollectDiagnosticPrescriptionDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitePatientToCollectDiagnosticPrescriptionDTO' is not null or undefined
            assertParamExists('invite', 'invitePatientToCollectDiagnosticPrescriptionDTO', invitePatientToCollectDiagnosticPrescriptionDTO)
            const localVarPath = `/v1/diagnostic-prescriptions/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitePatientToCollectDiagnosticPrescriptionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For the doctor, to list his requests?
         * @summary List Diagnostic Prescriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/diagnostic-prescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For a doctor to update a presciption is he has made a mistake or thinks of something else
         * @summary Update Diagnostic Prescription
         * @param {string} id 
         * @param {UpdateDiagnosticPrescriptionRequestDTO} updateDiagnosticPrescriptionRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById: async (id: string, updateDiagnosticPrescriptionRequestDTO: UpdateDiagnosticPrescriptionRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateById', 'id', id)
            // verify required parameter 'updateDiagnosticPrescriptionRequestDTO' is not null or undefined
            assertParamExists('updateById', 'updateDiagnosticPrescriptionRequestDTO', updateDiagnosticPrescriptionRequestDTO)
            const localVarPath = `/v1/diagnostic-prescriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDiagnosticPrescriptionRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to upload a result pdf by the patient via the frontend app
         * @summary Upload Diagnostic Result Pdf to Diagnostic Prescription
         * @param {UploadAttachmentRequestDTO} uploadAttachmentRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAttachment: async (uploadAttachmentRequestDTO: UploadAttachmentRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadAttachmentRequestDTO' is not null or undefined
            assertParamExists('uploadAttachment', 'uploadAttachmentRequestDTO', uploadAttachmentRequestDTO)
            const localVarPath = `/v1/diagnostic-prescriptions/upload-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadAttachmentRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiagnosticPrescriptionsApi - functional programming interface
 * @export
 */
export const DiagnosticPrescriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiagnosticPrescriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Invite a patient to collect his DP, used in doctors frontend app
         * @summary Collect Diagnostic Prescription
         * @param {string} id 
         * @param {CollectDiagnosticPrescriptionRequestDTO} collectDiagnosticPrescriptionRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectById(id: string, collectDiagnosticPrescriptionRequestDTO: CollectDiagnosticPrescriptionRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectById(id, collectDiagnosticPrescriptionRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticPrescriptionsApi.collectById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *    Create Diagnostic Prescription is created by a doctor in the doctor FE app.
         * @summary Create Diagnostic Prescription
         * @param {CreateDiagnosticPrescriptionRequestDTO} createDiagnosticPrescriptionRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createDiagnosticPrescriptionRequestDTO: CreateDiagnosticPrescriptionRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiagnosticPrescriptionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createDiagnosticPrescriptionRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticPrescriptionsApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get Diagnostic Prescription Result Attachment Pdf url, used in FE apps
         * @summary Get Diagnostic Prescription Result Attachment Pdf url
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttachmentById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAttachmentUrlByDiagnosticPrescriptionIdResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachmentById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticPrescriptionsApi.getAttachmentById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get a Diagnostic Prescription
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiagnosticPrescriptionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticPrescriptionsApi.getById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Invite Patient to collect Diagnostic Prescription
         * @param {InvitePatientToCollectDiagnosticPrescriptionDTO} invitePatientToCollectDiagnosticPrescriptionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invite(invitePatientToCollectDiagnosticPrescriptionDTO: InvitePatientToCollectDiagnosticPrescriptionDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invite(invitePatientToCollectDiagnosticPrescriptionDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticPrescriptionsApi.invite']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * For the doctor, to list his requests?
         * @summary List Diagnostic Prescriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiagnosticPrescriptionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticPrescriptionsApi.list']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * For a doctor to update a presciption is he has made a mistake or thinks of something else
         * @summary Update Diagnostic Prescription
         * @param {string} id 
         * @param {UpdateDiagnosticPrescriptionRequestDTO} updateDiagnosticPrescriptionRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateById(id: string, updateDiagnosticPrescriptionRequestDTO: UpdateDiagnosticPrescriptionRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiagnosticPrescriptionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateById(id, updateDiagnosticPrescriptionRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticPrescriptionsApi.updateById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Used to upload a result pdf by the patient via the frontend app
         * @summary Upload Diagnostic Result Pdf to Diagnostic Prescription
         * @param {UploadAttachmentRequestDTO} uploadAttachmentRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAttachment(uploadAttachmentRequestDTO: UploadAttachmentRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAttachment(uploadAttachmentRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticPrescriptionsApi.uploadAttachment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DiagnosticPrescriptionsApi - factory interface
 * @export
 */
export const DiagnosticPrescriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiagnosticPrescriptionsApiFp(configuration)
    return {
        /**
         * Invite a patient to collect his DP, used in doctors frontend app
         * @summary Collect Diagnostic Prescription
         * @param {string} id 
         * @param {CollectDiagnosticPrescriptionRequestDTO} collectDiagnosticPrescriptionRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectById(id: string, collectDiagnosticPrescriptionRequestDTO: CollectDiagnosticPrescriptionRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.collectById(id, collectDiagnosticPrescriptionRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *    Create Diagnostic Prescription is created by a doctor in the doctor FE app.
         * @summary Create Diagnostic Prescription
         * @param {CreateDiagnosticPrescriptionRequestDTO} createDiagnosticPrescriptionRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createDiagnosticPrescriptionRequestDTO: CreateDiagnosticPrescriptionRequestDTO, options?: any): AxiosPromise<DiagnosticPrescriptionDTO> {
            return localVarFp.create(createDiagnosticPrescriptionRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Diagnostic Prescription Result Attachment Pdf url, used in FE apps
         * @summary Get Diagnostic Prescription Result Attachment Pdf url
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentById(id: string, options?: any): AxiosPromise<GetAttachmentUrlByDiagnosticPrescriptionIdResponseDTO> {
            return localVarFp.getAttachmentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Diagnostic Prescription
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any): AxiosPromise<DiagnosticPrescriptionDTO> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite Patient to collect Diagnostic Prescription
         * @param {InvitePatientToCollectDiagnosticPrescriptionDTO} invitePatientToCollectDiagnosticPrescriptionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invite(invitePatientToCollectDiagnosticPrescriptionDTO: InvitePatientToCollectDiagnosticPrescriptionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.invite(invitePatientToCollectDiagnosticPrescriptionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * For the doctor, to list his requests?
         * @summary List Diagnostic Prescriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): AxiosPromise<Array<DiagnosticPrescriptionDTO>> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
        /**
         * For a doctor to update a presciption is he has made a mistake or thinks of something else
         * @summary Update Diagnostic Prescription
         * @param {string} id 
         * @param {UpdateDiagnosticPrescriptionRequestDTO} updateDiagnosticPrescriptionRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById(id: string, updateDiagnosticPrescriptionRequestDTO: UpdateDiagnosticPrescriptionRequestDTO, options?: any): AxiosPromise<DiagnosticPrescriptionDTO> {
            return localVarFp.updateById(id, updateDiagnosticPrescriptionRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to upload a result pdf by the patient via the frontend app
         * @summary Upload Diagnostic Result Pdf to Diagnostic Prescription
         * @param {UploadAttachmentRequestDTO} uploadAttachmentRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAttachment(uploadAttachmentRequestDTO: UploadAttachmentRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.uploadAttachment(uploadAttachmentRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiagnosticPrescriptionsApi - interface
 * @export
 * @interface DiagnosticPrescriptionsApi
 */
export interface DiagnosticPrescriptionsApiInterface {
    /**
     * Invite a patient to collect his DP, used in doctors frontend app
     * @summary Collect Diagnostic Prescription
     * @param {string} id 
     * @param {CollectDiagnosticPrescriptionRequestDTO} collectDiagnosticPrescriptionRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApiInterface
     */
    collectById(id: string, collectDiagnosticPrescriptionRequestDTO: CollectDiagnosticPrescriptionRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     *    Create Diagnostic Prescription is created by a doctor in the doctor FE app.
     * @summary Create Diagnostic Prescription
     * @param {CreateDiagnosticPrescriptionRequestDTO} createDiagnosticPrescriptionRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApiInterface
     */
    create(createDiagnosticPrescriptionRequestDTO: CreateDiagnosticPrescriptionRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<DiagnosticPrescriptionDTO>;

    /**
     * Get Diagnostic Prescription Result Attachment Pdf url, used in FE apps
     * @summary Get Diagnostic Prescription Result Attachment Pdf url
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApiInterface
     */
    getAttachmentById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GetAttachmentUrlByDiagnosticPrescriptionIdResponseDTO>;

    /**
     * 
     * @summary Get a Diagnostic Prescription
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApiInterface
     */
    getById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DiagnosticPrescriptionDTO>;

    /**
     * 
     * @summary Invite Patient to collect Diagnostic Prescription
     * @param {InvitePatientToCollectDiagnosticPrescriptionDTO} invitePatientToCollectDiagnosticPrescriptionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApiInterface
     */
    invite(invitePatientToCollectDiagnosticPrescriptionDTO: InvitePatientToCollectDiagnosticPrescriptionDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * For the doctor, to list his requests?
     * @summary List Diagnostic Prescriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApiInterface
     */
    list(options?: RawAxiosRequestConfig): AxiosPromise<Array<DiagnosticPrescriptionDTO>>;

    /**
     * For a doctor to update a presciption is he has made a mistake or thinks of something else
     * @summary Update Diagnostic Prescription
     * @param {string} id 
     * @param {UpdateDiagnosticPrescriptionRequestDTO} updateDiagnosticPrescriptionRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApiInterface
     */
    updateById(id: string, updateDiagnosticPrescriptionRequestDTO: UpdateDiagnosticPrescriptionRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<DiagnosticPrescriptionDTO>;

    /**
     * Used to upload a result pdf by the patient via the frontend app
     * @summary Upload Diagnostic Result Pdf to Diagnostic Prescription
     * @param {UploadAttachmentRequestDTO} uploadAttachmentRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApiInterface
     */
    uploadAttachment(uploadAttachmentRequestDTO: UploadAttachmentRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DiagnosticPrescriptionsApi - object-oriented interface
 * @export
 * @class DiagnosticPrescriptionsApi
 * @extends {BaseAPI}
 */
export class DiagnosticPrescriptionsApi extends BaseAPI implements DiagnosticPrescriptionsApiInterface {
    /**
     * Invite a patient to collect his DP, used in doctors frontend app
     * @summary Collect Diagnostic Prescription
     * @param {string} id 
     * @param {CollectDiagnosticPrescriptionRequestDTO} collectDiagnosticPrescriptionRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApi
     */
    public collectById(id: string, collectDiagnosticPrescriptionRequestDTO: CollectDiagnosticPrescriptionRequestDTO, options?: RawAxiosRequestConfig) {
        return DiagnosticPrescriptionsApiFp(this.configuration).collectById(id, collectDiagnosticPrescriptionRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *    Create Diagnostic Prescription is created by a doctor in the doctor FE app.
     * @summary Create Diagnostic Prescription
     * @param {CreateDiagnosticPrescriptionRequestDTO} createDiagnosticPrescriptionRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApi
     */
    public create(createDiagnosticPrescriptionRequestDTO: CreateDiagnosticPrescriptionRequestDTO, options?: RawAxiosRequestConfig) {
        return DiagnosticPrescriptionsApiFp(this.configuration).create(createDiagnosticPrescriptionRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Diagnostic Prescription Result Attachment Pdf url, used in FE apps
     * @summary Get Diagnostic Prescription Result Attachment Pdf url
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApi
     */
    public getAttachmentById(id: string, options?: RawAxiosRequestConfig) {
        return DiagnosticPrescriptionsApiFp(this.configuration).getAttachmentById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Diagnostic Prescription
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApi
     */
    public getById(id: string, options?: RawAxiosRequestConfig) {
        return DiagnosticPrescriptionsApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite Patient to collect Diagnostic Prescription
     * @param {InvitePatientToCollectDiagnosticPrescriptionDTO} invitePatientToCollectDiagnosticPrescriptionDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApi
     */
    public invite(invitePatientToCollectDiagnosticPrescriptionDTO: InvitePatientToCollectDiagnosticPrescriptionDTO, options?: RawAxiosRequestConfig) {
        return DiagnosticPrescriptionsApiFp(this.configuration).invite(invitePatientToCollectDiagnosticPrescriptionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For the doctor, to list his requests?
     * @summary List Diagnostic Prescriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApi
     */
    public list(options?: RawAxiosRequestConfig) {
        return DiagnosticPrescriptionsApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For a doctor to update a presciption is he has made a mistake or thinks of something else
     * @summary Update Diagnostic Prescription
     * @param {string} id 
     * @param {UpdateDiagnosticPrescriptionRequestDTO} updateDiagnosticPrescriptionRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApi
     */
    public updateById(id: string, updateDiagnosticPrescriptionRequestDTO: UpdateDiagnosticPrescriptionRequestDTO, options?: RawAxiosRequestConfig) {
        return DiagnosticPrescriptionsApiFp(this.configuration).updateById(id, updateDiagnosticPrescriptionRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to upload a result pdf by the patient via the frontend app
     * @summary Upload Diagnostic Result Pdf to Diagnostic Prescription
     * @param {UploadAttachmentRequestDTO} uploadAttachmentRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticPrescriptionsApi
     */
    public uploadAttachment(uploadAttachmentRequestDTO: UploadAttachmentRequestDTO, options?: RawAxiosRequestConfig) {
        return DiagnosticPrescriptionsApiFp(this.configuration).uploadAttachment(uploadAttachmentRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DiagnosticRequestsApi - axios parameter creator
 * @export
 */
export const DiagnosticRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a diagnostic request by id.
         * @summary Get Diagnostic Request
         * @param {string} id The ID of the Diagnostic Request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/v1/diagnostic-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filters Diagnostic Requests by Specimen Container Id.
         * @summary List Diagnostic Requests
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (containerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('list', 'containerId', containerId)
            const localVarPath = `/v1/diagnostic-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  This endpoint allows you to update a Diagnostic Request.  A Diagnostic Request is a custom FHIR Bundle defined by DasLab in order to represent the package that contains the following FHIR resources:  - Patient - Service Request - Specimen  [Please see here](https://build.fhir.org/bundle.html) for more information regarding Bundles and their usage in the FHIR standard. 
         * @summary Update Diagnostic Request
         * @param {string} id The ID of the Diagnostic Request.
         * @param {UpdateDiagnosticRequestRequestDTO} updateDiagnosticRequestRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById: async (id: string, updateDiagnosticRequestRequestDTO: UpdateDiagnosticRequestRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateById', 'id', id)
            // verify required parameter 'updateDiagnosticRequestRequestDTO' is not null or undefined
            assertParamExists('updateById', 'updateDiagnosticRequestRequestDTO', updateDiagnosticRequestRequestDTO)
            const localVarPath = `/v1/diagnostic-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDiagnosticRequestRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiagnosticRequestsApi - functional programming interface
 * @export
 */
export const DiagnosticRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiagnosticRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a diagnostic request by id.
         * @summary Get Diagnostic Request
         * @param {string} id The ID of the Diagnostic Request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticRequestsApi.getById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Filters Diagnostic Requests by Specimen Container Id.
         * @summary List Diagnostic Requests
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(containerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(containerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticRequestsApi.list']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *  This endpoint allows you to update a Diagnostic Request.  A Diagnostic Request is a custom FHIR Bundle defined by DasLab in order to represent the package that contains the following FHIR resources:  - Patient - Service Request - Specimen  [Please see here](https://build.fhir.org/bundle.html) for more information regarding Bundles and their usage in the FHIR standard. 
         * @summary Update Diagnostic Request
         * @param {string} id The ID of the Diagnostic Request.
         * @param {UpdateDiagnosticRequestRequestDTO} updateDiagnosticRequestRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateById(id: string, updateDiagnosticRequestRequestDTO: UpdateDiagnosticRequestRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateById(id, updateDiagnosticRequestRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticRequestsApi.updateById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DiagnosticRequestsApi - factory interface
 * @export
 */
export const DiagnosticRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiagnosticRequestsApiFp(configuration)
    return {
        /**
         * Retrieves a diagnostic request by id.
         * @summary Get Diagnostic Request
         * @param {string} id The ID of the Diagnostic Request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Filters Diagnostic Requests by Specimen Container Id.
         * @summary List Diagnostic Requests
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(containerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.list(containerId, options).then((request) => request(axios, basePath));
        },
        /**
         *  This endpoint allows you to update a Diagnostic Request.  A Diagnostic Request is a custom FHIR Bundle defined by DasLab in order to represent the package that contains the following FHIR resources:  - Patient - Service Request - Specimen  [Please see here](https://build.fhir.org/bundle.html) for more information regarding Bundles and their usage in the FHIR standard. 
         * @summary Update Diagnostic Request
         * @param {string} id The ID of the Diagnostic Request.
         * @param {UpdateDiagnosticRequestRequestDTO} updateDiagnosticRequestRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById(id: string, updateDiagnosticRequestRequestDTO: UpdateDiagnosticRequestRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateById(id, updateDiagnosticRequestRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiagnosticRequestsApi - interface
 * @export
 * @interface DiagnosticRequestsApi
 */
export interface DiagnosticRequestsApiInterface {
    /**
     * Retrieves a diagnostic request by id.
     * @summary Get Diagnostic Request
     * @param {string} id The ID of the Diagnostic Request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticRequestsApiInterface
     */
    getById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Filters Diagnostic Requests by Specimen Container Id.
     * @summary List Diagnostic Requests
     * @param {string} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticRequestsApiInterface
     */
    list(containerId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     *  This endpoint allows you to update a Diagnostic Request.  A Diagnostic Request is a custom FHIR Bundle defined by DasLab in order to represent the package that contains the following FHIR resources:  - Patient - Service Request - Specimen  [Please see here](https://build.fhir.org/bundle.html) for more information regarding Bundles and their usage in the FHIR standard. 
     * @summary Update Diagnostic Request
     * @param {string} id The ID of the Diagnostic Request.
     * @param {UpdateDiagnosticRequestRequestDTO} updateDiagnosticRequestRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticRequestsApiInterface
     */
    updateById(id: string, updateDiagnosticRequestRequestDTO: UpdateDiagnosticRequestRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DiagnosticRequestsApi - object-oriented interface
 * @export
 * @class DiagnosticRequestsApi
 * @extends {BaseAPI}
 */
export class DiagnosticRequestsApi extends BaseAPI implements DiagnosticRequestsApiInterface {
    /**
     * Retrieves a diagnostic request by id.
     * @summary Get Diagnostic Request
     * @param {string} id The ID of the Diagnostic Request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticRequestsApi
     */
    public getById(id: string, options?: RawAxiosRequestConfig) {
        return DiagnosticRequestsApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filters Diagnostic Requests by Specimen Container Id.
     * @summary List Diagnostic Requests
     * @param {string} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticRequestsApi
     */
    public list(containerId: string, options?: RawAxiosRequestConfig) {
        return DiagnosticRequestsApiFp(this.configuration).list(containerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  This endpoint allows you to update a Diagnostic Request.  A Diagnostic Request is a custom FHIR Bundle defined by DasLab in order to represent the package that contains the following FHIR resources:  - Patient - Service Request - Specimen  [Please see here](https://build.fhir.org/bundle.html) for more information regarding Bundles and their usage in the FHIR standard. 
     * @summary Update Diagnostic Request
     * @param {string} id The ID of the Diagnostic Request.
     * @param {UpdateDiagnosticRequestRequestDTO} updateDiagnosticRequestRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticRequestsApi
     */
    public updateById(id: string, updateDiagnosticRequestRequestDTO: UpdateDiagnosticRequestRequestDTO, options?: RawAxiosRequestConfig) {
        return DiagnosticRequestsApiFp(this.configuration).updateById(id, updateDiagnosticRequestRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DiagnosticResultsApi - axios parameter creator
 * @export
 */
export const DiagnosticResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a Diagnostic Result.
         * @summary Create Diagnostic Result
         * @param {CreateDiagnosticResultRequestDTO} createDiagnosticResultRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createDiagnosticResultRequestDTO: CreateDiagnosticResultRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDiagnosticResultRequestDTO' is not null or undefined
            assertParamExists('create', 'createDiagnosticResultRequestDTO', createDiagnosticResultRequestDTO)
            const localVarPath = `/v2/diagnostic-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDiagnosticResultRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a diagnostic result.
         * @summary Get Diagnostic Result
         * @param {string} id The ID of the Diagnostic Result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/v2/diagnostic-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches Diagnostic Result View
         * @summary Get Diagnostic Result View by Specimen Container ID
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailsByContainerId: async (containerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerId' is not null or undefined
            assertParamExists('getDetailsByContainerId', 'containerId', containerId)
            const localVarPath = `/v1/views/diagnostic-result-by-container-id/{containerId}`
                .replace(`{${"containerId"}}`, encodeURIComponent(String(containerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filters Diagnostic Results by Diagnostic Request Id.
         * @summary List Diagnostic Results
         * @param {string} diagnosticRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (diagnosticRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'diagnosticRequestId' is not null or undefined
            assertParamExists('list', 'diagnosticRequestId', diagnosticRequestId)
            const localVarPath = `/v2/diagnostic-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (diagnosticRequestId !== undefined) {
                localVarQueryParameter['diagnosticRequestId'] = diagnosticRequestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a Diagnostic Result.
         * @summary Update Diagnostic Result
         * @param {string} id The ID of the Diagnostic Result.
         * @param {UpdateDiagnosticResultRequestDTO} updateDiagnosticResultRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById: async (id: string, updateDiagnosticResultRequestDTO: UpdateDiagnosticResultRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateById', 'id', id)
            // verify required parameter 'updateDiagnosticResultRequestDTO' is not null or undefined
            assertParamExists('updateById', 'updateDiagnosticResultRequestDTO', updateDiagnosticResultRequestDTO)
            const localVarPath = `/v2/diagnostic-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDiagnosticResultRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiagnosticResultsApi - functional programming interface
 * @export
 */
export const DiagnosticResultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiagnosticResultsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a Diagnostic Result.
         * @summary Create Diagnostic Result
         * @param {CreateDiagnosticResultRequestDTO} createDiagnosticResultRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createDiagnosticResultRequestDTO: CreateDiagnosticResultRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createDiagnosticResultRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticResultsApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetches a diagnostic result.
         * @summary Get Diagnostic Result
         * @param {string} id The ID of the Diagnostic Result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticResultsApi.getById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetches Diagnostic Result View
         * @summary Get Diagnostic Result View by Specimen Container ID
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailsByContainerId(containerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDiagnosticResultViewResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailsByContainerId(containerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticResultsApi.getDetailsByContainerId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Filters Diagnostic Results by Diagnostic Request Id.
         * @summary List Diagnostic Results
         * @param {string} diagnosticRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(diagnosticRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(diagnosticRequestId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticResultsApi.list']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates a Diagnostic Result.
         * @summary Update Diagnostic Result
         * @param {string} id The ID of the Diagnostic Result.
         * @param {UpdateDiagnosticResultRequestDTO} updateDiagnosticResultRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateById(id: string, updateDiagnosticResultRequestDTO: UpdateDiagnosticResultRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateById(id, updateDiagnosticResultRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DiagnosticResultsApi.updateById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DiagnosticResultsApi - factory interface
 * @export
 */
export const DiagnosticResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiagnosticResultsApiFp(configuration)
    return {
        /**
         * Creates a Diagnostic Result.
         * @summary Create Diagnostic Result
         * @param {CreateDiagnosticResultRequestDTO} createDiagnosticResultRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createDiagnosticResultRequestDTO: CreateDiagnosticResultRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.create(createDiagnosticResultRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a diagnostic result.
         * @summary Get Diagnostic Result
         * @param {string} id The ID of the Diagnostic Result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches Diagnostic Result View
         * @summary Get Diagnostic Result View by Specimen Container ID
         * @param {string} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailsByContainerId(containerId: string, options?: any): AxiosPromise<GetDiagnosticResultViewResponseDTO> {
            return localVarFp.getDetailsByContainerId(containerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Filters Diagnostic Results by Diagnostic Request Id.
         * @summary List Diagnostic Results
         * @param {string} diagnosticRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(diagnosticRequestId: string, options?: any): AxiosPromise<void> {
            return localVarFp.list(diagnosticRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a Diagnostic Result.
         * @summary Update Diagnostic Result
         * @param {string} id The ID of the Diagnostic Result.
         * @param {UpdateDiagnosticResultRequestDTO} updateDiagnosticResultRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById(id: string, updateDiagnosticResultRequestDTO: UpdateDiagnosticResultRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateById(id, updateDiagnosticResultRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiagnosticResultsApi - interface
 * @export
 * @interface DiagnosticResultsApi
 */
export interface DiagnosticResultsApiInterface {
    /**
     * Creates a Diagnostic Result.
     * @summary Create Diagnostic Result
     * @param {CreateDiagnosticResultRequestDTO} createDiagnosticResultRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticResultsApiInterface
     */
    create(createDiagnosticResultRequestDTO: CreateDiagnosticResultRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Fetches a diagnostic result.
     * @summary Get Diagnostic Result
     * @param {string} id The ID of the Diagnostic Result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticResultsApiInterface
     */
    getById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Fetches Diagnostic Result View
     * @summary Get Diagnostic Result View by Specimen Container ID
     * @param {string} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticResultsApiInterface
     */
    getDetailsByContainerId(containerId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDiagnosticResultViewResponseDTO>;

    /**
     * Filters Diagnostic Results by Diagnostic Request Id.
     * @summary List Diagnostic Results
     * @param {string} diagnosticRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticResultsApiInterface
     */
    list(diagnosticRequestId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates a Diagnostic Result.
     * @summary Update Diagnostic Result
     * @param {string} id The ID of the Diagnostic Result.
     * @param {UpdateDiagnosticResultRequestDTO} updateDiagnosticResultRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticResultsApiInterface
     */
    updateById(id: string, updateDiagnosticResultRequestDTO: UpdateDiagnosticResultRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DiagnosticResultsApi - object-oriented interface
 * @export
 * @class DiagnosticResultsApi
 * @extends {BaseAPI}
 */
export class DiagnosticResultsApi extends BaseAPI implements DiagnosticResultsApiInterface {
    /**
     * Creates a Diagnostic Result.
     * @summary Create Diagnostic Result
     * @param {CreateDiagnosticResultRequestDTO} createDiagnosticResultRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticResultsApi
     */
    public create(createDiagnosticResultRequestDTO: CreateDiagnosticResultRequestDTO, options?: RawAxiosRequestConfig) {
        return DiagnosticResultsApiFp(this.configuration).create(createDiagnosticResultRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a diagnostic result.
     * @summary Get Diagnostic Result
     * @param {string} id The ID of the Diagnostic Result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticResultsApi
     */
    public getById(id: string, options?: RawAxiosRequestConfig) {
        return DiagnosticResultsApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches Diagnostic Result View
     * @summary Get Diagnostic Result View by Specimen Container ID
     * @param {string} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticResultsApi
     */
    public getDetailsByContainerId(containerId: string, options?: RawAxiosRequestConfig) {
        return DiagnosticResultsApiFp(this.configuration).getDetailsByContainerId(containerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filters Diagnostic Results by Diagnostic Request Id.
     * @summary List Diagnostic Results
     * @param {string} diagnosticRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticResultsApi
     */
    public list(diagnosticRequestId: string, options?: RawAxiosRequestConfig) {
        return DiagnosticResultsApiFp(this.configuration).list(diagnosticRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a Diagnostic Result.
     * @summary Update Diagnostic Result
     * @param {string} id The ID of the Diagnostic Result.
     * @param {UpdateDiagnosticResultRequestDTO} updateDiagnosticResultRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticResultsApi
     */
    public updateById(id: string, updateDiagnosticResultRequestDTO: UpdateDiagnosticResultRequestDTO, options?: RawAxiosRequestConfig) {
        return DiagnosticResultsApiFp(this.configuration).updateById(id, updateDiagnosticResultRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a laboratory invoice.
         * @summary Create Laboratory Invoice
         * @param {CreateLaboratoryInvoicesRequestDTO} createLaboratoryInvoicesRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createLaboratoryInvoicesRequestDTO: CreateLaboratoryInvoicesRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLaboratoryInvoicesRequestDTO' is not null or undefined
            assertParamExists('create', 'createLaboratoryInvoicesRequestDTO', createLaboratoryInvoicesRequestDTO)
            const localVarPath = `/v2/laboratory-invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLaboratoryInvoicesRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a DTA Invoice for consultations per organization.
         * @summary Create DTA Invoice
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultationsInvoice: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createConsultationsInvoice', 'organizationId', organizationId)
            const localVarPath = `/v1/dta-invoices/consultations/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a laboratory invoice.
         * @summary Get Laboratory Invoice
         * @param {string} id The ID of the Laboratory Invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/v2/laboratory-invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a laboratory invoice.
         * @summary Update Laboratory Invoice
         * @param {string} id The ID of the Laboratory Invoice.
         * @param {UpdateLaboratoryInvoicesRequestDTO} updateLaboratoryInvoicesRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById: async (id: string, updateLaboratoryInvoicesRequestDTO: UpdateLaboratoryInvoicesRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateById', 'id', id)
            // verify required parameter 'updateLaboratoryInvoicesRequestDTO' is not null or undefined
            assertParamExists('updateById', 'updateLaboratoryInvoicesRequestDTO', updateLaboratoryInvoicesRequestDTO)
            const localVarPath = `/v2/laboratory-invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLaboratoryInvoicesRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a laboratory invoice.
         * @summary Create Laboratory Invoice
         * @param {CreateLaboratoryInvoicesRequestDTO} createLaboratoryInvoicesRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createLaboratoryInvoicesRequestDTO: CreateLaboratoryInvoicesRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLaboratoryInvoicesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createLaboratoryInvoicesRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoicesApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Creates a DTA Invoice for consultations per organization.
         * @summary Create DTA Invoice
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConsultationsInvoice(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DTAInvoiceResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConsultationsInvoice(organizationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoicesApi.createConsultationsInvoice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetches a laboratory invoice.
         * @summary Get Laboratory Invoice
         * @param {string} id The ID of the Laboratory Invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLaboratoryInvoicesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoicesApi.getById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates a laboratory invoice.
         * @summary Update Laboratory Invoice
         * @param {string} id The ID of the Laboratory Invoice.
         * @param {UpdateLaboratoryInvoicesRequestDTO} updateLaboratoryInvoicesRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateById(id: string, updateLaboratoryInvoicesRequestDTO: UpdateLaboratoryInvoicesRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLaboratoryInvoicesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateById(id, updateLaboratoryInvoicesRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoicesApi.updateById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesApiFp(configuration)
    return {
        /**
         * Creates a laboratory invoice.
         * @summary Create Laboratory Invoice
         * @param {CreateLaboratoryInvoicesRequestDTO} createLaboratoryInvoicesRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createLaboratoryInvoicesRequestDTO: CreateLaboratoryInvoicesRequestDTO, options?: any): AxiosPromise<GetLaboratoryInvoicesResponseDTO> {
            return localVarFp.create(createLaboratoryInvoicesRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a DTA Invoice for consultations per organization.
         * @summary Create DTA Invoice
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultationsInvoice(organizationId: string, options?: any): AxiosPromise<DTAInvoiceResponseDTO> {
            return localVarFp.createConsultationsInvoice(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a laboratory invoice.
         * @summary Get Laboratory Invoice
         * @param {string} id The ID of the Laboratory Invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any): AxiosPromise<GetLaboratoryInvoicesResponseDTO> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a laboratory invoice.
         * @summary Update Laboratory Invoice
         * @param {string} id The ID of the Laboratory Invoice.
         * @param {UpdateLaboratoryInvoicesRequestDTO} updateLaboratoryInvoicesRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById(id: string, updateLaboratoryInvoicesRequestDTO: UpdateLaboratoryInvoicesRequestDTO, options?: any): AxiosPromise<GetLaboratoryInvoicesResponseDTO> {
            return localVarFp.updateById(id, updateLaboratoryInvoicesRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - interface
 * @export
 * @interface InvoicesApi
 */
export interface InvoicesApiInterface {
    /**
     * Creates a laboratory invoice.
     * @summary Create Laboratory Invoice
     * @param {CreateLaboratoryInvoicesRequestDTO} createLaboratoryInvoicesRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApiInterface
     */
    create(createLaboratoryInvoicesRequestDTO: CreateLaboratoryInvoicesRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<GetLaboratoryInvoicesResponseDTO>;

    /**
     * Creates a DTA Invoice for consultations per organization.
     * @summary Create DTA Invoice
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApiInterface
     */
    createConsultationsInvoice(organizationId: string, options?: RawAxiosRequestConfig): AxiosPromise<DTAInvoiceResponseDTO>;

    /**
     * Fetches a laboratory invoice.
     * @summary Get Laboratory Invoice
     * @param {string} id The ID of the Laboratory Invoice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApiInterface
     */
    getById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GetLaboratoryInvoicesResponseDTO>;

    /**
     * Updates a laboratory invoice.
     * @summary Update Laboratory Invoice
     * @param {string} id The ID of the Laboratory Invoice.
     * @param {UpdateLaboratoryInvoicesRequestDTO} updateLaboratoryInvoicesRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApiInterface
     */
    updateById(id: string, updateLaboratoryInvoicesRequestDTO: UpdateLaboratoryInvoicesRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<GetLaboratoryInvoicesResponseDTO>;

}

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI implements InvoicesApiInterface {
    /**
     * Creates a laboratory invoice.
     * @summary Create Laboratory Invoice
     * @param {CreateLaboratoryInvoicesRequestDTO} createLaboratoryInvoicesRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public create(createLaboratoryInvoicesRequestDTO: CreateLaboratoryInvoicesRequestDTO, options?: RawAxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).create(createLaboratoryInvoicesRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a DTA Invoice for consultations per organization.
     * @summary Create DTA Invoice
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public createConsultationsInvoice(organizationId: string, options?: RawAxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).createConsultationsInvoice(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a laboratory invoice.
     * @summary Get Laboratory Invoice
     * @param {string} id The ID of the Laboratory Invoice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getById(id: string, options?: RawAxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a laboratory invoice.
     * @summary Update Laboratory Invoice
     * @param {string} id The ID of the Laboratory Invoice.
     * @param {UpdateLaboratoryInvoicesRequestDTO} updateLaboratoryInvoicesRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public updateById(id: string, updateLaboratoryInvoicesRequestDTO: UpdateLaboratoryInvoicesRequestDTO, options?: RawAxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).updateById(id, updateLaboratoryInvoicesRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MonitoringApi - axios parameter creator
 * @export
 */
export const MonitoringApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  Used to ping the server make sure it is online and the correct version is deployed. Does not require authentication
         * @summary Check server health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonitoringApi - functional programming interface
 * @export
 */
export const MonitoringApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonitoringApiAxiosParamCreator(configuration)
    return {
        /**
         *  Used to ping the server make sure it is online and the correct version is deployed. Does not require authentication
         * @summary Check server health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthStatus(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MonitoringApi.getHealthStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MonitoringApi - factory interface
 * @export
 */
export const MonitoringApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonitoringApiFp(configuration)
    return {
        /**
         *  Used to ping the server make sure it is online and the correct version is deployed. Does not require authentication
         * @summary Check server health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthStatus(options?: any): AxiosPromise<void> {
            return localVarFp.getHealthStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonitoringApi - interface
 * @export
 * @interface MonitoringApi
 */
export interface MonitoringApiInterface {
    /**
     *  Used to ping the server make sure it is online and the correct version is deployed. Does not require authentication
     * @summary Check server health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitoringApiInterface
     */
    getHealthStatus(options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * MonitoringApi - object-oriented interface
 * @export
 * @class MonitoringApi
 * @extends {BaseAPI}
 */
export class MonitoringApi extends BaseAPI implements MonitoringApiInterface {
    /**
     *  Used to ping the server make sure it is online and the correct version is deployed. Does not require authentication
     * @summary Check server health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitoringApi
     */
    public getHealthStatus(options?: RawAxiosRequestConfig) {
        return MonitoringApiFp(this.configuration).getHealthStatus(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates an analysis file
         * @summary Create Analysis file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeCsv: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/schubert-analysis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancels an order.
         * @summary Cancel Order
         * @param {string} id The ID of the Order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelById', 'id', id)
            const localVarPath = `/v1/orders/{id}/cancellation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Connects an order to an account.
         * @summary Connect Order
         * @param {ConnectOrderRequestDTO} connectOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connect: async (connectOrderRequestDTO: ConnectOrderRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectOrderRequestDTO' is not null or undefined
            assertParamExists('connect', 'connectOrderRequestDTO', connectOrderRequestDTO)
            const localVarPath = `/v3/orders/connect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectOrderRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates an order.
         * @summary Create Order
         * @param {CreateOrderRequestDTO} createOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createOrderRequestDTO: CreateOrderRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderRequestDTO' is not null or undefined
            assertParamExists('create', 'createOrderRequestDTO', createOrderRequestDTO)
            const localVarPath = `/v3/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves an order by id.
         * @summary Get Order
         * @param {string} id The ID of the Order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/v3/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the list of orders from a specific Organization.
         * @summary List Orders
         * @param {Array<string>} sort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (sort: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('list', 'sort', sort)
            const localVarPath = `/v3/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recreate an order by its placer ID. This endpoint is only available for admin users.
         * @summary Recreate order
         * @param {RecreateOrderRequestDTO} recreateOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreate: async (recreateOrderRequestDTO: RecreateOrderRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recreateOrderRequestDTO' is not null or undefined
            assertParamExists('recreate', 'recreateOrderRequestDTO', recreateOrderRequestDTO)
            const localVarPath = `/v1/orders/recreate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recreateOrderRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an order.
         * @summary Update Order
         * @param {string} id The ID of the Order.
         * @param {UpdateOrderRequestDTO} updateOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById: async (id: string, updateOrderRequestDTO: UpdateOrderRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateById', 'id', id)
            // verify required parameter 'updateOrderRequestDTO' is not null or undefined
            assertParamExists('updateById', 'updateOrderRequestDTO', updateOrderRequestDTO)
            const localVarPath = `/v3/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates an analysis file
         * @summary Create Analysis file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyzeCsv(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyzeCsv(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrdersApi.analyzeCsv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Cancels an order.
         * @summary Cancel Order
         * @param {string} id The ID of the Order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrdersApi.cancelById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Connects an order to an account.
         * @summary Connect Order
         * @param {ConnectOrderRequestDTO} connectOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connect(connectOrderRequestDTO: ConnectOrderRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connect(connectOrderRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrdersApi.connect']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Creates an order.
         * @summary Create Order
         * @param {CreateOrderRequestDTO} createOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createOrderRequestDTO: CreateOrderRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createOrderRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrdersApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves an order by id.
         * @summary Get Order
         * @param {string} id The ID of the Order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrdersApi.getById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves the list of orders from a specific Organization.
         * @summary List Orders
         * @param {Array<string>} sort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(sort: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrdersApi.list']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Recreate an order by its placer ID. This endpoint is only available for admin users.
         * @summary Recreate order
         * @param {RecreateOrderRequestDTO} recreateOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recreate(recreateOrderRequestDTO: RecreateOrderRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recreate(recreateOrderRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrdersApi.recreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates an order.
         * @summary Update Order
         * @param {string} id The ID of the Order.
         * @param {UpdateOrderRequestDTO} updateOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateById(id: string, updateOrderRequestDTO: UpdateOrderRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateById(id, updateOrderRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrdersApi.updateById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * Creates an analysis file
         * @summary Create Analysis file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeCsv(options?: any): AxiosPromise<void> {
            return localVarFp.analyzeCsv(options).then((request) => request(axios, basePath));
        },
        /**
         * Cancels an order.
         * @summary Cancel Order
         * @param {string} id The ID of the Order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelById(id: string, options?: any): AxiosPromise<GetOrderResponseDTO> {
            return localVarFp.cancelById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Connects an order to an account.
         * @summary Connect Order
         * @param {ConnectOrderRequestDTO} connectOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connect(connectOrderRequestDTO: ConnectOrderRequestDTO, options?: any): AxiosPromise<GetOrderResponseDTO> {
            return localVarFp.connect(connectOrderRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates an order.
         * @summary Create Order
         * @param {CreateOrderRequestDTO} createOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createOrderRequestDTO: CreateOrderRequestDTO, options?: any): AxiosPromise<GetOrderResponseDTO> {
            return localVarFp.create(createOrderRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves an order by id.
         * @summary Get Order
         * @param {string} id The ID of the Order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: string, options?: any): AxiosPromise<GetOrderResponseDTO> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the list of orders from a specific Organization.
         * @summary List Orders
         * @param {Array<string>} sort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(sort: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.list(sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Recreate an order by its placer ID. This endpoint is only available for admin users.
         * @summary Recreate order
         * @param {RecreateOrderRequestDTO} recreateOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreate(recreateOrderRequestDTO: RecreateOrderRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.recreate(recreateOrderRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an order.
         * @summary Update Order
         * @param {string} id The ID of the Order.
         * @param {UpdateOrderRequestDTO} updateOrderRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById(id: string, updateOrderRequestDTO: UpdateOrderRequestDTO, options?: any): AxiosPromise<GetOrderResponseDTO> {
            return localVarFp.updateById(id, updateOrderRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - interface
 * @export
 * @interface OrdersApi
 */
export interface OrdersApiInterface {
    /**
     * Creates an analysis file
     * @summary Create Analysis file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    analyzeCsv(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Cancels an order.
     * @summary Cancel Order
     * @param {string} id The ID of the Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    cancelById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GetOrderResponseDTO>;

    /**
     * Connects an order to an account.
     * @summary Connect Order
     * @param {ConnectOrderRequestDTO} connectOrderRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    connect(connectOrderRequestDTO: ConnectOrderRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<GetOrderResponseDTO>;

    /**
     * Creates an order.
     * @summary Create Order
     * @param {CreateOrderRequestDTO} createOrderRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    create(createOrderRequestDTO: CreateOrderRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<GetOrderResponseDTO>;

    /**
     * Retrieves an order by id.
     * @summary Get Order
     * @param {string} id The ID of the Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    getById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GetOrderResponseDTO>;

    /**
     * Retrieves the list of orders from a specific Organization.
     * @summary List Orders
     * @param {Array<string>} sort 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    list(sort: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Recreate an order by its placer ID. This endpoint is only available for admin users.
     * @summary Recreate order
     * @param {RecreateOrderRequestDTO} recreateOrderRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    recreate(recreateOrderRequestDTO: RecreateOrderRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates an order.
     * @summary Update Order
     * @param {string} id The ID of the Order.
     * @param {UpdateOrderRequestDTO} updateOrderRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    updateById(id: string, updateOrderRequestDTO: UpdateOrderRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<GetOrderResponseDTO>;

}

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI implements OrdersApiInterface {
    /**
     * Creates an analysis file
     * @summary Create Analysis file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public analyzeCsv(options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).analyzeCsv(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancels an order.
     * @summary Cancel Order
     * @param {string} id The ID of the Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public cancelById(id: string, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).cancelById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Connects an order to an account.
     * @summary Connect Order
     * @param {ConnectOrderRequestDTO} connectOrderRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public connect(connectOrderRequestDTO: ConnectOrderRequestDTO, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).connect(connectOrderRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates an order.
     * @summary Create Order
     * @param {CreateOrderRequestDTO} createOrderRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public create(createOrderRequestDTO: CreateOrderRequestDTO, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).create(createOrderRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves an order by id.
     * @summary Get Order
     * @param {string} id The ID of the Order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getById(id: string, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the list of orders from a specific Organization.
     * @summary List Orders
     * @param {Array<string>} sort 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public list(sort: Array<string>, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).list(sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recreate an order by its placer ID. This endpoint is only available for admin users.
     * @summary Recreate order
     * @param {RecreateOrderRequestDTO} recreateOrderRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public recreate(recreateOrderRequestDTO: RecreateOrderRequestDTO, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).recreate(recreateOrderRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an order.
     * @summary Update Order
     * @param {string} id The ID of the Order.
     * @param {UpdateOrderRequestDTO} updateOrderRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public updateById(id: string, updateOrderRequestDTO: UpdateOrderRequestDTO, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).updateById(id, updateOrderRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates an organization.
         * @summary Create Organization
         * @param {CreateOrganizationRequestDTO} createOrganizationRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization: async (createOrganizationRequestDTO: CreateOrganizationRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrganizationRequestDTO' is not null or undefined
            assertParamExists('createOrganization', 'createOrganizationRequestDTO', createOrganizationRequestDTO)
            const localVarPath = `/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves an organization by id.
         * @summary Get Organization
         * @param {string} id The ID of the Organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganization', 'id', id)
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the public settings of an organization, such as locale, theme.
         * @summary Get Organization Public Settings
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSettings: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getPublicSettings', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/public-settings`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of organizations.
         * @summary List Organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an organization.
         * @summary Update Organization
         * @param {string} id The ID of the Organization.
         * @param {UpdateOrganizationRequestDTO} updateOrganizationRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (id: string, updateOrganizationRequestDTO: UpdateOrganizationRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrganization', 'id', id)
            // verify required parameter 'updateOrganizationRequestDTO' is not null or undefined
            assertParamExists('updateOrganization', 'updateOrganizationRequestDTO', updateOrganizationRequestDTO)
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates an organization.
         * @summary Create Organization
         * @param {CreateOrganizationRequestDTO} createOrganizationRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganization(createOrganizationRequestDTO: CreateOrganizationRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganization(createOrganizationRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.createOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves an organization by id.
         * @summary Get Organization
         * @param {string} id The ID of the Organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganization(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganization(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.getOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves the public settings of an organization, such as locale, theme.
         * @summary Get Organization Public Settings
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicSettings(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationPublicSettingsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicSettings(organizationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.getPublicSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves a list of organizations.
         * @summary List Organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganizations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetOrganizationResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganizations(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.listOrganizations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates an organization.
         * @summary Update Organization
         * @param {string} id The ID of the Organization.
         * @param {UpdateOrganizationRequestDTO} updateOrganizationRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(id: string, updateOrganizationRequestDTO: UpdateOrganizationRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(id, updateOrganizationRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.updateOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * Creates an organization.
         * @summary Create Organization
         * @param {CreateOrganizationRequestDTO} createOrganizationRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(createOrganizationRequestDTO: CreateOrganizationRequestDTO, options?: any): AxiosPromise<GetOrganizationResponseDTO> {
            return localVarFp.createOrganization(createOrganizationRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves an organization by id.
         * @summary Get Organization
         * @param {string} id The ID of the Organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(id: string, options?: any): AxiosPromise<GetOrganizationResponseDTO> {
            return localVarFp.getOrganization(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the public settings of an organization, such as locale, theme.
         * @summary Get Organization Public Settings
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSettings(organizationId: string, options?: any): AxiosPromise<OrganizationPublicSettingsResponseDTO> {
            return localVarFp.getPublicSettings(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of organizations.
         * @summary List Organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations(options?: any): AxiosPromise<Array<GetOrganizationResponseDTO>> {
            return localVarFp.listOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an organization.
         * @summary Update Organization
         * @param {string} id The ID of the Organization.
         * @param {UpdateOrganizationRequestDTO} updateOrganizationRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(id: string, updateOrganizationRequestDTO: UpdateOrganizationRequestDTO, options?: any): AxiosPromise<GetOrganizationResponseDTO> {
            return localVarFp.updateOrganization(id, updateOrganizationRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - interface
 * @export
 * @interface OrganizationsApi
 */
export interface OrganizationsApiInterface {
    /**
     * Creates an organization.
     * @summary Create Organization
     * @param {CreateOrganizationRequestDTO} createOrganizationRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    createOrganization(createOrganizationRequestDTO: CreateOrganizationRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<GetOrganizationResponseDTO>;

    /**
     * Retrieves an organization by id.
     * @summary Get Organization
     * @param {string} id The ID of the Organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    getOrganization(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GetOrganizationResponseDTO>;

    /**
     * Retrieves the public settings of an organization, such as locale, theme.
     * @summary Get Organization Public Settings
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    getPublicSettings(organizationId: string, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationPublicSettingsResponseDTO>;

    /**
     * Retrieves a list of organizations.
     * @summary List Organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    listOrganizations(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetOrganizationResponseDTO>>;

    /**
     * Updates an organization.
     * @summary Update Organization
     * @param {string} id The ID of the Organization.
     * @param {UpdateOrganizationRequestDTO} updateOrganizationRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApiInterface
     */
    updateOrganization(id: string, updateOrganizationRequestDTO: UpdateOrganizationRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<GetOrganizationResponseDTO>;

}

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI implements OrganizationsApiInterface {
    /**
     * Creates an organization.
     * @summary Create Organization
     * @param {CreateOrganizationRequestDTO} createOrganizationRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public createOrganization(createOrganizationRequestDTO: CreateOrganizationRequestDTO, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).createOrganization(createOrganizationRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves an organization by id.
     * @summary Get Organization
     * @param {string} id The ID of the Organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganization(id: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganization(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the public settings of an organization, such as locale, theme.
     * @summary Get Organization Public Settings
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getPublicSettings(organizationId: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getPublicSettings(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of organizations.
     * @summary List Organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public listOrganizations(options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).listOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an organization.
     * @summary Update Organization
     * @param {string} id The ID of the Organization.
     * @param {UpdateOrganizationRequestDTO} updateOrganizationRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateOrganization(id: string, updateOrganizationRequestDTO: UpdateOrganizationRequestDTO, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateOrganization(id, updateOrganizationRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParametersApi - axios parameter creator
 * @export
 */
export const ParametersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all Parameters
         * @summary Get all Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParameters: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParametersApi - functional programming interface
 * @export
 */
export const ParametersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParametersApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all Parameters
         * @summary Get all Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllParameters(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParameterDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllParameters(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ParametersApi.getAllParameters']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ParametersApi - factory interface
 * @export
 */
export const ParametersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParametersApiFp(configuration)
    return {
        /**
         * Retrieves all Parameters
         * @summary Get all Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParameters(options?: any): AxiosPromise<Array<ParameterDTO>> {
            return localVarFp.getAllParameters(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParametersApi - interface
 * @export
 * @interface ParametersApi
 */
export interface ParametersApiInterface {
    /**
     * Retrieves all Parameters
     * @summary Get all Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApiInterface
     */
    getAllParameters(options?: RawAxiosRequestConfig): AxiosPromise<Array<ParameterDTO>>;

}

/**
 * ParametersApi - object-oriented interface
 * @export
 * @class ParametersApi
 * @extends {BaseAPI}
 */
export class ParametersApi extends BaseAPI implements ParametersApiInterface {
    /**
     * Retrieves all Parameters
     * @summary Get all Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public getAllParameters(options?: RawAxiosRequestConfig) {
        return ParametersApiFp(this.configuration).getAllParameters(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a Product, with settings and roles
         * @summary Create Product
         * @param {CreateProductRequestDTO} createProductRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (createProductRequestDTO: CreateProductRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductRequestDTO' is not null or undefined
            assertParamExists('createProduct', 'createProductRequestDTO', createProductRequestDTO)
            const localVarPath = `/v1/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a product by id.
         * @summary Get Product
         * @param {string} id The ID of the Product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductById', 'id', id)
            const localVarPath = `/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the public settings of a product, such as age requirements.
         * @summary Get Product Public Settings
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getView: async (productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getView', 'productId', productId)
            const localVarPath = `/v1/products/{productId}/public-settings`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves list of Products
         * @summary Get Product List
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts: async (sku: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sku' is not null or undefined
            assertParamExists('listProducts', 'sku', sku)
            const localVarPath = `/v1/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a Product, with settings and roles
         * @summary Update Product
         * @param {string} id The ID of the Product.
         * @param {UpdateProductRequestDTO} updateProductRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (id: string, updateProductRequestDTO: UpdateProductRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProduct', 'id', id)
            // verify required parameter 'updateProductRequestDTO' is not null or undefined
            assertParamExists('updateProduct', 'updateProductRequestDTO', updateProductRequestDTO)
            const localVarPath = `/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a Product, with settings and roles
         * @summary Create Product
         * @param {CreateProductRequestDTO} createProductRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(createProductRequestDTO: CreateProductRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(createProductRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.createProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves a product by id.
         * @summary Get Product
         * @param {string} id The ID of the Product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.getProductById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves the public settings of a product, such as age requirements.
         * @summary Get Product Public Settings
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getView(productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPublicSettingsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getView(productId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.getView']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves list of Products
         * @summary Get Product List
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProducts(sku: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListProductResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProducts(sku, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.listProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates a Product, with settings and roles
         * @summary Update Product
         * @param {string} id The ID of the Product.
         * @param {UpdateProductRequestDTO} updateProductRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(id: string, updateProductRequestDTO: UpdateProductRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(id, updateProductRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.updateProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * Creates a Product, with settings and roles
         * @summary Create Product
         * @param {CreateProductRequestDTO} createProductRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(createProductRequestDTO: CreateProductRequestDTO, options?: any): AxiosPromise<GetProductResponseDTO> {
            return localVarFp.createProduct(createProductRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a product by id.
         * @summary Get Product
         * @param {string} id The ID of the Product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(id: string, options?: any): AxiosPromise<GetProductResponseDTO> {
            return localVarFp.getProductById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the public settings of a product, such as age requirements.
         * @summary Get Product Public Settings
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getView(productId: string, options?: any): AxiosPromise<ProductPublicSettingsResponseDTO> {
            return localVarFp.getView(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves list of Products
         * @summary Get Product List
         * @param {string} sku 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts(sku: string, options?: any): AxiosPromise<Array<ListProductResponseDTO>> {
            return localVarFp.listProducts(sku, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a Product, with settings and roles
         * @summary Update Product
         * @param {string} id The ID of the Product.
         * @param {UpdateProductRequestDTO} updateProductRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(id: string, updateProductRequestDTO: UpdateProductRequestDTO, options?: any): AxiosPromise<GetProductResponseDTO> {
            return localVarFp.updateProduct(id, updateProductRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - interface
 * @export
 * @interface ProductsApi
 */
export interface ProductsApiInterface {
    /**
     * Creates a Product, with settings and roles
     * @summary Create Product
     * @param {CreateProductRequestDTO} createProductRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    createProduct(createProductRequestDTO: CreateProductRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<GetProductResponseDTO>;

    /**
     * Retrieves a product by id.
     * @summary Get Product
     * @param {string} id The ID of the Product.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    getProductById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProductResponseDTO>;

    /**
     * Retrieves the public settings of a product, such as age requirements.
     * @summary Get Product Public Settings
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    getView(productId: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductPublicSettingsResponseDTO>;

    /**
     * Retrieves list of Products
     * @summary Get Product List
     * @param {string} sku 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    listProducts(sku: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ListProductResponseDTO>>;

    /**
     * Updates a Product, with settings and roles
     * @summary Update Product
     * @param {string} id The ID of the Product.
     * @param {UpdateProductRequestDTO} updateProductRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    updateProduct(id: string, updateProductRequestDTO: UpdateProductRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<GetProductResponseDTO>;

}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI implements ProductsApiInterface {
    /**
     * Creates a Product, with settings and roles
     * @summary Create Product
     * @param {CreateProductRequestDTO} createProductRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createProduct(createProductRequestDTO: CreateProductRequestDTO, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).createProduct(createProductRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a product by id.
     * @summary Get Product
     * @param {string} id The ID of the Product.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductById(id: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the public settings of a product, such as age requirements.
     * @summary Get Product Public Settings
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getView(productId: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getView(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves list of Products
     * @summary Get Product List
     * @param {string} sku 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public listProducts(sku: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).listProducts(sku, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a Product, with settings and roles
     * @summary Update Product
     * @param {string} id The ID of the Product.
     * @param {UpdateProductRequestDTO} updateProductRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateProduct(id: string, updateProductRequestDTO: UpdateProductRequestDTO, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateProduct(id, updateProductRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *    Reporting for a provider about the orders.
         * @summary Orders report
         * @param {Array<string>} sort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forOrders: async (sort: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('forOrders', 'sort', sort)
            const localVarPath = `/v1/reports/orders-by-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         *    Reporting for a provider about the orders.
         * @summary Orders report
         * @param {Array<string>} sort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forOrders(sort: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forOrders(sort, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.forOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         *    Reporting for a provider about the orders.
         * @summary Orders report
         * @param {Array<string>} sort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forOrders(sort: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.forOrders(sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - interface
 * @export
 * @interface ReportsApi
 */
export interface ReportsApiInterface {
    /**
     *    Reporting for a provider about the orders.
     * @summary Orders report
     * @param {Array<string>} sort 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    forOrders(sort: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI implements ReportsApiInterface {
    /**
     *    Reporting for a provider about the orders.
     * @summary Orders report
     * @param {Array<string>} sort 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public forOrders(sort: Array<string>, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).forOrders(sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SpecimensApi - axios parameter creator
 * @export
 */
export const SpecimensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates a specimen.
         * @summary Update Specimen
         * @param {string} id The ID of the Specimen.
         * @param {UpdateSpecimenRequestDTO} updateSpecimenRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById: async (id: string, updateSpecimenRequestDTO: UpdateSpecimenRequestDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateById', 'id', id)
            // verify required parameter 'updateSpecimenRequestDTO' is not null or undefined
            assertParamExists('updateById', 'updateSpecimenRequestDTO', updateSpecimenRequestDTO)
            const localVarPath = `/v1/specimens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSpecimenRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecimensApi - functional programming interface
 * @export
 */
export const SpecimensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpecimensApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates a specimen.
         * @summary Update Specimen
         * @param {string} id The ID of the Specimen.
         * @param {UpdateSpecimenRequestDTO} updateSpecimenRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateById(id: string, updateSpecimenRequestDTO: UpdateSpecimenRequestDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSpecimenResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateById(id, updateSpecimenRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SpecimensApi.updateById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SpecimensApi - factory interface
 * @export
 */
export const SpecimensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpecimensApiFp(configuration)
    return {
        /**
         * Updates a specimen.
         * @summary Update Specimen
         * @param {string} id The ID of the Specimen.
         * @param {UpdateSpecimenRequestDTO} updateSpecimenRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById(id: string, updateSpecimenRequestDTO: UpdateSpecimenRequestDTO, options?: any): AxiosPromise<GetSpecimenResponseDTO> {
            return localVarFp.updateById(id, updateSpecimenRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpecimensApi - interface
 * @export
 * @interface SpecimensApi
 */
export interface SpecimensApiInterface {
    /**
     * Updates a specimen.
     * @summary Update Specimen
     * @param {string} id The ID of the Specimen.
     * @param {UpdateSpecimenRequestDTO} updateSpecimenRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecimensApiInterface
     */
    updateById(id: string, updateSpecimenRequestDTO: UpdateSpecimenRequestDTO, options?: RawAxiosRequestConfig): AxiosPromise<GetSpecimenResponseDTO>;

}

/**
 * SpecimensApi - object-oriented interface
 * @export
 * @class SpecimensApi
 * @extends {BaseAPI}
 */
export class SpecimensApi extends BaseAPI implements SpecimensApiInterface {
    /**
     * Updates a specimen.
     * @summary Update Specimen
     * @param {string} id The ID of the Specimen.
     * @param {UpdateSpecimenRequestDTO} updateSpecimenRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecimensApi
     */
    public updateById(id: string, updateSpecimenRequestDTO: UpdateSpecimenRequestDTO, options?: RawAxiosRequestConfig) {
        return SpecimensApiFp(this.configuration).updateById(id, updateSpecimenRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



