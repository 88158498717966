import {BaseAPI} from '../apis';
import {
  CollectDiagnosticPrescriptionRequestDTO,
  DiagnosticPrescriptionDTO,
  DiagnosticPrescriptionsApi, GetAttachmentUrlByDiagnosticPrescriptionIdResponseDTO, UploadAttachmentRequestDTO,
} from "@infra/daslab-sdk";

export interface IDiagnosticPrescriptionsService
{
  readonly collectById: (id: string, collectDiagnosticPrescriptionRequestDTO: CollectDiagnosticPrescriptionRequestDTO) => void;
  readonly list: () => Promise<DiagnosticPrescriptionDTO[]>;
  readonly getById: (id: DiagnosticPrescriptionDTO['id']) => Promise<DiagnosticPrescriptionDTO>;
  readonly getAttachmentById: (id: string) => Promise<GetAttachmentUrlByDiagnosticPrescriptionIdResponseDTO>;
  readonly uploadAttachment: (uploadAttachmentRequestDTO: UploadAttachmentRequestDTO) => Promise<void>;
}

class DiagnosticPrescriptionsService extends BaseAPI<DiagnosticPrescriptionsApi> implements IDiagnosticPrescriptionsService
{
  constructor()
  {
    super(DiagnosticPrescriptionsApi);
  }

  getAttachmentById = async (id: string): Promise<GetAttachmentUrlByDiagnosticPrescriptionIdResponseDTO> =>
  {
    const {data} = await this.api.getAttachmentById(id);
    return data;
  }

  uploadAttachment = async (uploadAttachmentRequestDTO: UploadAttachmentRequestDTO): Promise<void> =>
  {
    await this.api.uploadAttachment(uploadAttachmentRequestDTO);
  }

  getById = async (id: DiagnosticPrescriptionDTO['id']): Promise<DiagnosticPrescriptionDTO> =>
  {
    const {data} = await this.api.getById(id);
    return data;
  }

  collectById = async (id: string, collectDiagnosticPrescriptionRequestDTO: CollectDiagnosticPrescriptionRequestDTO): Promise<void> =>
  {
    await this.api.collectById(id, collectDiagnosticPrescriptionRequestDTO);
  }

  list = async (): Promise<DiagnosticPrescriptionDTO[]> =>
  {
    const {data} = await this.api.list();
    return data;
  }
}

export const diagnosticPrescriptionsService = new DiagnosticPrescriptionsService();
