import auth0 from 'auth0-js'
import { providerConfig } from '../config'

export const client = new auth0.WebAuth({
  ...providerConfig,
  redirectUri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
  clientID: providerConfig.clientId,
  responseType: 'token',
  scope: 'openid profile',
})
