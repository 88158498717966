import {GetOrderResponseDTO, OrderStatusDTO} from "@infra/daslab-sdk";

export type orderEntityStatus = OrderStatusDTO & {needsFollowUp: string|boolean}
export type orderEntity = {
  order: GetOrderResponseDTO;
  fullName: string;
  fullAddress: string;
  status: orderEntityStatus;
  isFinalized?: boolean;
  combinedStatuses?: OrderStatusDTO[];
}
export enum Statuses {
  CREATED = 'created',
  DISPATCHED = 'dispatched',
  ACTIVATED = 'activated',
  SPECIMEN_RECEIVED_BY_LABORATORY = 'specimen_received_by_laboratory',
  DIAGNOSTIC_RESULTS_CREATED = 'diagnostic_results_created',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  AWAITING_DISPATCH = 'awaiting_dispatch',
}
