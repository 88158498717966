import {BaseAPI} from '../apis';
import {AttachmentsApi, GetAttachmentResponseDTO} from '@infra/daslab-sdk';

export interface IAttachmentsService
{
  readonly getById: (attachmentId: GetAttachmentResponseDTO['id']) => Promise<GetAttachmentResponseDTO>;
}

class AttachmentsService extends BaseAPI<AttachmentsApi> implements IAttachmentsService
{
  constructor()
  {
    super(AttachmentsApi);
  }

  getById = async (attachmentId: GetAttachmentResponseDTO['id']): Promise<GetAttachmentResponseDTO> =>
  {
    const {data} = await this.api.getById(attachmentId);
    return data;
  }
}

export const attachmentsService = new AttachmentsService();
