import { BehaviorSubject, distinctUntilKeyChanged, Observable, pluck, Subscription } from 'rxjs'
import { Action } from './StateStore.interface'

export class Store<T, S> {
  private _state: BehaviorSubject<T>
  private _reducer: (state: T, action: Action<S>) => T

  constructor(reducer: (state: T, action: Action<S>) => T, initialState: T) {
    this._state = new BehaviorSubject(initialState)
    this._reducer = reducer
  }

  select<K extends keyof T>(key: K): Observable<T[K]> {
    return this._state.pipe(distinctUntilKeyChanged(key), pluck(key))
  }

  get state(): T {
    return this._state.getValue()
  }

  subscribe(callback: (state: T) => void): Subscription {
    return this._state.subscribe(callback)
  }

  dispatch = (action: Action<S>): void => {
    const oldState = this._state.getValue()
    const newState = this._reducer(oldState, action)
    this._state.next(newState)
  }

  asyncDispatch = async <R>(type: S, runner: (state: T) => Promise<R>): Promise<void> => {
    const currentState = this._state.getValue()
    const payload = await runner(currentState)
    this.dispatch({ type, payload })
  }
}
