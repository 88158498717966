import { createContext } from 'react'
import { Action, Store } from '@infra/state-stores'
import { UserActionTypes, UserStoreState } from './user-store.interface'

const initialStoreState: UserState = {
  error: null,
  user: { firstName: '', lastName: '', notesForLab: '' },
  loading: false,
}

export type UserState = UserStoreState<{ firstName: string; lastName: string; notesForLab: string }>

const userStore = (storeReducer: (state: UserState, action: Action<UserActionTypes>) => any) =>
  new Store<UserState, UserActionTypes>(storeReducer, initialStoreState)

const UserStoreContext = createContext<Store<UserState, UserActionTypes> | null>(null)

const UserStoreProvider = ({ children, userStoreCreate }) => {
  return <UserStoreContext.Provider value={userStoreCreate}>{children}</UserStoreContext.Provider>
}

export { initialStoreState, userStore, UserStoreContext, UserStoreProvider }
export type { Action }
