import {
  TextField as BaseTextField,
  TextFieldProps as BaseTextFieldProps,
} from '@infra/ui-design-system'
import { FieldProps, getIn } from 'formik'

export interface TextFieldProps
  extends FieldProps,
    Omit<BaseTextFieldProps, 'name' | 'value' | 'error'> {}

export function fieldToTextField({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onChange,
  onBlur,
  helperText,
  ...props
}: TextFieldProps): BaseTextFieldProps {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name)
      },
    ...field,
    ...props,
  }
}

export function TextField({ children, ...props }: TextFieldProps) {
  return (
    <BaseTextField {...fieldToTextField(props)} {...props}>
      {children}
    </BaseTextField>
  )
}

TextField.displayName = 'TextField'
