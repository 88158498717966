/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  getAuth,
  sendSignInLinkToEmail,
  RecaptchaVerifier,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  UserCredential,
  AuthError,
  signOut,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { FirebaseError } from '@firebase/util'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY!,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN!,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID!,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
auth.tenantId = import.meta.env.VITE_FIREBASE_TENANT_ID!

// set locale to the device language
auth.useDeviceLanguage()

export {
  auth,
  getAuth,
  signOut,
  app,
  analytics,
  firebaseConfig,
  RecaptchaVerifier,
  sendSignInLinkToEmail,
  getMultiFactorResolver,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  PhoneMultiFactorGenerator,
  type UserCredential,
  type AuthError,
  type FirebaseError
}
