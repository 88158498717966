import {Box, Grid, Typography} from '@mui/material'
import { ChangeEvent, Fragment, useCallback } from 'react'
import { CheckboxItem } from './checkbox-item'
import {styled} from "@infra/ui-design-system";

interface CheckboxGroupProps {
  checkboxes: any[];
  onCheckboxGroupChange: (value: any) => void;
  search: string;
  shouldHighlight: boolean;
  groupByLetter?: boolean;
  highlightedColor: string;
}

const StyledTypographySubtitle = styled(Typography)(
  ({ theme: { palette } }) => `
    display: block;
    font-weight: 500;
    border-bottom: 1px solid ${palette.grey[300]};
    width: 100%;
`)

function getHighlightedText(
  text: string,
  search: string,
  highlightedColor: string
) {
  const searchValue: string = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const parts: Array<string> = text.split(new RegExp(`(${searchValue})`, 'gi'))
  return parts.map((part: string, index: number) => (
    <Fragment key={index}>
      {part.toLowerCase() === search.toLowerCase() ? (
        <b style={{ color: highlightedColor, fontWeight: 800, letterSpacing: '0.9' }}>{part}</b>
      ) : (
        part
      )}
    </Fragment>
  ))
}

export const CheckboxGroup = ({
  checkboxes,
  onCheckboxGroupChange,
  search,
  shouldHighlight,
  groupByLetter = false,
  highlightedColor,
}: CheckboxGroupProps) => {
  const handleChildCheckboxChange = useCallback(
    ({ target }: ChangeEvent<any>, checkbox: any) => {
      onCheckboxGroupChange({ ...checkbox, checked: target.checked })
    },
    [onCheckboxGroupChange]
  )

  const groupByFirstLetter = (parameters: Array<any>): {[i:string]: Array<any>} => parameters.reduce((acc, cur) => {
    const firstLetter = cur.label[0].match(/[A-Za-z]/) ? cur.label[0].toLowerCase() : '0';
    return { ...acc, [firstLetter]: [...(acc[firstLetter] || []), cur] };
  }, {})


  const renderCheckboxByLetter = useCallback(() => {
    const groupedCheckboxes = groupByFirstLetter(checkboxes);
    const letters = Object.keys(groupedCheckboxes);
    return letters.map(letter => <Box mb={2.5} sx={{width: '100%'}}>
      {letter !== '0' && <StyledTypographySubtitle pb={1}>{letter.toUpperCase()}</StyledTypographySubtitle>}
      <Grid container marginTop={1.5} marginLeft={.5}>{renderCheckboxes(groupedCheckboxes[letter])}</Grid>
    </Box>)
  }, [checkboxes, handleChildCheckboxChange, highlightedColor, search, shouldHighlight, groupByLetter])

  const renderCheckboxes = useCallback((checkboxCollection = checkboxes) => {
    return checkboxCollection.map((checkbox, idx) => {
      return <Grid item xs={12} sm={12} md={4} lg={4} mb={3} key={checkbox.value + idx}>
        <CheckboxItem
          code={checkbox.code}
          label={
            shouldHighlight && !!search
              ? getHighlightedText(checkbox.label, search, highlightedColor)
              : checkbox.label
          }
          value={checkbox.value}
          checkboxInformation={checkbox.information}
          checked={checkbox.checked}
          onChange={(checkStatus: ChangeEvent<any>) =>
            handleChildCheckboxChange(checkStatus, checkbox)
          }
        />
      </Grid>
    })
  }, [checkboxes, handleChildCheckboxChange, highlightedColor, search, shouldHighlight])

  if (!checkboxes) {
    return null
  }

  return <Fragment>{groupByLetter ? renderCheckboxByLetter() : renderCheckboxes()}</Fragment>
}
