import {
  Box,
  Button,
  Collapse,
  List, ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material'
import {styled} from '@infra/ui-design-system'
import {FC, Fragment, useEffect, useState} from 'react'
import { IParameter, IPanel, IParametersProps } from '../types'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {PanelAccordion} from "./panel-accordion";
import React from 'react';
import {ExpandLess, ExpandMore, StarBorder, Circle} from "@mui/icons-material";


const StyledDeleteIcon = styled(DeleteOutlineIcon)`
  width: 1rem;
`
const StyledDeleteButton = styled(Button)`
  position: absolute;
  right: -1rem;
  top: 50%;
  transform: translateY(-50%);
`

export const StaticParameters: FC<Pick<IParametersProps, 'parameters' | 'panels'>> = ({parameters, panels}) => {

  const [display, setDisplay] = useState<Array<IParameter|IPanel>>([]);

  useEffect(() => {
    const flatPanelProperties = panels?.map(panel => panel.parameters)?.flat(1)?.map(item => item.id) || [];
    setDisplay(parameters?.filter(parameter => !flatPanelProperties.includes(parameter.id)).concat(panels).sort((a: any, b: any) => a.label?.localeCompare(b.label)));
  }, [parameters?.length, panels?.length])

  return <List>
    {display && display.map(({label, name, parameters, id}: IParameter|IPanel) => <Fragment key={id}>
      <ListItem disablePadding>{label || name}</ListItem>
      {!!parameters?.length && parameters.map((parameter: IParameter) => <ListItem disablePadding><span style={{marginRight: '1rem'}}>•</span>{parameter.label}</ListItem>)}
    </Fragment>)}
  </List>
}

export const Parameters: FC<IParametersProps> = ({
  parameters = [],
  panels = [],
  isMobile,
  expanded,
  handleDeletePanel,
  handleDeleteParameter,
}) => {

  const [display, setDisplay] = useState<Array<IParameter|IPanel>>([]);

  useEffect(() => {
    const flatPanelProperties = panels?.map(panel => panel.parameters)?.flat(1)?.map(item => item.id) || [];
    setDisplay(parameters.filter(parameter => !flatPanelProperties.includes(parameter.id)).concat(panels).sort((a: any, b: any) => a.label?.localeCompare(b.label)));
  }, [parameters.length, panels?.length])

  return (
    <>
      {display && display.map((item: IParameter|IPanel) => (
        <Box key={item && item.id} textAlign="left" marginBottom={1}>
          {'parameters' in item ? <PanelAccordion handleDeletePanel={handleDeletePanel} panel={item} expanded={expanded}/> : <Box sx={{position: 'relative'}}>
            <Typography variant="body2">{item?.label || item.name}</Typography>
            {!!handleDeleteParameter && <StyledDeleteButton onClick={() => {handleDeleteParameter && handleDeleteParameter(item.id)}}><StyledDeleteIcon /></StyledDeleteButton>}
          </Box>}
        </Box>
      ))}
    </>
  )
}
