import {styled} from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {Box} from "@mui/material";

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`

export const StyledBox = styled(Box)(
  ({ theme: { spacing, palette } }) => `
  text-align: center;
  position: relative;
  padding: ${spacing(2)};
  color: white;
  background: ${palette.background.paper};
`);
