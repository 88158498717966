import {
  Box,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  TableCell as MuiTableCell,
  Typography,
  useScrollTrigger,
} from '@mui/material'
import { MouseEvent, cloneElement, ReactElement } from 'react'
import { visuallyHidden } from '@mui/utils'
import { styled } from '@infra/ui-design-system'
import { TableProps, EnhancedTableHeadProps } from './table.interface'
import { StyledRow, StyledSortIcon, StyledTable, StyledTableContainer } from './table.styled'
import { NoTableData } from './no-data-table'
import { TableSkeleton } from './skeleton'
import { TableCell } from './table-cell'

const StyledTableSortLabel = styled(TableSortLabel)`
  ${({ active }) => (!active ? `opacity: 0.7;` : `font-weight: 700;`)}
`

const StyledMuiTableCell = styled(MuiTableCell)`
  background: white;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    background: ${({ theme }) => theme.palette.grey[300]};
    width: 100%;
    left: 0;
  }
  &:first-of-type{
    &:after {
      width: calc(100% + ${({ theme }) => theme.spacing(2)});
      left: ${({ theme }) => theme.spacing(-2)};
    }
  }
  &:last-of-type{
    &:after {
      width: calc(100% + ${({ theme }) => theme.spacing(2)});
      right: ${({ theme }) => theme.spacing(2)};
    }
  }
`

interface ElevationScrollProps {
  children: ReactElement
  window?: () => Window
}

export function ElevationScroll(props: ElevationScrollProps) {
  const { children } = props

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { order, orderBy, onRequestSort, headCells, hover } = props
  const createSortHandler =
    (property: keyof Record<string, string>) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <>
      <ElevationScroll>
        <TableHead>
          <TableRow hover={hover}>
            {headCells.map(headCell => (
              <StyledMuiTableCell
                sx={{borderBottom: 'none'}}
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <StyledTableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={({ className }) => (
                    <StyledSortIcon
                      name={orderBy === headCell.id ? 'sortOrder' : 'passiveSort'}
                      className={className}
                    />
                  )}
                >
                  <Typography
                    variant="body2"
                    sx={{fontWeight: 600}}
                  >
                    {headCell.label}
                  </Typography>
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </StyledTableSortLabel>
              </StyledMuiTableCell>
            ))}
          </TableRow>
        </TableHead>
      </ElevationScroll>
    </>
  )
}

export function FlexibleTable<T>({
  title,
  rows = [],
  rowIdKey,
  onRowClick,
  columns,
  sort,
  onSortChange,
  error,
  fallbackError,
  loading = false,
  children,
  headerFontFamily,
  hover = false,
  order,
  orderBy,
  isMobile,
  shouldHighlight,
  searchValue,
  highlightedColor,
  handleRequestSort,
  selectedRow,
  ...styleProps
}: TableProps<T>) {
  return (
    <StyledTableContainer {...styleProps}>
      <StyledTable stickyHeader>
        <EnhancedTableHead
          headCells={columns}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />

        <TableBody>
          {loading ? <TableSkeleton columns={columns} /> : null}
          {!rows.length || (error && !loading) ? (
            <NoTableData message={error || fallbackError || 'No data found'} />
          ) : null}

          {rows.map((row, index) => (
            <StyledRow
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              key={`${row[rowIdKey]}--${index}`}
              hover={hover}
              selected={selectedRow === index}
              /** prevent onClick from being truthy in the styled function
               *  when onRowClick is undefined */
              onClick={onRowClick && (() => onRowClick(row, index))}
            >
              {columns.map(column => (
                <TableCell<T>
                  column={column}
                  row={row}
                  key={column.id}
                  loading={loading}
                  isMobile={isMobile}
                  shouldHighlight={shouldHighlight}
                  searchValue={searchValue}
                  highlightedColor={highlightedColor}
                />
              ))}
            </StyledRow>
          ))}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  )
}
