import {GetOrderResponseDTO} from "@infra/daslab-sdk";
import {orderEntity, Statuses} from './order.types';
import {needsFollowUp} from "./order.utils";

export class Order implements orderEntity
{
  order: GetOrderResponseDTO;

  constructor(order: GetOrderResponseDTO)
  {
    this.order = order;
  }

  private sortStatusesByDate = (statuses: GetOrderResponseDTO['statuses']): GetOrderResponseDTO['statuses'] =>
  {
    return statuses.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }

  get fullName(): orderEntity['fullName']
  {
    if (!this?.order?.customer?.firstName || !this?.order?.customer?.lastName) throw new Error('Missing customer firstName or lastName');
    return `${this?.order.customer.firstName} ${this?.order.customer.lastName}`;
  }

  get fullAddress(): orderEntity['fullAddress']
  {
    if(!this.order?.address?.length) return '';
    return `
        ${this.order.address[0]?.line} <br/>
        ${this.order.address[0]?.postalCode} ${this.order.address[0]?.city} ${this.order.address[0]?.country}
    `
  }

  get isFinalized(): boolean
  {
    if(!this.order.statuses.length || !this.order.products.length) return false;
    return this.order.statuses.some(status => status.value === Statuses.CANCELLED || status.value === Statuses.COMPLETED) ||
    this.order.products[0].statuses.some(status => status.value === Statuses.DIAGNOSTIC_RESULTS_CREATED)
  }

  get status(): orderEntity['status']
  {
    if(!this.order?.statuses || !this.order?.products?.length) return {value: '', createdAt: '', needsFollowUp: false};
    const sortedStatuses = this.sortStatusesByDate(this.order.statuses);
    return {
      ...sortedStatuses[sortedStatuses.length - 1],
      needsFollowUp: needsFollowUp(this.order.products[0].statuses)
    };
  }

  get combinedStatuses()
  {
    const orderCancelledStatus = this.order.statuses.find(({ value }) => value === Statuses.CANCELLED);
    const productStatuses = [...this.order.products[0].statuses];

    const createdStatusIndex = productStatuses.findIndex(status => status.value === Statuses.CREATED);
    if (createdStatusIndex !== -1)
    {
      if (productStatuses.length === 1) productStatuses[createdStatusIndex].value = Statuses.AWAITING_DISPATCH; // Rename created status if it's the only element
      else productStatuses.splice(createdStatusIndex, 1); // Remove the created status
    }

    return this.sortStatusesByDate([
      ...productStatuses,
      ...(orderCancelledStatus ? [orderCancelledStatus] : [])
    ]);
  }
}
