import { styled } from '@infra/ui-design-system'
import { Grid } from '@mui/material'
import { ReactNode } from 'react'

type LayoutWrapperProps = {
  children?: ReactNode
}

const StyedMainWithNoOverflow = styled.div`
  padding: 0;
`

export const LayoutWrapper = ({ children }: LayoutWrapperProps) => {
  return (
    <StyedMainWithNoOverflow data-test="layout-wrapper">
      <Grid item xs={12} sx={{ padding: 0 }}>
        <Grid container sx={{ padding: 0 }}>
          {children}
        </Grid>
      </Grid>
    </StyedMainWithNoOverflow>
  )
}
