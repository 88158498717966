import { styled } from '@infra/ui-design-system'
import InputMask from 'react-input-mask'

export const StyledNativeDateInput = styled(InputMask)(
  ({ theme: { spacing, typography, palette } }) => `
      padding: ${spacing(2)} ${spacing(3)};
      background-color: ${palette.grey[100]};
      border-radius: ${spacing(1)};
      font-size: 14px;
      border: 1px solid ${palette.grey[300]};
      font-family: ${typography.fontFamily};
      &:focus-visible {
        outline: 0;
      }
    `
)
