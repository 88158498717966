import {
  AccountsApi,
  CreateAccountRequestDTO,
  CreateAccountResponseDTO,
} from '@infra/daslab-sdk';
import {BaseAPI} from '@infra/observable-core';

export type getApiCredentialsListDTO = {organizationId: string, roleId: string};

export interface IApiCredentialsService {
  getApiCredentialsList: (payload: getApiCredentialsListDTO) => Promise<Array<any>>;
  createApiCredential: (payload: CreateAccountRequestDTO) => Promise<CreateAccountResponseDTO>;
  deleteApiCredentialFromId: (id: CreateAccountResponseDTO['id']) => Promise<any>;
}

export class ApiCredentialsService extends BaseAPI<AccountsApi> implements IApiCredentialsService
{

  constructor()
  {
    super(AccountsApi);
  }

  getApiCredentialsList = async ({organizationId, roleId}: getApiCredentialsListDTO): Promise<any> => {
    const {data} = await this.api.listAccounts(organizationId, roleId);
    return data;
  }

  createApiCredential = async (payload: CreateAccountRequestDTO): Promise<CreateAccountResponseDTO> =>
  {
    const {email, password, organizationId, tenantId, locale, roleId} = payload;
    const {data} = await this.api.create({
      roleId,
      email,
      password,
      organizationId,
      tenantId,
      locale,
    })
    return data;
  }
  deleteApiCredentialFromId = async (id: CreateAccountResponseDTO['id']): Promise<{id: CreateAccountResponseDTO['id']}> =>
  {
    await this.api.deleteAccount(id);
    return {id};
  }
}

export const apiCredentialsService = new ApiCredentialsService();
