import { styled } from '@infra/ui-design-system'
import { Checkbox } from '@mui/material'

export const StyledCheckbox = styled(Checkbox)(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
  color: ${theme.palette.grey[200]};
`
)

export const StyledCheckboxLabel = styled.div<{ checked: boolean | undefined }>(
  ({ theme, checked }) => `
  font-size: 14px;
  font-weight: ${checked ? 600 : 400};
  margin-left: ${theme.spacing(1)};
  color: ${checked ? theme.palette.text.primary : theme.palette.text.primary};

  &:hover {
    color: ${theme.palette.text.primary};
  }
`
)

export const StyledIconWrapperBase = styled.div(
  ({ theme }) => `
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};
    border-radius: 8px;
    display: flex;
    justify-content: center;
  `
)

export const StyledUncheckedIcon = styled(StyledIconWrapperBase)(
  ({ theme }) => `
    border: 1px solid ${theme.palette.grey[800]};
    box-sizing: border-box;
  `
)

export const StyledCheckedIconWrapper = styled(StyledIconWrapperBase)(
  ({ theme }) => `
    border: 1px solid ${theme.palette.primary.main};
    align-items: center;
`
)
