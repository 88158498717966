import {BaseAPI} from '../apis'
import {
  AccountsApi,
  Configuration,
  CreateAccountRequestDTO,
  CreateAccountResponseDTO,
  CreatePatientAccountRequestDTO,
  RequestAccountPasswordResetRequestDTO,
  SignInPatientAccountRequestDTO,
  VerifyEmailRequestDTO
} from '@infra/daslab-sdk';

export interface IPublicAccountsApiService
{
  readonly signInPatientAccount: (signInPatientAccountRequestDTO: SignInPatientAccountRequestDTO) => Promise<void>;
  readonly registerPatientAccount: (createAccountRequestDTO: CreatePatientAccountRequestDTO) => Promise<void>;
  readonly resetPassword: (payload: RequestAccountPasswordResetRequestDTO) => Promise<void>;
  readonly requestVerifyEmail: (payload: VerifyEmailRequestDTO) => Promise<void>;
  readonly createAccount: (createAccountRequestDTO: CreateAccountRequestDTO) => Promise<CreateAccountResponseDTO>;
  readonly createPatientAccount: (createPatientAccountRequestDTO: CreatePatientAccountRequestDTO) => Promise<CreateAccountResponseDTO>;
}

export class PublicAccountsService extends BaseAPI<null> implements IPublicAccountsApiService
{
  protected publicAPI: AccountsApi;

  constructor()
  {
    super();
    const configuration = new Configuration({basePath: this.baseUrl});
    this.publicAPI = new AccountsApi(configuration);
  }

  createAccount = async (createAccountRequestDTO: CreateAccountRequestDTO): Promise<CreateAccountResponseDTO> =>
  {
    const {data} = await this.publicAPI.create(createAccountRequestDTO);
    return data;
  }

  createPatientAccount = async (createPatientAccountRequestDTO: CreatePatientAccountRequestDTO) =>
  {
    const {data} = await this.publicAPI.create_1(createPatientAccountRequestDTO);
    return data;
  }

  signInPatientAccount = async (payload: SignInPatientAccountRequestDTO): Promise<void> =>
  {
    await this.publicAPI.signPatientInV1(payload);
  }

  resetPassword = async (payload: RequestAccountPasswordResetRequestDTO): Promise<void> =>
  {
    await this.publicAPI.resetPassword(payload);
  }

  registerPatientAccount = async (payload: CreatePatientAccountRequestDTO): Promise<void> =>
  {
    await this.publicAPI.create_1(payload);
  }

  requestVerifyEmail = async (payload: VerifyEmailRequestDTO): Promise<void> =>
  {
    await this.publicAPI.requestVerifyEmail(payload);
  }
}

export const publicAccountsService = new PublicAccountsService();
