import { FormControlLabel } from '@infra/ui-design-system'
import {Typography, useTheme} from '@mui/material'
import { FC } from 'react'
import {
  StyledCheckbox,
  StyledCheckboxLabel,
  StyledCheckedIconWrapper,
  StyledUncheckedIcon,
} from './checkbox-group.styled'
import { CheckboxItemProps } from './checkbox-item.interface'
import FeatureCheck from './FeatureCheck'
import {styled} from "@infra/ui-design-system";

const StyledFormControlLabel = styled(FormControlLabel)`

  font-weight: 400;
  max-width: 100%;

  & span {
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
    word-break: break-word;
  }
  & input + div {
    border-radius: 3px;
    width: 20px;
    height: 20px;
    border: 1px solid ${({theme}) => theme.palette.text.primary}
  }
  & .Mui-checked input + div {
    background: ${({theme}) => theme.palette.text.primary};
  }
`

export const CheckboxItem: FC<CheckboxItemProps> = ({
  value,
  label,
  checkboxInformation,
  checked,
  alignItems = 'flex-start',
  onChange,
  name,
  code,
  sx = {}
}) => {
  const theme = useTheme();

  return (
    <>
      <StyledFormControlLabel
        control={
          <StyledCheckbox
            name={name}
            checked={checked}
            onChange={onChange}
            value={value}
            disableRipple
            data-loinc={code}
            icon={<StyledUncheckedIcon />}
            checkedIcon={
              <StyledCheckedIconWrapper>
                <FeatureCheck width={12} height={12} stroke={'white'} />
              </StyledCheckedIconWrapper>
            }
          />
        }
        label={<StyledCheckboxLabel checked={checked}>{label}</StyledCheckboxLabel>}
        sx={{alignItems, ...sx}}
      />
      <Typography variant="body1" component="p">
        {checkboxInformation}
      </Typography>
    </>
  )
}
