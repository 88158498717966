import {getAuth, MultiFactorResolver, onAuthStateChanged, User} from 'firebase/auth'
import { createContext, useState, useEffect } from 'react'
import HyperDX from '@hyperdx/browser';

interface IData {
  email?: string,
  password?: string,
  verificationId?: string,
  phoneNumber?: string,
  resolver?: MultiFactorResolver|null,
}

export const AuthContext = createContext({
  user: null,
  error: undefined,
  data: {
    email: '',
    verificationId: '',
    resolver: null,
  },
  setData: (data: IData) => {
    HyperDX.setGlobalAttributes({
      userEmail: data.email || 'null',
    });
  }
})

export const AuthContextProvider = props => {
  const [user, setUser] = useState<User | null>()
  const [error, setError] = useState<Error | undefined>()
  const [data, setData] = useState<IData>({
    email: '',
    verificationId: '',
    resolver: null,
  })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    return () => unsubscribe()
  }, [])

  return <AuthContext.Provider value={{
    user,
    error,
    data,
    setData,
  }} {...props} />
}
