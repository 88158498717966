import {FC, ReactNode} from 'react';
import {Box} from "@mui/material";
import {ScrollbarProps, Scrollbars} from 'react-custom-scrollbars-2';
import {useTheme, styled} from '@infra/ui-design-system';
import {StyledComponent} from "@emotion/styled";

const StyledScrollBar:  StyledComponent<{}> = styled(Scrollbars)`
  width: calc(100% + 17px + 4px);
  padding-left: 4px;
  margin-left: -4px;
  & > div[style*="overflow: scroll"] {
    padding-left: 4px;
    margin-left: -4px;
  }
`

type Scrollarea = ScrollbarProps & {
  disable: boolean;
  children: ReactNode;
}

export const ScrollArea: FC<Scrollarea> = ({
   disable = false,
   children,
   ...rest
 }) => {
  const {spaces} = useTheme();
  if(disable) return  <Box>{children}</Box>
  return <StyledScrollBar {...rest}>
    <Box pr={spaces.main}>
      {children}
    </Box>
  </StyledScrollBar>
}
