import * as React from 'react'

function SvgComponent({
  width = 12,
  height = 12,
  stroke,
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M.5 7.5l3 3 8-9"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
