import { createContext } from 'react'
import { Store } from '../StateStore/StateStore'
import { Action } from '../StateStore/StateStore.interface'
import { UserActionTypes, UserFormData, UserStoreState } from './UserStore.interface'

export const initialStoreState: UserStoreState<UserFormData> = {
  error: null,
  user: null,
  consent: null,
  requestId: null,
}

export const storeReducer = (
  state: UserStoreState<UserFormData>,
  action: Action<UserActionTypes>
) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_COUNTER:
      return { ...state, counter: action.payload }
    case UserActionTypes.SET_USER_ERROR:
      return { ...state, error: action.payload }
    case UserActionTypes.SET_DIAGNOSTIC_REQUEST_ID:
      return { ...state, requestId: action.payload }
    case UserActionTypes.SET_USER_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload,
        },
      }
    case UserActionTypes.SET_USER_INPUTS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }
    case UserActionTypes.CLEAR_USER:
      return {
        ...state,
        user: {},
      }
    case UserActionTypes.CAPTURE_CONSENT:
      return {
        ...state,
        consent: {
          primary: action.payload.primary,
          secondary: action.payload.secondary,
        },
      }
    default:
      return state
  }
}

export const userStore = new Store<UserStoreState<UserFormData>, UserActionTypes>(
  storeReducer,
  initialStoreState
)

export const UserStoreContext = createContext(userStore)

export const UserStoreProvider = ({ children }) => {
  return <UserStoreContext.Provider value={userStore}>{children}</UserStoreContext.Provider>
}
