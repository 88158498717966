import {AxiosResponse} from "axios";
import { BaseAPI } from '@infra/observable-core';
import {
  ProductsApi,
  CreateProductRequestDTO,
  GetProductResponseDTO, UpdateProductRequestDTO, ListProductResponseDTO,
} from "@infra/daslab-sdk";

export interface IProductsService {
  create: (newProduct: CreateProductRequestDTO) => Promise<GetProductResponseDTO>;
  getOne: (id: string) => Promise<GetProductResponseDTO>;
  getList: () => Promise<ListProductResponseDTO[]>;
  update: (id: string, payload: UpdateProductRequestDTO) => Promise<GetProductResponseDTO>;
  delete: (id: string) => Promise<any>;
}

export class ProductsService extends BaseAPI<ProductsApi> implements IProductsService
{

  constructor()
  {
    super(ProductsApi);
  }

  create = async (newProduct: CreateProductRequestDTO): Promise<GetProductResponseDTO> =>
  {
    const {data} = await this.api.createProduct(newProduct);
    return data;
  };
  getOne = async (id: string): Promise<GetProductResponseDTO> =>
  {
    const {data} = await this.api.getProductById(id);
    return data;
  };
  getList = async () =>
  {
    const {data} = await this.api.listProducts("");
    return data;
  };
  update = async (id: string, payload: UpdateProductRequestDTO): Promise<GetProductResponseDTO> =>
  {
    const {data} = await this.api.updateProduct(id, payload);
    return data;
  }
  delete = async (id: string) =>
  {
    // TODO - implement when EP is ready
    return {id};
  }
}

export const productsService: ProductsService = new ProductsService();
